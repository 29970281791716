import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";

// components
import { DynamicFormBuilderComponent } from "./form-builder/dynamic-form-builder.component";
import { FieldBuilderComponent } from "./field-builder/field-builder.component";
import { TextBoxComponent } from "./atoms/textbox/textbox";
import { DropDownComponent } from "./atoms/dropdown/dropdown";
import { FileComponent } from "./atoms/file";
import { CheckBoxComponent } from "./atoms/checkbox/checkbox";
import { RadioComponent } from "./atoms/radio/radio";
import { MaterialModule } from "../material/material.module";
import { DescriptionDialogComponent } from "./field-builder/dialog/descriprion-dialog/dialog.component";
import {
  MatDatepickerModule,
  MAT_DATE_FORMATS,
  MAT_DIALOG_DATA,
} from "@angular/material";
import { StaminaUiModule } from "src/app/modules/stamina-ui/stamina-ui.module";
import { OpenStreetMapModule } from "../open-street-map/open-map.module";
import { GeoLocationService } from "../open-street-map/open-map.service";
import { ZoneOmiDialog } from "./dialogs/zone-omi/dialog.component";
import { AgmCoreModule, GoogleMapsAPIWrapper } from "@agm/core";
import { ComuniDialog } from "./dialogs/comuni/dialog.component";
import { FormService } from "./services/form.service";
import { AutonumericModule } from "@angularfy/autonumeric";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MM YYYY",
    dateA11yLabel: "DD/MM/YYYY",
    monthYearA11yLabel: "MM YYYY",
  },
};

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    StaminaUiModule,
    OpenStreetMapModule,
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyCzNHmjJgmCII7gI1PTqeGL6QJydbor4mY",
      libraries: ["geometry"],
    }),
    AutonumericModule.forRoot(),
  ],
  declarations: [
    DynamicFormBuilderComponent,
    FieldBuilderComponent,
    TextBoxComponent,
    DropDownComponent,
    CheckBoxComponent,
    FileComponent,
    RadioComponent,
    DescriptionDialogComponent,
    ZoneOmiDialog,
    ComuniDialog,
  ],
  entryComponents: [DescriptionDialogComponent, ZoneOmiDialog, ComuniDialog],
  exports: [DynamicFormBuilderComponent],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    MatDatepickerModule,
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    GeoLocationService,
    GoogleMapsAPIWrapper,
    FormService,
  ],
})
export class DynamicFormBuilderModule {}
