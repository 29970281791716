import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { CommonModule, DatePipe, CurrencyPipe } from '@angular/common';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { SpinnerModule } from '../../../../shared/spinner-material/spinner.module';
import { Comparabili } from './components/comparabili/comparabili.component';
import { DetailComparabile } from './dialogs/dialog-detail/detail.component';
import { DetailSup } from './dialogs/sup-detail/supDetail.component';
import { Confronto } from './components/confronto/confronto.component';
import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from 'agm-overlays';
import { ComparabiliService } from './services/comparabili.service';
import { MaterialModule } from 'src/app/shared/material/material.module';
import { ConfrontoService } from './services/confronto.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MM YYYY',
    },
};

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        DataTablesModule,
        MaterialModule,
        AgmCoreModule.forRoot({
            apiKey: environment.googleKey
        }),
        AgmOverlays,
        SpinnerModule,
        SharedModule
    ],
    declarations: [
        Comparabili,
        DetailComparabile,
        DetailSup,
        Confronto
    ],
    entryComponents: [
        DetailComparabile,
        DetailSup
    ],
    providers: [
        ComparabiliService,
        CurrencyPipe,
        DatePipe,
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
})
export class ComparabiliModule { }


