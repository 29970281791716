import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  DocumentChangeAction,
} from "@angular/fire/firestore";
import { FirebaseCollectionsHelper } from "src/helpers/firebase-collections-helper";
import { Perizia } from "src/app/classi/Perizia/Perizia";
import { BehaviorSubject, Observable, of } from "rxjs";
import * as XLSX from "xlsx";
import { map, switchMap, take } from "rxjs/operators";
import { AuthService } from "../../auth/services/auth-service";
import { RealPerizia } from "src/app/classi/Perizia/RealPerizia";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ElencoPerizieService {
  perizie: RealPerizia[] = [];
  perizieToExport: RealPerizia[] = [];
  onPerizieChanges = new BehaviorSubject<RealPerizia[]>(null);
  onPerizieToExportChange = new BehaviorSubject<RealPerizia[]>(null);
  token: string;

  constructor(
    private _authService: AuthService,
    private _db: AngularFirestore,
    private _http: HttpClient
  ) { }

  async resolve(): Promise<any> {
    this.token = await this._authService.getAuthToken();
    return new Promise<void>((resolve, reject) => {
      Promise.resolve(this._getPerizie()).then(() => {
        resolve();
      }, reject);
    });
  }

  deleteComparabile(comp: RealPerizia) {
    try {
      this._db.doc(FirebaseCollectionsHelper.PERIZIE + "/" + comp.$id).delete();
    } catch (e) {
      console.log(e);
    }
  }

  handlePerizieToExport(perizia: RealPerizia) {
    if (this.perizieToExport.includes(perizia)) {
      this.perizieToExport.splice(this.perizieToExport.indexOf(perizia), 1);
    } else {
      this.perizieToExport.push(perizia);
    }

    this.onPerizieToExportChange.next(this.perizieToExport);
  }

  export(name: string) {
    const worksheetPerizia: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      this.perizieToExport
    );
    const workbook: XLSX.WorkBook = {
      Sheets: {
        perizia: worksheetPerizia,
      },
      SheetNames: ["perizia"],
    };
    XLSX.writeFile(workbook, name + ".xlsx");
  }

  async print(perizia: RealPerizia) {
    return this._http
      .get(
        "http://localhost:5001/easystima-4edee/europe-west1/api/v1.0/print/" +
        perizia.$id,
        {
          headers: { Authorization: `Bearer ${this.token}` },
          responseType: "blob",
        }
      )
      .pipe(take(1))
      .toPromise();
  }

  async duplicate(comp: RealPerizia): Promise<string> {
    const copy = { ...comp };
    delete copy.$id;
    delete copy.dataInserimento;
    delete copy.userId;
    delete copy.userName;
    delete copy.userSurname;
    delete copy.$subject;

    const compa = (await this._db.doc(FirebaseCollectionsHelper.COMPARABILI + "/" + copy.subjectId).get().toPromise()).data() as Perizia;
    const newId = (await this._db.collection<Perizia>(FirebaseCollectionsHelper.COMPARABILI).add({
      ...compa,
      dataInserimento: new Date() as any,
      user: this._db.doc("users/" + localStorage.getItem("USER_UID")).ref
    })).id;

    delete copy.subjectId;
    const newComp: Partial<RealPerizia> = {
      ...copy, dataInserimento: new Date() as any,
      userId: localStorage.getItem("USER_UID"),
      userName: this._authService.currentUser.name,
      userSurname: this._authService.currentUser.surname,
      subjectId: newId
    }

    const id = (await this._db.collection(FirebaseCollectionsHelper.PERIZIE).add(newComp)).id;
    return id;
  }

  private _getQuery(): Observable<DocumentChangeAction<any>[]> {
    return this._authService.isUserRole
      ? this._db
        .collection(FirebaseCollectionsHelper.PERIZIE, (ref) =>
          ref
            .where("userId", "==", this._authService.getUserId)
            .orderBy("dataInserimento", "desc")
        )
        .snapshotChanges()
      : this._db
        .collection(FirebaseCollectionsHelper.PERIZIE, (ref) =>
          ref.orderBy("dataInserimento", "desc")
        )
        .snapshotChanges();
  }

  private async _getPerizie() {
    this._getQuery()
      .pipe(
        map((perizie) =>
          perizie.map((perizia) => {
            return {
              $id: perizia.payload.doc.ref.id,
              ...(perizia.payload.doc.data() as RealPerizia),
            };
          })
        ),
        switchMap(async (perizie) => {
          for (let per of perizie) {
            const subId = per.subjectId;
            if (subId) {
              const sub = (
                await this._db
                  .doc(FirebaseCollectionsHelper.COMPARABILI + "/" + subId)
                  .get()
                  .toPromise()
              ).data() as Perizia;

              per.$subject = sub;
            }
          }

          return perizie;
        })
      )
      .subscribe((perizie) => {
        this.perizie = perizie;
        this.onPerizieChanges.next(this.perizie);
      });
  }
}
