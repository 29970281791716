import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MaterialModule } from "src/app/shared/material/material.module";
import { StaminaFileComponent } from "./file/file.component";
import { ProfilePhotoUploadComponent } from "./profile-photo-upload/profile-photo-upload.component";
import { DropzoneDirective } from "src/app/shared/directives";

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [
    StaminaFileComponent,
    ProfilePhotoUploadComponent,
    DropzoneDirective,
  ],
  exports: [StaminaFileComponent, ProfilePhotoUploadComponent],
  providers: [],
})
export class StaminaUiModule {}
