import { Injectable } from '@angular/core';
import { SisterClass } from 'src/app/classi/Sister/Sister';
import * as XLSX from 'xlsx';
import { BehaviorSubject } from 'rxjs';
import { Intestati } from 'src/app/classi/Sister/Intestati';
import { CurrencyFormat } from 'src/app/shared/pipes/currency';

@Injectable()

export class SisterUtilsService {

    constructor(
        private _currency: CurrencyFormat) { }

    immobiliDaEsportare: SisterClass[] = []
    onImmboliDaEsortareChanhe = new BehaviorSubject<SisterClass[]>([]);

    getMq(classamento: string, consistenza: string) {
        if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("A/1") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 27).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("A/2") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 21).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("A/3") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 20).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("A/4") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 21).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("A/5") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 21).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("A/6") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 23).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("A/7") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 23).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("A/8") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 30).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("A/9") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 32).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("A/10") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 23).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("A/11") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 20).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("B/8") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 0.85).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("C/1") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 1.3).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("C/2") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 1.2).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("C/3") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 1.1).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("C/6") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 1.1).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (classamento != "MODELLO 26" && classamento.split('cat.').join('').indexOf("C/7") > -1) {
            return (+consistenza.split(" ")[0].split(",").join(".") * 1.1).toLocaleString("it-it", { minimumFractionDigits: 0 });
        }
    }

    private _getMqFromAre(consistenza: string) {
        const arra2 = consistenza.split(' ');
        if (arra2.length == 6) {
            return (+arra2[0] * 10000 + +arra2[2] * 100 + +arra2[4] * 1).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (arra2.length == 4) {
            return (+arra2[0] * 100 + +arra2[2] * 1).toLocaleString("it-it", { minimumFractionDigits: 0 });
        } else if (arra2.length == 2) {
            return (+arra2[0] * 1).toLocaleString("it-it", { minimumFractionDigits: 0 });
        }
    }

    parseImmobile(immobile: SisterClass): SisterClass {
        let mq = "";
        immobile.rendita = immobile.rendita.split('Euro').join('€ ').split("(*)").join("").split(':').join(' ');
        immobile.classamento = immobile.classamento.split('cat.').join('');

        if ((immobile.altriDati && immobile.altriDati.length != 0) || (immobile.altriDatiTerreni && immobile.altriDatiTerreni.length != 0)) {
            immobile.hasOther = true;
        }

        if (immobile.consistenza.indexOf("vani") > -1) {
            mq = this.getMq(immobile.classamento, immobile.consistenza).replace(',', '.');
        }

        if (immobile.consistenza.indexOf("ha") > -1 || immobile.consistenza.indexOf("are") > -1 || immobile.consistenza.indexOf("ca") > -1) {
            mq = this._getMqFromAre(immobile.consistenza)
        }

        immobile.mq = mq;

        return immobile;
    }

    scaricaImmobiliSister(excelFileName: string) {
        const worksheetIntestati: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this._extractIntestati(this.immobiliDaEsportare));
        const worksheetImmobili: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this._manipulateJson(this.immobiliDaEsportare));
        const workbook: XLSX.WorkBook = {
            Sheets: {
                'immobili': worksheetImmobili,
                'intestati': worksheetIntestati
            },
            SheetNames: ['immobili', 'intestati']
        };
        XLSX.writeFile(workbook, excelFileName + '.xlsx');
        this.immobiliDaEsportare = [];
        this.onImmboliDaEsortareChanhe.next(this.immobiliDaEsportare);
    }

    private _manipulateJson(json: any) {
        json.forEach((j: any) => {

            j['qualita'] = "";
            j['graffato'] = "";

            j.classamento = j.classamento ? j.classamento.split('cat.').join('') : '';
            j.rendita = j.rendita ? j.rendita.split('Euro').join('€ ').split("(*)").join("").split(':').join(' ') : '';

            if (j.rendita && j.rendita.indexOf('R.D.') != -1) {
                j['redditoDominicale'] = j.rendita.split(' ')[2] ? this._currency.transform(+(j.rendita.split(' ')[2].split(',').join('.')), '€', 2, '.', ',') : 'Na';
                j['redditoAgrario'] = j.rendita.split(' ')[8] ? this._currency.transform(+(j.rendita.split(' ')[8].split(',').join('.')), '€', 2, '.', ',') : j.rendita.split(' ')[7] ?
                    this._currency.transform(+(j.rendita.split(' ')[7].split(',').join('.')), '€', 2, '.', ',') : 'Na';
                j['rendita'] = "";
            } else {
                j['redditoDominicale'] = "";
                j['redditoAgrario'] = "";
            }

            if (j.consistenza && j.classamento) {
                if (j.consistenza.indexOf("vani") > -1) j['mq'] = this.getMq(j.classamento, j.consistenza);
                else if (j.consistenza.indexOf("ha") > -1 || j.consistenza.indexOf("are") > -1 || j.consistenza.indexOf("ca") > -1) j['mq'] = this._getMqFromAre(j.consistenza);
            }


            //TO DO: cambiare i nomi dei campi, forse anche il formato
            if (j.altriDati && j.altriDati.length > 0) {
                j.altriDati.forEach(element => {
                    json.push({
                        'foglio': element.foglio,
                        'particella': element.particella,
                        'sub': element.sub,
                        'catasto': j.catasto ? j.catasto : '',
                        'qualita': '',
                        'ubicazione': element.indirizzo ? element.indirizzo : '',
                        'classamento': element.classamento ? element.classamento : j.classamento ? j.classamento : '',
                        'classe': element.classe ? element.classe : '',
                        'consistenza': element.consistenza ? element.consistenza : '',
                        'mq': element.categoria && element.consistenza ? this.getMq(element.categoria, element.consistenza).replace(',', '.') : '',
                        'rendita': element.rendita ? element.rendita.split('Euro').join('€ ').split("(*)").join("").split(':').join(' ') : '',
                        'redditoDominicale': '',
                        'redditoAgrario': '',
                        'graffato': 'Graffato'
                    });
                });
            }

            if (j.altriDatiTerreni) {
                j.altriDatiTerreni.forEach(element => {
                    json.push({
                        'foglio': element.foglio,
                        'particella': element.particella,
                        'sub': element.sub + '/' + element.porz ? element.porz : '',
                        'catasto': j.catasto ? j.catasto : '',
                        'qualita': element.qualita ? element.qualita : '',
                        'ubicazione': element.indirizzo ? element.indirizzo : j.ubicazione ? j.ubicazione : '',
                        'classamento': element.qualita ? element.qualita : '',
                        'classe': element.classe ? element.classe : '',
                        'consistenza': element.ha + 'ha ' + element.are + 'are ' + element.ca + 'ca',
                        'mq': (+element.ha * 10000 + +element.are * 100 + +element.ca).toLocaleString("it-it", { minimumFractionDigits: 0 }),
                        'rendita': '',
                        'redditoDominicale': element.redditoDomiciliare ? element.redditoDomiciliare.split('Euro').join('€ ').split("(*)").join("").split(':').join(' ') : '',
                        'redditoAgrario': element.redditoAgrario ? element.redditoAgrario.split('Euro').join('€ ').split("(*)").join("").split(':').join(' ') : '',
                        'graffato': 'Modello 26'
                    })
                });
            }
        });

        for (var i = json.length - 1; i >= 0; i--) {
            if (json[i].altriDatiTerreni || json[i].altriDati) {
                if (json[i].altriDatiTerreni.length > 0 || json[i].altriDati.length > 0) {
                    json.splice(json.indexOf(json[i]), 1);
                }
            }
        }

        const manipulatedJson = JSON.parse(JSON.stringify(json, ['catasto', 'sezione', 'foglio', 'particella', 'sub', 'ubicazione', 'classamento', 'classe', 'consistenza', 'classe',
            'rendita', 'redditoDominicale', 'redditoAgrario', 'graffato', 'mq'], 17));

        return manipulatedJson;
    }

    private _extractIntestati(json: any) {
        const arr: any[] = []
        json.forEach((j: SisterClass) => {
            j.intestati.forEach((doc: Intestati) => {
                doc['catasto'] = j.catasto
                doc['foglio'] = j.foglio
                doc['particella'] = j.particella
                doc['sub'] = j.sub
                doc['codiceFisc'] = doc.codiceFisc
                doc['nominativo'] = doc.nominativo
                doc['quota'] = doc.quota
                doc['titolarita'] = doc.titolarita
                arr.push(doc)
            });
        });

        const manipulatedJson = JSON.parse(JSON.stringify(arr, ['catasto', 'foglio', 'particella', 'sub', 'codiceFisc', 'nominativo', 'quota', 'titolarita'], 9));

        return manipulatedJson;
    }
}