import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
})
export class ChooseFileDialog implements OnInit {
  form: FormGroup;

  acronimi = [
    { value: "APROV", label: "APROV - atto di provenienza", sezione: 0 },
    {
      value: "DPREL",
      label:
        "DPREL - dichiarazione del cliente attestante l’elenco dei preliminari sottoscritti e/o assenti",
      sezione: 0,
    },
    {
      value: "IPROV",
      label: "IPROV - conferimento Incarico agli Infoprovider",
      sezione: 0,
    },
    {
      value: "LDISM",
      label: "LDISM - lettera dell'Ente per la Dismissione dell'Immobile",
      sezione: 0,
    },
    {
      value: "PRPAQ",
      label: "PRPAQ - proposta di acquisto o compromesso",
      sezione: 0,
    },
    { value: "SUCCE", label: "SUCCE - documento di Successione", sezione: 0 },
    {
      value: "EPLAN",
      label:
        "EPLAN - elaborato Planimetrico Catastale Rasterizzato e Relativo Elenco Immobili",
      sezione: 1,
    },
    { value: "MCATA", label: "MCATA - foglio di Mappa Catastale", sezione: 1 },
    {
      value: "PCRAS",
      label: "PCRAS - planimetria Catastale Rasterizzata",
      sezione: 1,
    },
    {
      value: "PRAST",
      label:
        "PRAST - planimetrie Catastali Rasterizzate di tutte le porzioni presenti nell’elaborato planimetrico",
      sezione: 1,
    },
    {
      value: "VPLAN",
      label:
        "VPLAN - visure Storiche Catastali di tutte le porzioni presenti nell'elaborato planimetrico",
      sezione: 1,
    },
    { value: "VSTOR", label: "VSTOR - visura Storico Catastale", sezione: 1 },
    {
      value: "CDEST",
      label: "CDEST - certificato di Destinazione Urbanistica Aggiornato",
      sezione: 2,
    },
    {
      value: "CEDST",
      label: "CEDST - concessione Edilizia in sanatoria",
      sezione: 2,
    },
    {
      value: "CRTAB",
      label: "CRTAB - certificato di Abitabilità o di Agibilità",
      sezione: 2,
    },
    {
      value: "LICED",
      label: "LICED - licenza / Concessione Edilizia",
      sezione: 2,
    },
    { value: "PCOST", label: "PCOST - permesso a Costruire", sezione: 2 },
    {
      value: "PPOEG",
      label: "PPOEG - progetto Protocollato o Elaborato Grafico",
      sezione: 2,
    },
    {
      value: "RELTC",
      label:
        "RELTC - relazione tecnica asseverata firmata dal tecnico di supporto all'istruttoria",
      sezione: 2,
    },
    {
      value: "ROPER",
      label: "ROPER - relazione Opere già Realizzate",
      sezione: 2,
    },
    {
      value: "SANAT",
      label: "SANAT - domanda / Istanza di sanatoria",
      sezione: 2,
    },
    {
      value: "TAOPE",
      label:
        "TAOPE - titolo Autorizzativo Opere Esecuzione (Permesso di costruire / D.I.A. / S.C.I.A. / C.I.L.A.)",
      sezione: 2,
    },
    {
      value: "CEDST",
      label: "CEDST - concessione Edilizia in sanatoria",
      sezione: 2,
    },
    {
      value: "-df.est",
      label: "-df.est - documentazione Fotografica degli Esterni",
      sezione: 3,
    },
    {
      value: "-df.int",
      label: "-df.int - documentazione Fotografica degli interni",
      sezione: 3,
    },
    {
      value: "FGALL",
      label: "FGALL - foto Galleria",
      sezione: 3,
    },
    {
      value: "CMETR",
      label: "CMETR - computo Metrico",
      sezione: 4,
    },
    {
      value: "CTU",
      label: "CTU - C.T.U. - Consulenza Tecnica d'Ufficio",
      sezione: 4,
    },
    {
      value: "PEFIN",
      label: "PEFIN - piano Economico Finanziario",
      sezione: 4,
    },
    {
      value: "-perboz",
      label: "-perboz - perizia di stima in bozza",
      sezione: 5,
    },
    {
      value: "PERIZ",
      label: "PERIZ - perizia",
      sezione: 5,
    },
    {
      value: "PFIRM",
      label: "PFIRM - perizia firmata",
      sezione: 5,
    },
    {
      value: "-rev",
      label: "-rev - perizia di stima revisionata",
      sezione: 5,
    },
    {
      value: "-sal",
      label: "-sal - perizia di stima a SAL",
      sezione: 5,
    },
    {
      value: "-sal.fin",
      label: "-sal.fin - perizia di stima a SAL a lavori conclusi",
      sezione: 5,
    },
    {
      value: "-regcon",
      label: "-regcon - regolamento Condominiale",
      sezione: 6,
    },
    {
      value: "-mail",
      label: "-mail - mail della committenza o richieste al D.L.",
      sezione: 6,
    },
    {
      value: "-dati",
      label: "-dati - dati per AQR",
      sezione: 7,
    },
    {
      value: "altro",
      label: "Altro",
      sezione: 8,
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<ChooseFileDialog>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      acronimo: string;
      file: string | File;
      descrizione: string;
    }[],
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    console.log(this.data);

    this.form = this._formBuilder.group({
      file: [null, Validators.required],
    });
  }

  confirm(form: FormGroup) {
    if (!form.valid) return;
    this.dialogRef.close(form.value);
  }

  getDesc(acr: string) {
    return this.acronimi.find((a) => a.value === acr).label;
  }
}
