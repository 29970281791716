import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_FORMATS, MatTableModule, MatPaginatorModule, MatSortModule, MatProgressSpinnerModule, MatDatepickerModule, MatChipsModule, MatAutocompleteModule, MatTabsModule, MatDividerModule, MatStepperModule, MatSelectModule, MatBadgeModule, MatMenuModule, MatNativeDateModule } from '@angular/material';
import { MatDialogModule } from '@angular/material/dialog';
import {MatListModule} from '@angular/material/list';
import {ScrollingModule} from '@angular/cdk/scrolling'; 
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatRadioModule} from '@angular/material/radio'; 
import {MatExpansionModule} from '@angular/material/expansion'; 

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MM YYYY',
    },
};

@NgModule({
    imports: [
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatInputModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatTabsModule,
        MatDividerModule,
        MatStepperModule,
        MatSelectModule,
        MatCardModule,
        MatBadgeModule,
        MatMenuModule,
        MatDialogModule,
        MatListModule,
        ScrollingModule,
        MatTooltipModule,
        MatRadioModule,
        MatNativeDateModule,
        MatExpansionModule
    ],
    exports: [
        MatButtonModule,
        MatIconModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatTableModule,
        MatPaginatorModule,
        MatSortModule,
        MatProgressSpinnerModule,
        MatDatepickerModule,
        MatChipsModule,
        MatAutocompleteModule,
        MatCheckboxModule,
        MatTabsModule,
        MatDividerModule,
        MatStepperModule,
        MatSelectModule,
        MatCardModule,
        MatBadgeModule,
        MatMenuModule,
        MatDialogModule,
        MatListModule,
        ScrollingModule,
        MatTooltipModule,
        MatRadioModule,
        MatNativeDateModule,
        MatExpansionModule
    ],
    providers: [{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },]
})
export class MaterialModule {

}
