import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  AfterViewInit,
  ViewChild,
  AfterViewChecked,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { Etichetta } from "src/app/classi/Etichetta";
import { GeoLocationService } from "src/app/shared/open-street-map/open-map.service";
import { SharedService } from "src/app/shared/services/shared.service";
import { FormService } from "../../services/form.service";
import * as AutoNumeric from "autonumeric";
declare var google: any;

@Component({
  selector: "textbox",
  templateUrl: "./textbox.html",
  styleUrls: ["./textbox.scss"],
})
export class TextBoxComponent
  implements OnInit, AfterViewInit, AfterViewChecked
{
  @ViewChild("address", { static: false }) address: any;

  @Input() field: Etichetta;
  @Input() form: FormGroup;
  @Input() isViewMode?: boolean = false;

  @Output() onCoordinatesClick = new EventEmitter();

  disabled: boolean;
  initPrice = false;
  initCanone = false;

  get isValid() {
    return this.form.controls[this.field.name].valid;
  }
  get isDirty() {
    return this.form.controls[this.field.name].dirty;
  }
  get value() {
    return this.form.controls[this.field.name].value;
  }

  constructor(
    private _cdRef: ChangeDetectorRef,
    private _sharedService: SharedService,
    private _geoLocationService: GeoLocationService,
    private _dialog: MatDialog,
    private _formService: FormService
  ) {}

  ngOnInit() {
    this.disabled = this.form.controls[this.field.name].disabled;
  }

  ngAfterViewInit() {
    this._cdRef.detectChanges();
    if (this.address) this._getPlaceAutocomplete();
  }

  ngAfterViewChecked() {
    if (this.field.name === "prezzo") {
      if (document.getElementById("prezzo") && !this.initPrice) {
        new AutoNumeric("#prezzo", 0, {
          digitGroupSeparator: ".",
          decimalCharacter: ",",
        });

        console.log(this.field.value);
        
        AutoNumeric.getAutoNumericElement(
          document.getElementById("prezzo")
        ).set(this.field.value ? this.field.value.replace(".","") : 0);

        this.initPrice = true;
      }
    }

    if (this.field.name === "canone_affitto") {
      if (document.getElementById("canone_affitto") && !this.initCanone) {
        new AutoNumeric("#canone_affitto", 0, {
          digitGroupSeparator: ".",
          decimalCharacter: ",",
        });

        AutoNumeric.getAutoNumericElement(
          document.getElementById("canone_affitto")
        ).set(this.field.value ? this.field.value.replace(".","") : 0);

        this.initCanone = true;
      }
    }
  }

  emit(value: any) {
    this.onCoordinatesClick.emit(value);
  }

  async emitSismica() {
    let zonaSismica1 = "";
    const zonaSismica = await this._formService.getZonaSismica(
      this.form.value.comune.toLowerCase().split(" ").join("")
    );
    if (zonaSismica[0]) zonaSismica1 = zonaSismica[0];

    this.onCoordinatesClick.emit({
      zonaSismica: zonaSismica1,
    });
  }

  updateValue() {
    if (this.form.controls[this.field.name + "supEff"])
      this.form.controls[this.field.name + "supEff"].setValue(
        this._sharedService.parseNumber(this.value) *
          +this.form.controls[this.field.name + "indice"].value
      );
  }

  private _getPlaceAutocomplete() {
    // const service = new google.maps.places.PlacesService(document.createElement('div'));
    const autocomplete = new google.maps.places.Autocomplete(
      this.address.nativeElement,
      {
        componentRestrictions: { country: "IT" },
        types: ["geocode"],
      }
    );

    google.maps.event.addListener(autocomplete, "place_changed", async () => {
      const place = autocomplete.getPlace() as google.maps.places.PlaceResult;

      let address = place.formatted_address
        .split(",")
        .slice(0, 2)
        .reduce((a, b) => a + b);
      console.log(address);

      let cap = "";
      let city = "";
      let provincia = "";
      let distance1: number;
      let zonaSismica1 = "";

      const vicinity = place.vicinity;
      if (vicinity) {
        const comune = vicinity.toLowerCase().split(" ").join("");
        const zonaSismica = await this._formService.getZonaSismica(comune);
        if (zonaSismica[0]) zonaSismica1 = zonaSismica[0];

        const geoServiceResult = await this._geoLocationService.geoGoogle(
          vicinity
        );
        if (geoServiceResult.results[0]) {
          const loc = geoServiceResult.results[0].geometry.location;
          const point = new google.maps.LatLng(
            place.geometry.location.lat(),
            place.geometry.location.lng()
          );
          const center = new google.maps.LatLng(loc.lat, loc.lng);
          const distance =
            google.maps.geometry.spherical.computeDistanceBetween(
              center,
              point
            );
          distance1 = Math.round(distance);
        }
      }

      place.address_components.forEach((address) => {
        address.types.forEach((type) => {
          if (type === "postal_code") cap = address.long_name;
          if (type === "locality") city = address.long_name;
          if (type === "administrative_area_level_2")
            provincia = address.long_name;
        });
      });

      this.onCoordinatesClick.emit({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        cap: cap,
        city: city,
        provincia: provincia,
        distance: distance1,
        zonaSismica: zonaSismica1,
        indirizzo: address,
      });
    });
  }
}
