import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { ActivatedRouteSnapshot } from "@angular/router";
import { FirebaseCollectionsHelper } from "src/helpers/firebase-collections-helper";
import { map, take, switchMap } from "rxjs/operators";
import { Perizia } from "src/app/classi/Perizia/Perizia";
import { BehaviorSubject, Observable, zip, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Etichetta } from "src/app/classi/Etichetta";
import * as _ from "lodash";

@Injectable()
export class DetailComparabileService {
  id: string;
  comparabile: Perizia;
  onComparabileChanged = new BehaviorSubject<Perizia>(null);

  etichette: Etichetta[];

  constructor(private _db: AngularFirestore, private _http: HttpClient) {}

  resolve(route: ActivatedRouteSnapshot): Promise<any> {
    this.id = route.params.id;

    return new Promise<void>((resolve, reject) => {
      this._getComparabile(this.id)
        .pipe(
          switchMap((comparabile) => {
            return zip(
              of(comparabile),
              this._getEtichette(comparabile.subCategory)
            );
          })
        )
        .subscribe(([a, b]) => {
          if (a) {
            this.comparabile = a;
            this.onComparabileChanged.next(a);
          }
          if (b) this.etichette = b;

          resolve();
        }, reject);
    });
  }

  private _getComparabile(id: string): Observable<Perizia> {
    return this._db
      .doc(FirebaseCollectionsHelper.COMPARABILI + "/" + id)
      .snapshotChanges()
      .pipe(
        map((doc) => {
          return {
            id: doc.payload.ref.id,
            ...(doc.payload.data() as Perizia),
          };
        })
      );
  }

  private _getEtichette(subCategory: string): Observable<any[]> {
    return this._http
      .get("https://europe-west1-easystima-4edee.cloudfunctions.net/api/v1.0/etichette/get-all")
      .pipe(
        take(1),
        map((res: any) =>
          res.map((single: any) => {
            if (single.options) {
              const options = _.orderBy(
                single.options,
                (single) => +single.key,
                "asc"
              );
              single.options = options;
            }

            return single;
          })
        ),
        map((etichette: any[]) => {
          return etichette.filter(
            (etichetta) =>
              etichetta.comparabile === 1 && etichetta[subCategory] === 1
          );
        }),
        map((res) => {
          return _.orderBy(res, (a) => a.ordinamento).map((a) => {
            return {
              ...a,
              required: a.required === 1 ? true : false,
            };
          });
        })
      );
  }
}
