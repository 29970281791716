import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { macroCategorie } from 'src/app/classi/categorie';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})

export class CategoryDialog implements OnInit {

    categorie = macroCategorie;
    form: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<CategoryDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private formBuilder: FormBuilder) { }

    ngOnInit() {
        this.form = this.formBuilder.group({
            category: ['', Validators.required]
        });       
    }

    onNoClick() {
        this.dialogRef.close();
    }

    confirm(form: FormGroup) {
        if (!form.valid) return;
        this.dialogRef.close(form.value.category);
    }
}