import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { FirebaseCollectionsHelper } from "src/helpers/firebase-collections-helper";
import { AngularFireStorage } from "angularfire2/storage";
import { StatiPeriziaHelper } from "src/helpers/stati-perizia-helper";
import { User } from "src/app/classi/User";
import { Perizia } from "src/app/classi/Perizia/Perizia";
import * as _ from "lodash";
import * as firebase from "firebase";

@Injectable()
export class NuovoComparabileService {
  constructor(
    private _db: AngularFirestore,
    private _storage: AngularFireStorage
  ) {}

  insertRequest(
    comparabile: Partial<Perizia>,
    state: string,
    user: User,
    filesInterni: File[],
    filesEsterni: File[],
    documenti: File[]
  ): Promise<string> {
    return new Promise<string>(async (resolve, reject) => {
      this._db
        .collection(FirebaseCollectionsHelper.COMPARABILI)
        .add({
          ...comparabile,
          coordinates: comparabile["coordinate"]
            ? new firebase.firestore.GeoPoint(
                +comparabile["coordinate"].split(",")[0],
                +comparabile["coordinate"].split(",")[1]
              )
            : null,
          user: this._db.doc("users/" + user.id).ref,
          state:
            state === "send"
              ? StatiPeriziaHelper.PENDING
              : StatiPeriziaHelper.DRAFT,
          dataInserimento: new Date(),
        })
        .then((res) => {
          const concatNames = _.concat(
            comparabile.filesEsterni,
            comparabile.filesInterni,
            comparabile.documenti
          );
          const concatFiles = _.concat(filesEsterni, filesInterni, documenti);
          concatNames.forEach((file, index) => {
            try {
              this._storage.ref(file).put(concatFiles[index]);
            } catch (e) {
              console.log(e);
            }
          });

          resolve(res.id);
        }, reject);
    });
  }

  editComparabile(
    id: string,
    state: string,
    newValues: Partial<Perizia>,
    filesInterni?: File[],
    filesEsterni?: File[],
    documenti?: File[]
  ): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this._db
        .doc(FirebaseCollectionsHelper.COMPARABILI + "/" + id)
        .update({
          state:
            state === "send"
              ? StatiPeriziaHelper.PENDING
              : StatiPeriziaHelper.DRAFT,
          coordinates: newValues["coordinate"]
            ? new firebase.firestore.GeoPoint(
                +newValues["coordinate"].split(",")[0],
                +newValues["coordinate"].split(",")[1]
              )
            : null,
          ...newValues,
        })
        .then(() => {
          const concatNames = _.concat(
            newValues.filesEsterni,
            newValues.filesInterni,
            newValues.documenti
          );
          const concatFiles = _.concat(filesEsterni, filesInterni, documenti);
          concatNames.forEach((file, index) => {
            try {
              if (file && concatFiles[index])
                this._storage.ref(file).put(concatFiles[index]);
            } catch (e) {
              console.log(e);
            }
          });

          resolve();
        }, reject);
    });
  }
}
