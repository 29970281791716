import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';
import { Convenzione } from 'src/app/classi/Sister/Convenzione';
import { environment } from 'src/environments/environment';

@Injectable()

export class SisterService {

    private _tokenExpirationTimer: any;

    constructor(
        private _http: HttpClient) { }

    login(userName: string, password: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._http.get(environment.baseUrl + "sister/login/" + userName + "/" + password).pipe(take(1)).subscribe(res => {
                const token = JSON.parse(JSON.stringify(res)).token as string;
                if (token) {
                    localStorage.setItem('cf', userName);
                    localStorage.setItem('psw', password);
                    localStorage.setItem('tokenSis', token);

                    this._autoLogout(1800000);

                    const arra = JSON.parse(JSON.stringify(res)).convenzioni as any[];
                    if (arra.length > 0) {
                        const convenzioni: Convenzione[] = [];
                        arra.forEach(convenzione => {
                            convenzioni.push(new Convenzione(convenzione.convenzioneDesc, convenzione.idConvenzione));
                        });

                        resolve(convenzioni);

                    } else resolve('logged');

                } else {
                    reject('sessione');
                }
            }, reject);
        });
    }

    fetchOmonini(codFisc: string, idConvenzione: number, isFetchingCodFisc: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            this._http.get(environment.baseUrl + "sister/omonimi/" + codFisc + "/" + idConvenzione + "/" + isFetchingCodFisc).pipe(take(1))
                .subscribe((res: any[]) => {
                    if (res.length) resolve(res)
                    else reject('no-data');
                }, reject);
        });
    }

    fetchProvincie(isFetchingCodFisc: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            this._http.get(environment.baseUrl + "sister/provincie/" + isFetchingCodFisc).pipe(take(1))
                .subscribe((res: any[]) => {
                    resolve(res);
                }, reject);
        });
    }

    fetchBuildings(idProv: string, isFetchingCodFisc: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            this._http.post(environment.baseUrl + "sister/immobili", { idProv, isFetchingCodFisc }).pipe(take(1))
                .subscribe((res: any[]) => {
                    resolve(res);
                }, reject);
        });
    }

    pay(id: number, isFetchingCodFisc: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            this._http.get(environment.baseUrl + "sister/pay/" + id + "/" + isFetchingCodFisc).pipe(take(1))
                .subscribe(() => {
                    setTimeout(() => {
                        this._http.get(environment.baseUrl + "sister/getPdf/" + localStorage.getItem("cf"), { responseType: 'blob' }).pipe(take(1))
                            .subscribe(res => {
                                resolve(res);
                            }, reject);
                    }, 5000);
                }, reject)
        });
    }

    private _autoLogout(expirationDuration: number) {
        this._tokenExpirationTimer = setTimeout(() => {
            this.logOut();
        }, expirationDuration)
    }

    logOut(): Promise<any> {
        return new Promise<void>(resolve => {
            localStorage.removeItem('cf');
            localStorage.removeItem('psw');
            localStorage.removeItem('tokenSis');

            if (this._tokenExpirationTimer) clearTimeout(this._tokenExpirationTimer);

            this._tokenExpirationTimer = null;

            resolve();
        });
    }

    logOutFromSister() {
        return this._http.get(environment.baseUrl + "sister/logout").pipe(take(1)).toPromise();
    }

}