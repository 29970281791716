import { Component, OnInit } from '@angular/core';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth-service';
import { FuseSplashScreenService } from '../../../../shared/services/splash.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class login implements OnInit {

  formLogin: FormGroup;

  constructor(
    private _authService: AuthService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.formLogin = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      psw: ['', Validators.required]
    });
  }


  onSubmit(form: FormGroup) {
    if (!form.valid) {
      return;
    }

    const email = form.value.email;
    const password = form.value.psw;

    this._fuseSplashScreenService.show();
    this._authService.logIn(email, password).then(null, err => {
      if (err === 'pending') alert('Utente non abilitato, controlla la tua mail per sapere quando sarai abilitato')
    }).finally(() => this._fuseSplashScreenService.hide());
    form.reset();
  }
}
