import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FuseSplashScreenService } from './shared/services/splash.service';
import { MatIconRegistry } from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _matIconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer) {

    this._matIconRegistry.addSvgIcon('delete-icon', this._sanitizer.bypassSecurityTrustResourceUrl('assets/icons/delete.svg'));

  }

  ngOnInit(): void { }
}
