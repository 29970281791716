import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'description-dialog',
    templateUrl: 'dialog.component.html',
    styleUrls: ['dialog.component.scss'],
})
export class DescriptionDialogComponent {

    mainDesc: string;
    fieldsDesc: any;

    constructor(
        public dialogRef: MatDialogRef<DescriptionDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        this.mainDesc = data.mainDesc ? data.mainDesc : ''
        this.fieldsDesc = data.fieldsDesc ? data.fieldsDesc : ''
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}