import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { Immobile } from 'src/app/classi/Comparabili-immobiliare-casa/Immobile';
import * as _ from 'lodash';
import { Etichetta } from 'src/app/classi/Etichetta';

@Injectable({ providedIn: 'root' })
export class ConfrontoService {

    immobiliPerConfronto: Immobile[] = [];
    subject: Immobile;
    onChange = new BehaviorSubject<{ immobili: Immobile[], chars: string[], subject: Immobile }>(null);
    onChangeEtichette = new BehaviorSubject<Etichetta[]>(null);

    constructor(private _http: HttpClient) { }

    resolve() {
        this._getMcaEtichette();
    }

    public handleArray(imm: Immobile) {
        const index = this.immobiliPerConfronto.indexOf(imm);
        if (index === -1) {
            this.immobiliPerConfronto.push(imm);
        } else this.immobiliPerConfronto.splice(index, 1)
    }

    public shouldSeeConfronto(): boolean {
        return this.immobiliPerConfronto.length >= 2;
    }

    private _getMcaEtichette(): Subscription {
        return this._http
            .get<Etichetta[]>("https://europe-west1-easystima-4edee.cloudfunctions.net/api/v1.0/etichette/get-all")
            .pipe(
                take(1),
                map(res => res.filter(a => a.MCA === 1)))
            .subscribe(res => this.onChangeEtichette.next(res));
    }

}