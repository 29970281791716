import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../auth/services/auth-service";
import { User } from "../../../../classi/User";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UserType } from "functions/src/lib/firestore/user";
import { MatDialog } from "@angular/material";
import { CategoryDialog } from "./dialogs/category-dialog/dialog.component";
import { HostListener } from "@angular/core";
import { NuovaPeriziaService } from "../../services/nuova-perizia.service";

@Component({
  selector: "dash",
  templateUrl: "./back.component.html",
  styleUrls: ["./back.component.scss"],
})
export class Back implements OnInit, OnDestroy {
  private _unsuscribeAll = new Subject<any>();

  userIn: User;
  showNav = true;

  @HostListener("window:resize")
  onResize() {
    if (window.innerWidth <= 480) this.showNav = false;
    else this.showNav = true;
  }

  get isAdmin(): boolean {
    return this.userIn ? this.userIn.userType === UserType.admin : false;
  }

  get isRevisore(): boolean {
    return this.userIn ? this.userIn.userType === UserType.revisore : false;
  }

  public constructor(
    private router: Router,
    private _authService: AuthService,
    private _perService: NuovaPeriziaService,
    private _dialog: MatDialog,
    private _route: ActivatedRoute) {

    this.onResize();
  }

  ngOnInit() {
    this._authService.userActive
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((user) => {
        if (user) this.userIn = user;
      });
  }

  ngOnDestroy() {
    this._unsuscribeAll.next();
    this._unsuscribeAll.complete();
  }

  onLogout() {
    this._authService.logout();
  }

  goToNew() {
    this._perService.periziaId = null;
    this._perService.perizia.next(null);
    this.router.navigateByUrl("/dash/new-perizia)");
  }

  goToNewComparabile() {
    this.router.navigateByUrl("/dash").finally(() => {
      this._dialog
        .open(CategoryDialog, { disableClose: true })
        .afterClosed()
        .pipe(takeUntil(this._unsuscribeAll))
        .subscribe((res: number) => {
          this.router.navigate(["/dash/new-comparabile", res]);
        });
    });
  }

  goToSister() {
    this.router.navigateByUrl("/dash/sister");
  }

  goToComparabili() {
    this.router.navigateByUrl("/dash/comparabili");
  }

  goToElencoPerizie() {
    this.router.navigateByUrl("/dash/elenco-perizie");
  }

  goToElencoComparabili() {
    this.router.navigateByUrl("/dash/elenco-comparabili");
  }

  goToUsers() {
    this.router.navigateByUrl("/dash/utenti");
  }

  goToEtichette() {
    window.open("http://173.249.48.19:8080/StaminaV3/", "_blank");
  }

  showHideNavBar() {
    this.showNav = !this.showNav;
  }

  showMca(): boolean {
    return this.router.url.indexOf("mca/do") != -1;
  }

  goToMca(param: string) {
    this.router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        location: param
      },
      queryParamsHandling: 'merge'
    });
  }

  goToElencoCalcoli() {
    this.router.navigate(["dash", "mca", "list"]);
  }
}
