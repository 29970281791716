import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Etichetta } from 'src/app/classi/Etichetta';

@Component({
  selector: 'radio',
  templateUrl: './radio.html'
})
export class RadioComponent {

  @Input() field: Etichetta;
  @Input() form: FormGroup;
  @Input() isViewMode?: boolean = false;
}