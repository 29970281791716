import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedService } from './services/shared.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [SharedService]
})
export class SharedModule { }