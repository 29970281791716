import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { login } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Main } from './components/main/main.component';
import { SignUp } from './components/signup/signup.component';
import { MaterialModule } from '../../shared/material/material.module';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        MaterialModule
    ],
    declarations: [
        Main,
        login,
        SignUp
    ],
    entryComponents: [

    ],
    providers: [

    ]
})
export class AuthModule { }