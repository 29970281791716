import { Component, HostBinding, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SisterClass } from 'src/app/classi/Sister/Sister';


@Component({
    selector: 'detail-sister-payment',
    templateUrl: './payment.component.html',
    styleUrls: ['./payment.component.css']
})
export class DetailSisterPagamento {

    constructor(public dialogRef: MatDialogRef<DetailSisterPagamento>,
        @Inject(MAT_DIALOG_DATA) public dataPay: number) {
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
