import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { MatTableDataSource, MatPaginator, MatDialog } from "@angular/material";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ElencoComparabiliService } from "../../services/elenco-comparabili.service";
import { Perizia } from "src/app/classi/Perizia/Perizia";
import { ConfirmDialog } from "src/app/shared/confirm-dialog/dialog.component";
import { AuthService } from "src/app/modules/auth/services/auth-service";
import { Router } from "@angular/router";

@Component({
  selector: "elenco-comparabili",
  templateUrl: "./elenco-comparabili.component.html",
  styleUrls: ["./elenco-comparabili.component.scss"],
})
export class ElencoComparabiliComponent implements OnInit, OnDestroy {
  private _unsuscribeAll: Subject<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  get isAdmin(): boolean {
    return this._authService.isAdminRole;
  }

  get isRevisore(): boolean {
    return this._authService.isRevisoreRole;
  }

  get isUser(): boolean {
    return this._authService.isUserRole;
  }

  displayedColumns = [
    "date",
    "code",
    "state",
    "visible",
    "address",
    "coordinates",
    "user",
    "more",
  ];
  dataSource = new MatTableDataSource([]);
  idToEdit: string;

  constructor(
    private _service: ElencoComparabiliService,
    private dialog: MatDialog,
    private _authService: AuthService,
    private router: Router
  ) {
    this._unsuscribeAll = new Subject();
  }

  ngOnInit() {
    this._service.onComparabiliChanges
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res) {
          this.dataSource.data = res;
        }
      });

    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    this._unsuscribeAll.next();
    this._unsuscribeAll.complete();
  }

  acceptComparabile(comparabile: Perizia, state: number) {
    const dialog = this.dialog.open(ConfirmDialog);
    dialog
      .afterClosed()
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res === 0) this._service.editComparabile(comparabile, state);
      });
  }

  delete(comparabile: Perizia) {
    const dialog = this.dialog.open(ConfirmDialog);
    dialog
      .afterClosed()
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res === 0) this._service.deleteComparabile(comparabile);
      });
  }

  editComparabile(comparabile: Perizia) {
    this.idToEdit = comparabile.id;
    this.router.navigate(["dash/edit-comparabile", comparabile.id]);
  }

  detail(comparabile: Perizia) {
    this.idToEdit = comparabile.id;
    this.router.navigate(["dash/view-comparabile", comparabile.id]);
  }

  valida(comparabile: Perizia) {
    this._service.validaComparabile(comparabile);
  }

  isOwnedByUser(comparabile: Perizia): boolean {
    return comparabile.user.id === this._authService.getUserId;
  }

  isEditing(comparabile: Perizia): boolean {
    return comparabile.id === this.idToEdit;
  }

  async duplicate(comparabile: Perizia) {
    this.idToEdit = comparabile.id;
    const id = await this._service.duplicateComparabile(comparabile);
    this.router.navigate(["dash/view-comparabile", id]);
  }
}
