import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatStepper } from "@angular/material";
import { Subject } from "rxjs";

@Component({
  selector: "valori-component",
  templateUrl: "./valori.component.html",
  styleUrls: ["./valori.component.scss"],
})
export class ValoriComponent implements OnInit, OnDestroy {
  @Input() stepper: MatStepper;
  @Output() save = new EventEmitter();
  private _unsuscribeAll: Subject<any>;

  form: FormGroup;

  constructor(private _dialog: MatDialog, private _formBuilder: FormBuilder) {
    this._unsuscribeAll = new Subject();
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      valoreDiMercato: [null, Validators.required],
    });
  }

  ngOnDestroy() {
    this._unsuscribeAll.next();
    this._unsuscribeAll.complete();
  }

  next() {
    this.save.emit(this.form.value);
    this.stepper.next();
  }

  prev() {
    this.stepper.previous();
  }
}
