import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnDestroy,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from "@angular/forms";
import { Etichetta } from "src/app/classi/Etichetta";
import { MatSelectChange, MatDialog } from "@angular/material";
import { categorie } from "src/app/classi/categorie";
import { GeoLocationService } from "../../open-street-map/open-map.service";
import { Subject } from "rxjs";
import { SharedService } from "../../services/shared.service";
import { NumberFormat } from "../../pipes/currency";
import { takeUntil } from "rxjs/operators";
import { ZoneOmiDialog } from "../dialogs/zone-omi/dialog.component";

@Component({
  selector: "dynamic-form-builder",
  templateUrl: "./dynamic-form-builder.component.html",
  styleUrls: ["./dynamic-form-builder.component.scss"],
})
export class DynamicFormBuilderComponent implements OnInit, OnDestroy {
  private _unsuscribeAll: Subject<any>;

  @Output() onSubmit = new EventEmitter();
  @Output() onFileUploaded = new EventEmitter();
  @Output() onDeleteClicked = new EventEmitter();
  @Output() onDownloadClicked = new EventEmitter();
  @Output() onSubCategoryChanged = new EventEmitter();

  @Input() fields: Etichetta[] = [];
  @Input() visibleFilesInterni: string[] = [];
  @Input() visibleFilesEsterni: string[] = [];
  @Input() documenti: string[] = [];
  @Input() set category(value: number) {
    this.realCategory = value;
    this.categorie = categorie.filter(
      (category) => category.macro === this.realCategory
    );
  }
  @Input() isEdit = false;
  @Input() isSubject = false;
  @Input() shouldSeeSave = true;

  @Input() isViewMode? = false;

  loading = false;

  lat = 0;
  lng = 0;
  realCategory: number;
  filteredFileds: Etichetta[] = [];

  macro1: Etichetta[] = [];
  macro2: Etichetta[] = [];
  macro3: Etichetta[] = [];
  macro4: Etichetta[] = [];

  form: FormGroup;
  categorie: any;

  constructor(
    private _geoService: GeoLocationService,
    private _sharedService: SharedService,
    private _number: NumberFormat,
    private dialog: MatDialog
  ) {
    this._unsuscribeAll = new Subject();
  }

  ngOnInit() {
    if (this.isEdit) this._divideCategories(this.fields);

    const fieldsCtrls = {};
    this.form = new FormGroup(this._createForm(fieldsCtrls));
    const affectedControls: AbstractControl[] = [];
    let affectedControlsValues = [];

    for (let control in this.form.controls) {
      if (control.includes("supEff")) {
        affectedControls.push(this.form.controls[control]);
        affectedControlsValues.push(this.form.controls[control].value);
      }
    }

    const validControls = affectedControlsValues.filter(
      (value) => value && value !== "NaN"
    );
    const value =
      validControls.length > 0 ? validControls.reduce((a, b) => +a + +b) : 0;
    if (this.form.controls["superficiecommerciale"])
      this.form.controls["superficiecommerciale"].setValue(
        this._number.transform(value)
      );

    affectedControls.forEach((control) => {
      control.valueChanges.subscribe(() => {
        affectedControlsValues = [];
        affectedControls.forEach((control) => {
          affectedControlsValues.push(control.value);
        });

        const validControls = affectedControlsValues.filter(
          (value) => value && value !== "NaN"
        );
        const value =
          validControls.length > 0
            ? validControls.reduce((a, b) => +a + +b)
            : 0;
        this.form.controls["superficiecommerciale"].setValue(
          this._number.transform(value)
        );
      });
    });
  }

  ngOnDestroy() {
    this._unsuscribeAll.next();
    this._unsuscribeAll.complete();
  }

  async getCoordinates(value: any) {
    if (value === 0) {
      this.loading = true;
      this._sharedService
        .getZonaOmi(
          this.form.controls["comune"].value,
          String(this.lng),
          String(this.lat)
        )
        .then((res) => {
          const json = JSON.parse(JSON.stringify(res));
          if (json["zone"]) {
            const zona = json["zone"][0];
            this.form.controls["zona_omi"].setValue(zona["zona"]);
            this.dialog
              .open(ZoneOmiDialog, {
                width: "50%",
                data: zona["valori"],
              })
              .afterClosed()
              .pipe(takeUntil(this._unsuscribeAll))
              .subscribe((res) => {
                if (res) {
                  this.form.controls["valore_mercato_massimo"].setValue(
                    res["comprMax"]
                  );
                  this.form.controls["valore_mercato_minimo"].setValue(
                    res["comprMin"]
                  );
                  this.form.controls["valore_locazione_massimo"].setValue(
                    res["locMax"]
                  );
                  this.form.controls["valore_locazione_minimo"].setValue(
                    res["locMin"]
                  );
                }
                this.loading = false;
              });
          } else alert("Dati non disponibili");
        });
    } else {
      if (value.lat && value.lng)
        this.form.controls["coordinate"].setValue(value.lat + ", " + value.lng);
      if (value.city) this.form.controls["comune"].setValue(value.city);
      if (value.cap) this.form.controls["cap"].setValue(value.cap);
      if (value.provincia)
        this.form.controls["provincia"].setValue(value.provincia);
      if (value.distance)
        this.form.controls["distanza_comune"].setValue(value.distance);
      if (value.zonaSismica)
        this.form.controls["zonarischiosismico"].setValue(value.zonaSismica);
      if (value.indirizzo)
        this.form.controls["indirizzo"].setValue(value.indirizzo);

      if (value.lat) this.lat = value.lat;
      if (value.lng) this.lng = value.lng;
    }
  }

  onSubmitForm(form: FormGroup) {
    this.onSubmit.emit({ form: form, type: "send" });
  }

  onSubmitForDraft() {
    this.onSubmit.emit({ form: this.form, type: "save" });
  }

  changeCategory(category: MatSelectChange) {
    this.filteredFileds = this.fields.filter(
      (etichetta) => etichetta[category.value] === 1
    );
    this.onSubCategoryChanged.emit(category.value);
    this._divideCategories(this.filteredFileds);
  }

  private _createForm(fieldsCtrls: any): any {
    for (let f of this.fields) {
      if (f.type !== "checkbox") {
        if (f.type === "date") {
          fieldsCtrls[f.name] = new FormControl(
            {
              value: f.value && f.value ? f.value : "",
              disabled: !f.enabled,
            },
            f.required ? Validators.required : null
          );
        } else if (f.type === "number") {
          const value = this._number.transform(+f.value);
          fieldsCtrls[f.name] = new FormControl(
            {
              value: value && value !== "NaN" ? value : f.value,
              disabled: !f.enabled,
            },
            f.required ? Validators.required : null
          );
        } else if (f.type === "anno")
          fieldsCtrls[f.name] = new FormControl(
            { value: f.value ? f.value : "", disabled: !f.enabled },
            f.required ? Validators.required : null
          );
        else
          fieldsCtrls[f.name] = new FormControl(
            { value: f.value ? f.value : "", disabled: !f.enabled },
            f.required ? Validators.required : null
          );

        if (f.millesimi)
          fieldsCtrls[f.name + "millesimi"] = new FormControl(f.millesimi);

        if (f.vitaUtile)
          fieldsCtrls[f.name + "vitaUtile"] = new FormControl(f.vitaUtile);

        if (f.vetusta)
          fieldsCtrls[f.name + "vetusta"] = new FormControl(f.vetusta);

        if (f.indice) {
          if (this.isEdit)
            fieldsCtrls[f.name + "indice"] = new FormControl(f.indice);

          if (!this.isEdit)
            fieldsCtrls[f.name + "indice"] = new FormControl(
              this._getCorrectIndice(f)
            );

          fieldsCtrls[f.name + "supEff"] = new FormControl({
            value: this._sharedService.parseNumber(f.value) * +f.indice,
            disabled: true,
          });
        }
      } else if (f.type === "checkbox") {
        const opts = {};

        for (let opt of f.options) {
          if (f.value) {
            const services = f.value.split("_") as string[];
            opts[opt.label] = new FormControl({
              value: services.includes(opt.label) ? true : false,
              disabled: !f.enabled,
            });
          } else
            opts[opt.label] = new FormControl({
              value: f.value === opt.label ? true : false,
              disabled: !f.enabled,
            });
        }

        fieldsCtrls[f.name] = new FormGroup(opts);

        if (f.millesimi)
          fieldsCtrls[f.name + "millesimi"] = new FormControl(f.millesimi);

        if (f.vitaUtile)
          fieldsCtrls[f.name + "vitaUtile"] = new FormControl(f.vitaUtile);

        if (f.vetusta)
          fieldsCtrls[f.name + "vetusta"] = new FormControl(f.vetusta);

        if (f.indice)
          fieldsCtrls[f.name + "indice"] = new FormControl(f.indice);
      }
    }

    if (this.isViewMode)
      for (let a in fieldsCtrls) {
        const control = fieldsCtrls[a] as FormControl;
        control.disable();
      }

    return fieldsCtrls;
  }

  private _divideCategories(toFilter: Etichetta[]) {
    this.macro1 = toFilter.filter(
      (etichetta) => etichetta.macrocategoria === 1
    );
    this.macro2 = toFilter.filter(
      (etichetta) => etichetta.macrocategoria === 2
    );
    this.macro3 = toFilter.filter(
      (etichetta) => etichetta.macrocategoria === 3
    );
    this.macro4 = toFilter.filter(
      (etichetta) => etichetta.macrocategoria === 4
    );
  }

  private _getCorrectIndice(f: Etichetta): number {
    switch (f.name) {
      case "vani_principali":
        return 1;
      case "vaniacc.diretti(nonresidenziali)":
        return 0.25;
      case "a2-vaniacc.diretti(mensa,spogliatoio)":
        return 0.25;
      case "municanti(mag.,soppalco,retrobottega)":
        return 0.3;
      case "vani_acc_diretti":
        return 0.25;
      case "vani_acc_comunicanti":
        return 0.3;
      case "vani_acc_non_comunicanti":
        return 0.5;
      case "balconi_terrazze":
        return 0.5;
      case "taverna":
        return 0.3;
      case "altriacessori":
        return 0.3;
      case "giardino":
        return 0.5;
      case "postoautoscoperto":
        return 0.15;
      default:
        return 1;
    }
  }

  uploadFileInterni(ev: any) {
    const files: FileList = ev.target.files;
    this.onFileUploaded.emit({ files: files, type: "interni" });
  }

  uploadFileEsterni(ev: any) {
    const files: FileList = ev.target.files;
    this.onFileUploaded.emit({ files: files, type: "esterni" });
  }

  uploadDocumenti(ev: any) {
    const files: FileList = ev.target.files;
    this.onFileUploaded.emit({ files: files, type: "documenti" });
  }

  onDelete(file: string) {
    this.onDeleteClicked.emit(file);
  }

  onDownload(file: string) {
    this.onDownloadClicked.emit(file);
  }

  onAddressChange(position: any) {
    if (position) {
      const indirizzo = position.civico
        ? position.address + " " + position.civico
        : position.address;

      this.form.controls["cap"].setValue(position.zip);
      this.form.controls["indirizzo"].setValue(indirizzo);
      this.form.controls["comune"].setValue(position.city);
      this.form.controls["frazione"].setValue(position.locality);
      this.form.controls["provincia"].setValue(position.provincia);
      this.form.controls["coordinate"].setValue(
        position.lat + ", " + position.lng
      );
    }
  }
}
