// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'https://europe-west1-tecniciconferitori-test.cloudfunctions.net/api/v1.0/',
  baseUrl: 'https://europe-west1-easystima-4edee.cloudfunctions.net/api/v1.0/',
  googleKey: 'AIzaSyCzNHmjJgmCII7gI1PTqeGL6QJydbor4mY',
  // firebaseConfig: {
  //   apiKey: "AIzaSyDAE6R0YAwL9B2qJtzGCVPK1XyJrHhZx1c",
  //   authDomain: "tecniciconferitori-test.firebaseapp.com",
  //   projectId: "tecniciconferitori-test",
  //   storageBucket: "tecniciconferitori-test.appspot.com",
  //   messagingSenderId: "824584139010",
  //   appId: "1:824584139010:web:3095a67a132e87dc48921b"
  // }
  firebaseConfig: {
    apiKey: "AIzaSyAT_dJ3MQLHXMLQCDixkSjW3FOXCX5aoHE",
    authDomain: "easystima-4edee.firebaseapp.com",
    databaseURL: "https://easystima-4edee.firebaseio.com",
    projectId: "easystima-4edee",
    storageBucket: "easystima-4edee.appspot.com",
    messagingSenderId: "643035315923",
    appId: "1:643035315923:web:5aecc26afd1c4cf4059e26"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
