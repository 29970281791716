import { Component, Input, OnInit, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Immobile } from 'src/app/classi/Comparabili-immobiliare-casa/Immobile';
import { MatDialog } from '@angular/material/dialog';
import { DetailComparabile } from '../../dialogs/dialog-detail/detail.component';
import { DetailSup } from '../../dialogs/sup-detail/supDetail.component';
import { ComparabiliService } from '../../services/comparabili.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { } from 'googlemaps';
import { ConfrontoService } from '../../services/confronto.service';
import { destinazioneUrbanistica, fasiMercato, filtering, formaMercato, macroCategorie, statoConservativo, tipEdi } from 'src/app/classi/ricerca-comparabili-enum';
declare var $: any;

@Component({
  selector: 'comparabili',
  templateUrl: './comparabili.component.html',
  styleUrls: ['./comparabili.component.scss']
})

export class Comparabili implements OnInit, AfterViewInit, OnDestroy {

  private _unsuscribeAll: Subject<any>;

  @Input() indirizzo: string;
  @ViewChild('addresstext', { static: false }) addresstext: any;

  latitude: number;
  longitude: number;
  contratto = "1";
  tipo = "1";
  isMapVisible = false;
  loading = false;
  error = false;
  errorText = "Errore, ";
  showConfronto = false;
  immobili: Immobile[] = [];

  types: Tipologie[] = [
    { value: '1', viewValue: 'Vendita' },
    { value: '2', viewValue: 'Affitto' }
  ];

  typesImmobili: TipologieImmobili[] = [
    { value: '1', viewValue: 'Abitativo' },
    //{ value: '23', viewValue: 'Direzionale' },
    // { value: '1_6', viewValue: 'Garage' },
    { value: '2_16', viewValue: 'Commerciale' },
    //{ value: '', viewValue: 'Turistico ricettivo' },
    { value: '21', viewValue: 'Industriale - Bottega' },
    { value: '25', viewValue: 'Industriale - Capannoni' },
    //{ value: '10_', viewValue: 'Terreni agricoli' },
    //{ value: '10_', viewValue: 'Terreni edificabili' }
  ];

  periodi = [
    {
      label: 'Ultimi sei mesi',
      value: 0
    },
    {
      label: 'Ultimo anno',
      value: 1
    },
    {
      label: 'Ultimi due anni',
      value: 2
    }
  ];
  destUrb = destinazioneUrbanistica;
  tipEdi = tipEdi;
  statoConservativo = statoConservativo;
  formaMercato = formaMercato;
  fasiMercato = fasiMercato;
  filtering = filtering;
  categorie = macroCategorie;

  searchForm: FormGroup;

  constructor(
    private _service: ComparabiliService,
    private _confrontoService: ConfrontoService,
    private dialog: MatDialog,
    private router: Router,
    private formBuilder: FormBuilder) {

    this._unsuscribeAll = new Subject();

  }

  ngOnInit() {
    combineLatest(this._service.onChangeImmobili, this._service.onChangeLatitude, this._service.onChangeLongitude)
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe(([a, b, c]) => {
        if (a) this.immobili = a;
        if (b) this.latitude = b;
        if (c) this.longitude = c;
      });

    this.searchForm = this.formBuilder.group({
      address: ['', Validators.required],
      raggio: [''],
      contratto: [this.contratto, Validators.required],
      tipologia: [this.tipo, Validators.required],
      zona_omi: [null],
      datacompravendita: [null],
      filtering: [null],
      fasidelmercato: [null],
      forma_mercato: [null],
      statoconservativo: [null],
      tipologia_edilizia_ddl: [null],
      subCategory: [null],
      supMin: [null],
      supMax: [null]
    });
  }

  ngAfterViewInit() {
    this._getPlaceAutocomplete();
  }

  ngOnDestroy() {
    this._unsuscribeAll.next();
    this._unsuscribeAll.complete();
  }

  getFromGoogle(form: FormGroup) {
    if (!form.valid) return;
    const raggio = form.value.raggio ? form.value.raggio : 1000;

    this.immobili = [];
    this.loading = true;
    this.isMapVisible = false;
    this.showConfronto = false;
    this.error = false;
    this.errorText = "Errore";

    this._service.getComparabili(raggio, this.contratto, this.tipo, this.searchForm.value).then(() => {
      this.isMapVisible = true;
      $(document).ready(function () {
        $('#dataTableImmobili').DataTable();
      });
    }, (err) => {
      this.error = true
    }
    ).finally(() => this.loading = false);

  }

  private _getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement,
      {
        componentRestrictions: { country: 'IT' },
        types: ['geocode']  // 'establishment' / 'address' / 'geocode'
      });

    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      this._service.modifyLat(place.geometry.location.lat());
      this._service.modifyLng(place.geometry.location.lng());
    });
  }

  showDetail(imm: Immobile) {
    this.dialog.open(DetailComparabile, {
      data: imm
    });
  }

  showSup(imm: Immobile) {
    this.dialog.open(DetailSup, {
      data: imm
    });
  }

  changeSelect(imm: Immobile) {
    this._confrontoService.handleArray(imm);
    this.showConfronto = this._confrontoService.shouldSeeConfronto();
  }

  changeSubject(imm: Immobile) {
    this._confrontoService.subject = imm;
  }

  goToConfronto() {
    this.router.navigateByUrl('back/dash/comparabili/confronto)');
  }
}

export interface Tipologie {
  value: string;
  viewValue: string;
}

export interface TipologieImmobili {
  value: string;
  viewValue: string;
}