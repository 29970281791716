import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SisterAltriDati } from 'src/app/classi/Sister/SisterAltriDati';
import { SisterUtilsService } from '../../services/sister-utils.service';
import { SisterAltriDatiTerreni } from 'src/app/classi/Sister/SisterAltriDatiTerrreni';


@Component({
    selector: 'detail-sister',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.css']
})
export class DetailSister {

    dataPP: SisterAltriDati[];
    dataPPTerreni: SisterAltriDatiTerreni[]

    constructor(
        public dialogRef: MatDialogRef<DetailSister>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public service: SisterUtilsService) {

        this.dataPP = data.fabb;
        this.dataPPTerreni = data.terre

        this.dataPPTerreni.forEach(data => {
            data.mq = (+data.ha * 1000 + +data.ca * 100 + +data.are).toLocaleString("it-it", { minimumFractionDigits: 0 })
        })
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}