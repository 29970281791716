import { Routes } from '@angular/router';
import { Main } from './components/main/main.component';
import { login } from './components/login/login.component';
import { SignUp } from './components/signup/signup.component';

export const routes: Routes = [
    {
        path: '',
        component: Main,
        children: [
            {
                path: '',
                redirectTo: 'login',
                pathMatch: 'full'
            },
            {
                path: 'login',
                component: login
            },
            {
                path: 'signup',
                component: SignUp
            }
        ]
    }
]