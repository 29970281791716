import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { BehaviorSubject, Observable } from "rxjs";
import { User } from "src/app/classi/User";
import { map } from "rxjs/operators";
import { AngularFireFunctions } from "@angular/fire/functions";
import { FirebaseCollectionsHelper } from "src/helpers/firebase-collections-helper";
import { SharedService } from "src/app/shared/services/shared.service";

@Injectable()
export class UtentiService {
  utenti: User[] = [];
  onUtentiChanged = new BehaviorSubject<User[]>(null);

  constructor(
    private _db: AngularFirestore,
    private _fns: AngularFireFunctions,
    private _sharedService: SharedService
  ) {}

  resolve(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this._getUsers().subscribe((users) => {
        this.utenti = users;
        this.onUtentiChanged.next(users);

        resolve();
      }, reject);
    });
  }

  private _getUsers(): Observable<User[]> {
    return this._db
      .collection(FirebaseCollectionsHelper.USERS)
      .snapshotChanges()
      .pipe(
        map((users) =>
          users.map((user) => {
            const us = user.payload.doc.data() as User;
            us.visibleRole = this._sharedService.translateRoles(us.userType);
            return {
              id: user.payload.doc.ref.id,
              ...us,
            };
          })
        )
      );
  }

  editUser(user: Partial<User>): Promise<any> {
    return new Promise((resolve, reject) => {
      this._db
        .doc(FirebaseCollectionsHelper.USERS + "/" + user.id)
        .update({
          ...user,
        })
        .then(resolve, reject);
    });
  }

  saveUser(user: Partial<User>): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this._fns
        .httpsCallable("createUser")({
          ...user,
        })
        .subscribe((res) => {
          if (res === "Success") resolve();
          else reject();
        }, reject);
    });
  }

  deleteUser(user: User) {
    try {
      this._db.doc(FirebaseCollectionsHelper.USERS + "/" + user.id).delete();
    } catch (e) {
      console.log(e);
    }
  }
}
