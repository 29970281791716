import { Component, ViewChild, OnInit, OnDestroy } from "@angular/core";
import { ElencoPerizieService } from "../../services/elenco-perizie.service";
import { MatTableDataSource, MatPaginator, MatDialog } from "@angular/material";
import { Perizia } from "src/app/classi/Perizia/Perizia";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { RealPerizia } from "src/app/classi/Perizia/RealPerizia";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "src/app/modules/auth/services/auth-service";
import { ConfirmDialog } from "src/app/shared/confirm-dialog/dialog.component";
import { Router } from "@angular/router";

@Component({
  selector: "elenco-perizie",
  templateUrl: "./elenco-perizie.component.html",
  styleUrls: ["./elenco-perizie.component.scss"],
})
export class ElencoPerizieComponent implements OnInit, OnDestroy {
  private _unsuscribeAll: Subject<any>;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  get isAdmin(): boolean {
    return this._authService.isAdminRole;
  }

  displayedColumns = [
    "date",
    //"state",
    //  "visible",
    "address",
    "coordinates",
    "user",
    "more",
  ];
  dataSource = new MatTableDataSource([]);
  showExportButton: boolean;
  idToEdit: string;

  constructor(
    private _service: ElencoPerizieService,
    private _authService: AuthService,
    private dialog: MatDialog,
    private _router: Router,
    private _http: HttpClient
  ) {
    this._unsuscribeAll = new Subject();
  }

  ngOnInit() {
    this._service.onPerizieChanges
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res) {
          this.dataSource.data = res;
        }
      });

    this._service.onPerizieToExportChange
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res) {
          this.showExportButton = !(res.length == 0);
        }
      });

    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    this._unsuscribeAll.next();
    this._unsuscribeAll.complete();
  }

  selectPerizia(perizia: RealPerizia) {
    this._service.handlePerizieToExport(perizia);
  }

  export() {
    this._service.export("test");
  }

  isEditing(perizia: RealPerizia): boolean {
    return perizia.$id === this.idToEdit;
  }

  isOwnedByUser(comparabile: RealPerizia): boolean {
    return comparabile.userId === this._authService.getUserId;
  }

  delete(comparabile: RealPerizia) {
    const dialog = this.dialog.open(ConfirmDialog);
    dialog
      .afterClosed()
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res === 0) this._service.deleteComparabile(comparabile);
      });
  }

  edit(comparabile: RealPerizia) {
    this.idToEdit = comparabile.$id;
    this._router.navigate(["dash", "new-perizia", comparabile.$id]);
  }

  async print(perizia: RealPerizia) {
    this.idToEdit = perizia.$id;
    const blob = await this._service.print(perizia);
    if (blob.type === "application/json") {
      alert("Hai già generato tutti i codici, creane di nuovi per stamparli");
    } else {
      const file = new Blob([blob], { type: "application/pdf" });
      const fileURL = URL.createObjectURL(file);
      const fileLink = document.createElement("a");
      fileLink.href = fileURL;
      fileLink.download = perizia.name;
      fileLink.click();
    }
  }

  async duplicate(comparabile: RealPerizia) {
    this.idToEdit = comparabile.$id;
    const id = await this._service.duplicate(comparabile);
    this._router.navigate(["dash", "new-perizia", id]);
  }
}
