import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../material/material.module';
import { ConfirmDialog } from './dialog.component';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule
    ],
    declarations: [
        ConfirmDialog
    ],
    entryComponents: [

    ],
    exports: [ConfirmDialog]
})
export class SharedDialogModule { }
