import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from '../../modules/auth/services/auth-service';

@Injectable({ providedIn: 'root' })
export class AuthAdminGuards implements CanActivate {

    constructor(
        private _logIn: AuthService,
        private router: Router) { }

    canActivate() {
        return this._handleNavigation();
    }

    private _handleNavigation(): boolean {
        if (!this._logIn.isAdminRole) {
            this.router.navigateByUrl('/back');
            return false;
        }
        return true;
    }
} 