import { Injectable } from "@angular/core";
import {
  AngularFirestore,
  DocumentChangeAction,
} from "@angular/fire/firestore";
import { FirebaseCollectionsHelper } from "src/helpers/firebase-collections-helper";
import { Perizia } from "src/app/classi/Perizia/Perizia";
import { BehaviorSubject, Observable, combineLatest } from "rxjs";
import { map } from "rxjs/operators";
import { StatiPeriziaHelper } from "src/helpers/stati-perizia-helper";
import { SharedService } from "src/app/shared/services/shared.service";
import { AuthService } from "../../auth/services/auth-service";
import * as _ from "lodash";

@Injectable()
export class ElencoComparabiliService {
  comparabili: Perizia[] = [];
  onComparabiliChanges = new BehaviorSubject<Perizia[]>(null);

  constructor(
    private _authService: AuthService,
    private _db: AngularFirestore,
    private _sharedService: SharedService
  ) { }

  resolve(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this._getComparabili().subscribe((perizie) => {
        this.comparabili = perizie;
        this.onComparabiliChanges.next(this.comparabili);

        resolve();
      }, reject);
    });
  }

  deleteComparabile(comp: Perizia) {
    try {
      this._db
        .doc(FirebaseCollectionsHelper.COMPARABILI + "/" + comp.id)
        .delete();
    } catch (e) {
      console.log(e);
    }
  }

  editComparabile(comp: Perizia, state: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this._db
        .doc(FirebaseCollectionsHelper.COMPARABILI + "/" + comp.id)
        .update({
          state:
            state === 0
              ? StatiPeriziaHelper.ACCEPTED
              : StatiPeriziaHelper.RETURNED,
        })
        .then(resolve, reject);
    });
  }

  validaComparabile(comp: Perizia) {
    return new Promise((resolve, reject) => {
      this._db
        .doc(FirebaseCollectionsHelper.COMPARABILI + "/" + comp.id)
        .update({
          state: StatiPeriziaHelper.PENDING,
        })
        .then(resolve, reject);
    });
  }

  async duplicateComparabile(comp: Perizia): Promise<string> {
    const copy = { ...comp };
    delete copy.id;
    delete copy.dataInserimento;
    delete copy.user;
    delete copy.userName;
    delete copy.userSurname;

    const newComp: Partial<Perizia> = {
      ...copy, dataInserimento: new Date() as any,
      user: this._db.doc("users/" + localStorage.getItem("USER_UID")).ref,
      state: "DRAFT"
    }

    const id = (await this._db.collection(FirebaseCollectionsHelper.COMPARABILI).add(newComp)).id;
    return id;
  }

  private _getQuery(): Observable<DocumentChangeAction<any>[]>[] {
    return this._authService.isUserRole
      ? [
        this._db
          .collection(FirebaseCollectionsHelper.COMPARABILI, (ref) =>
            ref
              .where(
                "user",
                "==",
                this._db.doc(
                  FirebaseCollectionsHelper.USERS +
                  "/" +
                  this._authService.getUserId
                ).ref
              )
              .orderBy("dataInserimento", "desc")
          )
          .snapshotChanges(),
      ]
      : [
        this._db
          .collection(FirebaseCollectionsHelper.COMPARABILI, (ref) =>
            ref
              .where("state", "in", [
                StatiPeriziaHelper.ACCEPTED,
                StatiPeriziaHelper.PENDING,
                StatiPeriziaHelper.RETURNED,
              ])
              .orderBy("dataInserimento", "desc")
          )
          .snapshotChanges(),
        this._db
          .collection(FirebaseCollectionsHelper.COMPARABILI, (ref) =>
            ref
              .where(
                "user",
                "==",
                this._db.doc(
                  FirebaseCollectionsHelper.USERS +
                  "/" +
                  this._authService.getUserId
                ).ref
              )
              .where("state", "==", StatiPeriziaHelper.DRAFT)
              .orderBy("dataInserimento", "desc")
          )
          .snapshotChanges(),
      ];
  }

  private _getComparabili(): Observable<Perizia[]> {
    return combineLatest(this._getQuery()).pipe(
      map((perizie) => {
        const allPerizie = perizie
          .reduce((a, b) => _.concat(a, b))
          .map((perizia) => {
            const comp = perizia.payload.doc.data() as Perizia;
            comp.visibleState = this._sharedService.translateState(comp.state);
            return {
              id: perizia.payload.doc.ref.id,
              ...comp,
            };
          }) as Perizia[];

        return allPerizie;
      })
    );
  }
}
