import { NgModule } from '@angular/core';
import { AngularOpenlayersModule } from 'ngx-openlayers';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '../material/material.module';
import { OsmViewComponent } from './open-map.component';
import { CommonModule } from '@angular/common';
import { GeoLocationService } from './open-map.service';

@NgModule({
    imports: [CommonModule,
        MaterialModule,
        AngularOpenlayersModule,
        HttpClientModule],
        declarations: [OsmViewComponent],
    exports: [OsmViewComponent],
    providers: [GeoLocationService]

})
export class OpenStreetMapModule { }