import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuperficieExtra } from 'src/app/classi/Sister/SuperficieExtra';
import { Immobile } from 'src/app/classi/Comparabili-immobiliare-casa/Immobile';


@Component({
    selector: 'supDetail-comparabile',
    templateUrl: './supDetail.component.html',
    styleUrls: ['./supDetail.component.css']
})
export class DetailSup {

    dataP: SuperficieExtra[];

    constructor(
        public dialogRef: MatDialogRef<DetailSup>,
        @Inject(MAT_DIALOG_DATA) public data: Immobile) {

        let a: SuperficieExtra[] = [];
        data.supExtra.forEach(extra => {
            const e = new SuperficieExtra(extra.consistenza, extra.piano, extra.superficie, extra.coefficiente, extra.tipo, extra.supCommerciale);
            a.push(e);
        });

        this.dataP = a;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}