import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'stamina-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.scss'],
})
export class StaminaFileComponent {
    @Input() withFiletype = false;
    @Input() report: string;
    @Input() visibleReport?: string;
    @Input() isDeletable = false;
    @Output() onDeleteClicked = new EventEmitter<string>();
    @Output() onDownloadClicked = new EventEmitter<string>();

    constructor() {
    }

    onDeleteClick(fileId: string) {
        this.onDeleteClicked.emit(fileId);
    }

    onDownloadClick(fileId: string) {
        this.onDownloadClicked.emit(fileId);
    }
}
