import { OnInit, Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator } from '@angular/material';

@Component({
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class ComuniDialog implements OnInit {

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    dataSource = new MatTableDataSource([]);
    columnsToDisplay = ['select1', 'display_name', 'lat', 'lon'];

    constructor(
        public dialogRef: MatDialogRef<ComuniDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any[]) { }

    ngOnInit() {
        this.dataSource.data = this.data;
        this.dataSource.paginator = this.paginator;
    }

    select(select: any) {
        this.dialogRef.close(select);
    }
}