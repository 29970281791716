import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Etichetta } from 'src/app/classi/Etichetta';

@Component({
    selector: 'checkbox',
    templateUrl:"./checkbox.html"
})
export class CheckBoxComponent {

    @Input() field: Etichetta;
    @Input() form: FormGroup;
    @Input() isViewMode?: boolean = false;

    get isValid() { return this.form.controls[this.field.name].valid; }
    get isDirty() { return this.form.controls[this.field.name].dirty; }
}