import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { MatDialog, MatStepper } from "@angular/material";
import { Router } from "@angular/router";
import { combineLatest, Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { Etichetta } from "src/app/classi/Etichetta";
import { RealPerizia } from "src/app/classi/Perizia/RealPerizia";
import { User } from "src/app/classi/User";
import { EtichetteService } from "../../../services/etichette.service";
import { NuovaPeriziaService } from "../../../services/nuova-perizia.service";
import { InitPeriziaDialog } from "./dialogs/init-perizia/dialog.component";
import { SisterClass } from "src/app/classi/Sister/Sister";

@Component({
  selector: "nuova-perizia",
  templateUrl: "./nuova-perizia.component.html",
  styleUrls: ["./nuova-perizia.component.css"],
})
export class NuovaPeriziaComponent implements OnInit, OnDestroy {
  @ViewChild("stepper", { static: false }) stepper: MatStepper;
  private _unsuscribeAll: Subject<any>;
  etichette: Etichetta[] = [];
  model: Partial<RealPerizia> = {};
  category: number;
  userIn: User;
  loading = false;
  showCatasto = false;
  hasSearchedCatasto = false;
  intestatiIndex = 1000000;
  intestati: any[] = [];
  periziaId: string;

  constructor(
    private _service: NuovaPeriziaService,
    private _dialog: MatDialog,
    private _etichettService: EtichetteService,
    private _router: Router
  ) {
    this._unsuscribeAll = new Subject();
    this.periziaId = this._service.periziaId;
  }

  ngOnInit() {
    if (!this.periziaId)
      this._dialog
        .open(InitPeriziaDialog, { disableClose: true })
        .afterClosed()
        .pipe(take(1))
        .subscribe(
          (res: {
            name: string;
            category: number;
            unitNumber: string;
            codice: string;
          }) => {
            if (res) {
              this.category = res.category;
              this.save(res);
            }
          }
        );

    combineLatest([
      this._etichettService.onEtichetteChanged,
      this._service.perizia,
    ])
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe(([res, b]) => {
        if (res) this.etichette = res;
        if (b) this.model = b;
      });
  }

  ngOnDestroy() {
    this._unsuscribeAll.next();
    this._unsuscribeAll.complete();
  }

  save(event: any): void {
    const keys = Object.keys(event);
    keys.forEach((key) => {
      this.model[key] = event[key];
    });
    this._service.perizia.next(this.model);
  }

  setSubjectId(id: string) {
    this.model.subjectId = id;
    this._service.perizia.next(this.model);
    this.stepper.next();
  }

  uploadPerizia(event: any) {
    this.save(event);
    if (!this.periziaId)
      this._service
        .savePerizia(this.model)
        .then((_) => this._router.navigateByUrl("/dash/elenco-perizie"));
    else
      this._service
        .editPerizia(this.periziaId, this.model)
        .then((_) => this._router.navigateByUrl("/dash/elenco-perizie"));
  }

  selectionChange(event: any) {
    if (event.selectedIndex === 2) this.showCatasto = true;
  }

  sister(event: SisterClass[]) {
    this.hasSearchedCatasto = true;
    this.model.catasto = event;
    const int = event.map((a) => a.intestati);
    int.forEach((b, i) => {
      b.forEach((c) => this.intestati.push({ ...c, rif: i + 1 }));
    });
  }

  showIntestati(index: number) {
    this.intestatiIndex = index;
  }

  editSubject(id: string) {
    this._router.navigate(["dash/edit-comparabile", id], { queryParams: { periziaId: this.periziaId } });
  }
}
