import { OnInit, Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource, MatPaginator } from '@angular/material';

@Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})
export class ZoneOmiDialog implements OnInit {

    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

    dataSource = new MatTableDataSource([]);
    columnsToDisplay = ['select', 'desc', 'stato', 'comprMax', 'comprMin', 'locMax', 'locMin'];

    constructor(
        public dialogRef: MatDialogRef<ZoneOmiDialog>,
        @Inject(MAT_DIALOG_DATA) public data: any[]) { }

    ngOnInit() {
        console.log(this.data);
        
        this.dataSource.data = this.data;
        this.dataSource.paginator = this.paginator;
    }

    select(select: any) {
        this.dialogRef.close(select);
    }
}