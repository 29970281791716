import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Immobile } from 'src/app/classi/Comparabili-immobiliare-casa/Immobile';
import { Etichetta } from 'src/app/classi/Etichetta';
import { ConfrontoService } from '../../services/confronto.service';
declare var $: any;

@Component({
    selector: 'confronto',
    templateUrl: './confronto.component.html',
    styleUrls: ['./confronto.component.scss']
})
export class Confronto implements OnInit, OnDestroy {

    private _unsubscribeAll: Subject<any>;

    immobiliDaConfrontare: Immobile[] = [];
    selectedChars: string[] = [];
    subject: Immobile;
    etichette: Etichetta[];

    constructor(
        private _service: ConfrontoService,
        private _router: Router) {

        this._unsubscribeAll = new Subject();
    }

    ngOnInit() {
        this.immobiliDaConfrontare = this._service.immobiliPerConfronto;
        this.subject = this._service.subject;

        this._service.onChangeEtichette
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(res => {
                if (res) this.etichette = res;
            });

        $(document).ready(function () {
            $('#dataTableConfronto').DataTable({
                scrollX: true,
                scrollCollapse: true,
                paging: false,
                searching: false,
                info: false,
                bSort: false,
                dom: 'Bfrtip',
                buttons: [
                    'copy', 'excel', 'pdf', 'print'
                ]
            });

            $('#dataTableExtra').DataTable({
                scrollX: true,
                scrollCollapse: true,
                paging: false,
                searching: false,
                info: false,
                bSort: false,
                dom: 'Bfrtip',
                buttons: [
                    'copy', 'excel', 'pdf', 'print'
                ]
            });
        });
    }

    ngOnDestroy() {
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();

        this._service.immobiliPerConfronto = [];
    }

    selectAll() {
        this.selectedChars = this.etichette.map(a => a.name);
    }

    deselectAll() {
        this.selectedChars = [];
    }

    selectVal() {
        this.etichette.map(a => a.name).forEach(char => {
            if (this.subject[char] != null && this.subject[char] != undefined && this.subject[char] != "") this.selectedChars.push(char);
        });
    }

    changed(evt: any, char: string) {
        const index = this.selectedChars.indexOf(char);
        if (evt.target.checked) {
            this.selectedChars.push(char);
        } else {
            this.selectedChars.splice(index, 1);
        }
    }

    isChecked(char: string): boolean {
        return this.selectedChars.indexOf(char) != -1;
    }

    mca() {
        this._router.navigate(["dash", "mca", "do", "new"], { queryParams: { location: 'tabella_dati' } })
            .then(() => this._service.onChange.next({ immobili: this.immobiliDaConfrontare, chars: this.selectedChars, subject: this.subject }));
    }
}