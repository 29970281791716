import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatStepper, MatTableDataSource } from "@angular/material";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NuovaPeriziaService } from "src/app/modules/back/services/nuova-perizia.service";
import { ChooseFileDialog } from "../../dialogs/choose-file/dialog.component";

@Component({
  selector: "due-diligence-component",
  templateUrl: "./due-diligence.component.html",
  styleUrls: ["./due-diligence.component.scss"],
})
export class DueDiligenceComponent implements OnInit, OnDestroy {
  @Input() stepper: MatStepper;
  @Output() save = new EventEmitter();
  private _unsuscribeAll: Subject<any>;

  files: { acronimo: string; sezione: string; file: string | File }[];
  statoDiPossesso: FormGroup[] = [];
  statiDiPossesso = [
    { label: "Libero", value: 0 },
    { label: "Condotto proprietà", value: 1 },
    { label: "Locato terzi", value: 2 },
    { label: "Comodato", value: 3 },
    { label: "Occupato senza titolo", value: 4 },
    { label: "Altro", value: 5 },
  ];
  dataSourceStatoDiPossesso = new MatTableDataSource([]);
  displayedColumnsStatoDiPossesso: string[] = [
    "sub",
    "stato",
    "note",
    "allega",
  ];

  urbanistica: FormGroup[] = [];
  urbanistiche = [
    { label: "Assente", value: 0 },
    { label: "Piano regolatore generale", value: 1 },
    { label: "Piani attuativi", value: 2 },
    { label: "Piani di zona", value: 3 },
  ];
  dataSourceUrbanistica = new MatTableDataSource([]);
  displayedColumnsUrbanistica: string[] = ["sub", "stato", "note", "allega"];

  edilizia: FormGroup[] = [];
  titoloAbitativo = [
    { label: "Assente", value: 0 },
    { label: "Piano regolatore generale", value: 1 },
    { label: "Piani attuativi", value: 2 },
    { label: "Piani di zona", value: 3 },
  ];
  dataSourceEdilizia = new MatTableDataSource([]);
  displayedColumnsEdilizia: string[] = [
    "sub",
    "titolo",
    "dataInizio",
    "dataFine",
    "note",
    "allega",
  ];

  vincoli: FormGroup[] = [];
  dataSourceVincoli = new MatTableDataSource([]);
  displayedColumnsVincoli: string[] = ["sub", "stato", "note", "allega"];

  servitu: FormGroup[] = [];
  tipoUrbanistico = [
    { label: "Apparente", value: 0 },
    { label: "Da titolo", value: 1 },
  ];
  dataSourceServitu = new MatTableDataSource([]);
  displayedColumnsServitu: string[] = [
    "sub",
    "servitu",
    "stato",
    "note",
    "allega",
  ];

  impianti: FormGroup[] = [];
  dataSourceImpianti = new MatTableDataSource([]);
  displayedColumnsImpianti: string[] = [
    "sub",
    "impianto",
    "certificazione",
    "stato",
    "note",
    "allega",
  ];
  certificazioni = [
    { label: "Assente", value: 0 },
    { label: "Presente", value: 1 },
    { label: "Non prodotto", value: 2 },
  ];
  stati = [
    { label: "Vuoto", value: 0 },
    { label: "Funzionante", value: 1 },
    { label: "Non funzionante", value: 2 },
  ];

  provenienza: FormGroup[] = [];
  dataSourceProvenienza = new MatTableDataSource([]);
  displayedColumnsProvenienza: string[] = [
    "sub",
    "asset",
    "tipoAtto",
    "data",
    "repertorio",
    "raccolta",
    "rogante",
    "sede",
  ];

  formNote: FormGroup;
  selectedIdo: number;

  constructor(
    private _service: NuovaPeriziaService,
    private _dialog: MatDialog,
    private _formBuilder: FormBuilder
  ) {
    this._unsuscribeAll = new Subject();
  }

  ngOnInit() {
    this._service.perizia
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res) {
          if (res.attachments) this.files = res.attachments;
          if (res.statoDiPossesso) {
            res.statoDiPossesso.forEach((a) => {
              this.statoDiPossesso.push(
                this._formBuilder.group({
                  sub: [a.sub],
                  stato: [a.stato, Validators.required],
                  note: [a.note, Validators.required],
                  allegato: [a.allegato, Validators.required],
                })
              );
            });

            this.dataSourceStatoDiPossesso.data = this.statoDiPossesso;
          }
          if (res.urbanistica) {
            res.urbanistica.forEach((a) => {
              this.urbanistica.push(
                this._formBuilder.group({
                  sub: [a.sub],
                  strumento: [a.strumento, Validators.required],
                  note: [a.note, Validators.required],
                  allegato: [a.allegato, Validators.required],
                })
              );
            });

            this.dataSourceUrbanistica.data = this.urbanistica;
          }
          if (res.provenienza) {
            res.provenienza.forEach((a) => {
              this.provenienza.push(
                this._formBuilder.group({
                  sub: [a.sub],
                  asset: [a.asset, Validators.required],
                  tipoAtto: [a.tipoAtto, Validators.required],
                  data: [a.data, Validators.required],
                  repertorio: [a.repertorio, Validators.required],
                  raccolta: [a.raccolta, Validators.required],
                  rogante: [a.rogante, Validators.required],
                  sede: [a.sede, Validators.required],
                })
              );
            });

            this.dataSourceProvenienza.data = this.provenienza;
          }
          if (res.vincoli) {
            res.vincoli.forEach((a) => {
              this.vincoli.push(
                this._formBuilder.group({
                  sub: [a.sub],
                  vincolo: [a.vincolo, Validators.required],
                  note: [a.note, Validators.required],
                  allegato: [a.allegato, Validators.required],
                })
              );
            });

            this.dataSourceVincoli.data = this.vincoli;
          }
          if (res.servitu) {
            res.servitu.forEach((a) => {
              this.servitu.push(
                this._formBuilder.group({
                  sub: [a.sub],
                  servitu: [a.servitu, Validators.required],
                  tipo: [a.tipo, Validators.required],
                  note: [a.note, Validators.required],
                  allegato: [a.allegato, Validators.required],
                })
              );
            });

            this.dataSourceServitu.data = this.servitu;
          }
          if (res.edilizia) {
            res.edilizia.forEach((a) => {
              this.edilizia.push(
                this._formBuilder.group({
                  sub: [a.sub],
                  titolo: [a.titolo, Validators.required],
                  dataInizio: [a.dataInizio, Validators.required],
                  dataFine: [a.dataFine, Validators.required],
                  note: [a.note, Validators.required],
                  allegato: [a.allegato, Validators.required],
                })
              );
            });

            this.dataSourceEdilizia.data = this.edilizia;
          }
          if (res.impianti) {
            res.impianti.forEach((a) => {
              this.impianti.push(
                this._formBuilder.group({
                  sub: [a.sub],
                  impianto: [a.impianto, Validators.required],
                  certificazione: [a.certificazione, Validators.required],
                  stato: [a.stato, Validators.required],
                  note: [a.note, Validators.required],
                  allegato: [a.allegato, Validators.required],
                })
              );
            });

            this.dataSourceImpianti.data = this.impianti;
          }
        }
      });

    if (!this._service.periziaId) {
      this.impianti.push(
        this._formBuilder.group({
          sub: [null],
          impianto: [null, Validators.required],
          certificazione: [null, Validators.required],
          stato: [null, Validators.required],
          note: [null, Validators.required],
          allegato: [null, Validators.required],
        })
      );

      this.edilizia.push(
        this._formBuilder.group({
          sub: [null],
          titolo: [null, Validators.required],
          dataInizio: [null, Validators.required],
          dataFine: [null, Validators.required],
          note: [null, Validators.required],
          allegato: [null, Validators.required],
        })
      );

      this.servitu.push(
        this._formBuilder.group({
          sub: [null],
          servitu: [null, Validators.required],
          tipo: [null, Validators.required],
          note: [null, Validators.required],
          allegato: [null, Validators.required],
        })
      );

      this.vincoli.push(
        this._formBuilder.group({
          sub: [null],
          vincolo: [null, Validators.required],
          note: [null, Validators.required],
          allegato: [null, Validators.required],
        })
      );

      this.provenienza.push(
        this._formBuilder.group({
          sub: [null],
          asset: [null, Validators.required],
          tipoAtto: [null, Validators.required],
          data: [null, Validators.required],
          repertorio: [null, Validators.required],
          raccolta: [null, Validators.required],
          rogante: [null, Validators.required],
          sede: [null, Validators.required],
        })
      );

      this.urbanistica.push(
        this._formBuilder.group({
          sub: [null],
          strumento: [null, Validators.required],
          note: [null, Validators.required],
          allegato: [null, Validators.required],
        })
      );

      this.statoDiPossesso.push(
        this._formBuilder.group({
          sub: [null],
          stato: [null, Validators.required],
          note: [null, Validators.required],
          allegato: [null, Validators.required],
        })
      );
    }

    this.formNote = this._formBuilder.group({ note: [null], coerenze: [null] });

    this.dataSourceProvenienza.data = this.provenienza;
    this.dataSourceStatoDiPossesso.data = this.statoDiPossesso;
    this.dataSourceUrbanistica.data = this.urbanistica;
    this.dataSourceVincoli.data = this.vincoli;
    this.dataSourceServitu.data = this.servitu;
    this.dataSourceEdilizia.data = this.edilizia;
    this.dataSourceImpianti.data = this.impianti;
  }

  ngOnDestroy() {
    this._unsuscribeAll.next();
    this._unsuscribeAll.complete();
  }

  addValue(type: string) {
    if (type === "prov") {
      this.provenienza.push(
        this._formBuilder.group({
          sub: [null],
          asset: [null, Validators.required],
          tipoAtto: [null, Validators.required],
          data: [null, Validators.required],
          repertorio: [null, Validators.required],
          raccolta: [null, Validators.required],
          rogante: [null, Validators.required],
          sede: [null, Validators.required],
        })
      );

      this.dataSourceProvenienza.data = this.provenienza;
    } else if (type === "stato") {
      this.statoDiPossesso.push(
        this._formBuilder.group({
          sub: [null],
          stato: [null, Validators.required],
          note: [null, Validators.required],
          allegato: [null, Validators.required],
        })
      );

      this.dataSourceStatoDiPossesso.data = this.statoDiPossesso;
    } else if (type === "urbanistica") {
      this.urbanistica.push(
        this._formBuilder.group({
          sub: [null],
          strumento: [null, Validators.required],
          note: [null, Validators.required],
          allegato: [null, Validators.required],
        })
      );

      this.dataSourceUrbanistica.data = this.urbanistica;
    } else if (type === "vincolo") {
      this.vincoli.push(
        this._formBuilder.group({
          sub: [null],
          vincolo: [null, Validators.required],
          note: [null, Validators.required],
          allegato: [null, Validators.required],
        })
      );

      this.dataSourceVincoli.data = this.vincoli;
    } else if (type === "servitu") {
      this.servitu.push(
        this._formBuilder.group({
          sub: [null],
          servitu: [null, Validators.required],
          tipo: [null, Validators.required],
          note: [null, Validators.required],
          allegato: [null, Validators.required],
        })
      );

      this.dataSourceServitu.data = this.servitu;
    } else if (type === "edilizia") {
      this.edilizia.push(
        this._formBuilder.group({
          sub: [null],
          titolo: [null, Validators.required],
          dataInizio: [null, Validators.required],
          dataFine: [null, Validators.required],
          note: [null, Validators.required],
          allegato: [null, Validators.required],
        })
      );

      this.dataSourceEdilizia.data = this.edilizia;
    } else if (type === "impianti") {
      this.impianti.push(
        this._formBuilder.group({
          sub: [null],
          impianto: [null, Validators.required],
          certificazione: [null, Validators.required],
          stato: [null, Validators.required],
          note: [null, Validators.required],
          allegato: [null, Validators.required],
        })
      );

      this.dataSourceImpianti.data = this.impianti;
    }
  }

  allega(form: FormGroup) {
    this._dialog
      .open(ChooseFileDialog, { data: this.files })
      .afterClosed()
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res) {
          form.controls.allegato.setValue(res.file);
          form.updateValueAndValidity();
        }
      });
  }

  next() {
    const statoDiPossesso = this.statoDiPossesso.map((a) => a.value);
    const provenienza = this.provenienza.map((a) => a.value);
    const urbanistica = this.urbanistica.map((a) => a.value);
    const edilizia = this.edilizia.map((a) => a.value);
    const vincoli = this.vincoli.map((a) => a.value);
    const servitu = this.servitu.map((a) => a.value);
    const impianti = this.impianti.map((a) => a.value);
    this.save.emit({
      statoDiPossesso: statoDiPossesso,
      urbanistica: urbanistica,
      edilizia: edilizia,
      vincoli: vincoli,
      servitu: servitu,
      impianti: impianti,
      provenienza: provenienza,
      idoneita: {
        ido: this.selectedIdo ? this.selectedIdo : null,
        note: this.formNote.value.note,
      },
    });
    this.stepper.next();
  }

  prev() {
    this.stepper.previous();
  }

  setIdo(ido: number) {
    this.selectedIdo = ido;
  }
}
