import { Routes } from '@angular/router';
import { Comparabili } from './components/comparabili/comparabili.component';
import { Confronto } from './components/confronto/confronto.component';
import { ConfrontoService } from './services/confronto.service';


export const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                redirectTo: 'search',
                pathMatch: 'full'
            },
            {
                path: 'search',
                component: Comparabili
            },
            {
                path: 'confronto',
                component: Confronto,
                resolve: { etichette: ConfrontoService }
            }
        ]
    }
];
