import { Routes } from '@angular/router';
import { Sister } from './components/sister/sister.component';


export const routes: Routes = [
    {
        path: '',
        component: Sister
    }
];
