import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Immobile } from 'src/app/classi/Comparabili-immobiliare-casa/Immobile';


@Component({
    selector: 'detail-comparabile',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.css']
})
export class DetailComparabile {

    dataP: Immobile;

    constructor(
        public dialogRef: MatDialogRef<DetailComparabile>,
        @Inject(MAT_DIALOG_DATA) public data: Immobile) {

        this.dataP = data;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}