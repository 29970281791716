export class Omonini {
    constructor(
        public name: string,
        public surname: string,
        public placeOfBirth: string,
        public dateOfBirth: string,
        public sesso: string,
        public codFisc: string,
        public id: string) { }

}