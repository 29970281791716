import { Component, OnDestroy, Input, Output, EventEmitter } from '@angular/core'
import { marker } from './marker.image'
import { proj } from 'openlayers'
import { Subject } from 'rxjs'
import { GeoLocationService } from './open-map.service'
import { takeUntil } from 'rxjs/operators'

@Component({
    selector: 'vo-ui-osm-view',
    templateUrl: './open-map.component.html'
})
export class OsmViewComponent implements OnDestroy {

    private _unsuscribeAll: Subject<any>;

    @Input() width: string;
    @Input() height: string;
    @Input() latitude: number;
    @Input() longitude: number;
    @Input() latitudePointer: number;
    @Input() longitudePointer: number;
    @Input() showControlsZoom: boolean;
    @Input() zoom = 17;

    @Output() addressChanged = new EventEmitter<any>();

    markerImage = marker;

    constructor(
        private _service: GeoLocationService) {

        this._unsuscribeAll = new Subject();
    }

    ngOnDestroy() {
        this._unsuscribeAll.next();
        this._unsuscribeAll.complete();
    }

    onSingleClick(event: any) {
        const lonlat = proj.transform(event.coordinate, 'EPSG:3857', 'EPSG:4326');
        this.longitudePointer = lonlat[0];
        this.latitudePointer = lonlat[1];
        this._reverseGeo();
    }

    increaseZoom() {
        this.zoom++;
    }

    decreaseZoom() {
        this.zoom--;
    }

    private _reverseGeo() {
        this._service.reverseGeo(this.latitudePointer, this.longitudePointer)
            .pipe(takeUntil(this._unsuscribeAll))
            .subscribe(data => {
                const val = (data || {});

                let lat = null;
                let lng = null;
                let address = null;
                let provincia = null;
                let zip = null;
                let city = null;
                let civico = null;
                let locality = null;
                if (val['lat']) lat = val['lat'];
                if (val['lon']) lng = val['lon'];
                if (val['address']['road']) address = val['address']['road'];
                if (val['address']['county']) provincia = val['address']['county'];
                if (val['address']['postcode']) zip = val['address']['postcode'];
                if (val['address']['town']) city = val['address']['town'];
                if (val['address']['city']) city = val['address']['city'];
                if (val['address']['house_number']) civico = val['address']['house_number'];
                if (val['address']['quarter']) locality = val['address']['quarter'];

                this.addressChanged.emit({ address: address, locality: locality, civico: civico, provincia: provincia, city: city, zip: zip, lat: lat, lng: lng });
            });
    }
}