import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatStepper } from "@angular/material";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RealPerizia } from "src/app/classi/Perizia/RealPerizia";
import { NuovaPeriziaService } from "src/app/modules/back/services/nuova-perizia.service";

@Component({
  selector: "intestazione-component",
  templateUrl: "./intestazione.component.html",
  styleUrls: ["./intestazione.component.scss"],
})
export class IntestazioneComponent implements OnInit, OnDestroy {
  @Input() stepper: MatStepper;
  @Output() save = new EventEmitter();
  private _unsuscribeAll: Subject<any>;

  form: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private _service: NuovaPeriziaService
  ) {
    this._unsuscribeAll = new Subject();
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      societyName: [null],
      societyCity: [null],
      societyProv: [null],
      societyAddress: [null],
      societyPiva: [null],
      client: [null],
      client1: [null],
      client2: [null],

      bankName: [null],
      bankCity: [null],
      bankProv: [null],
      bankAddress: [null],
      bankPiva: [null],

      societyReteName: [null],
      societyReteCity: [null],
      societyReteProv: [null],
      societyReteAddress: [null],
      societyRetePiva: [null],

      houseNumber: [null],
      houseDate: [null],
      houseCity: [null],
      houseProv: [null],
      houseCap: [null],
      houseAddress: [null],
      houseMore: [null],

      accountName: [null],
      accountBornCity: [null],
      accountBornProv: [null],
      accountBirthDate: [null],
      accountDocumentNumber: [null],
      accountCity: [null],
      accountCap: [null],
      accountProv: [null],
      accountAddress: [null],
      accountRegistryOrder: [null],
      accountRegistryOrderNumber: [null],
      accountRegistryOrderQualify: [null],

      validatoreName: [null],
      validatoreBornCity: [null],
      validatoreBornProv: [null],
      validatoreBirthDate: [null],
      validatoreDocumentNumber: [null],
      validatoreCity: [null],
      validatoreCap: [null],
      validatoreProv: [null],
      validatoreAddress: [null],
      validatoreRegistryOrder: [null],
      validatoreRegistryOrderNumber: [null],
      validatoreRegistryOrderQualify: [null],
    });

    this._service.perizia
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res) this._preloadDatas(res);
      });
  }

  ngOnDestroy() {
    this._unsuscribeAll.next();
    this._unsuscribeAll.complete();
  }

  next() {
    this.save.emit({ intestazione: this.form.value });
    this.stepper.next();
  }

  prev() {
    this.stepper.previous();
  }

  private _preloadDatas(val: Partial<RealPerizia>) {
    if (val.intestazione) {
      this.form.patchValue(val.intestazione);
      this.form.updateValueAndValidity();
    }
  }
}
