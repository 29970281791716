import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { Subject } from "rxjs";
import { UtentiService } from "../../services/utenti.service";
import { takeUntil } from "rxjs/operators";
import { MatTableDataSource, MatPaginator, MatDialog } from "@angular/material";
import { User, UserType } from "src/app/classi/User";
import { NewEditUser } from "./dialogs/new-edit/dialog.component";
import { ConfirmDialog } from "src/app/shared/confirm-dialog/dialog.component";

@Component({
  selector: "utenti",
  templateUrl: "./utenti.component.html",
  styleUrls: ["./utenti.component.scss"],
})
export class UtentiComponent implements OnInit, OnDestroy {
  private _unsuscribeAll: Subject<any>;

  displayedColumns = [
    "nome",
    "cognome",
    "userType",
    "disabled",
    "subDate",
    "perizie",
    "more",
  ];
  dataSource = new MatTableDataSource([]);
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private _usersService: UtentiService, private dialog: MatDialog) {
    this._unsuscribeAll = new Subject();
  }

  ngOnInit() {
    this._usersService.onUtentiChanged
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res) this.dataSource.data = res;
      });

    this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    this._unsuscribeAll.next();
    this._unsuscribeAll.complete();
  }

  addUser() {
    this.dialog.open(NewEditUser);
  }

  editUser(user: User) {
    this.dialog.open(NewEditUser, {
      data: user,
    });
  }

  enablePerizie(user: User) {
    this._usersService.editUser({
      ...user,
      canSeePerizia: user.canSeePerizia ? !user.canSeePerizia : true,
    });
  }

  sospendUser(user: User) {
    const newUser: Partial<User> = {
      ...user,
      disabled: !user.disabled,
    };
    const dialog = this.dialog.open(ConfirmDialog);
    dialog
      .afterClosed()
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res === 0) this._usersService.editUser(newUser);
      });
  }

  deleteUser(user: User) {
    const dialog = this.dialog.open(ConfirmDialog);
    dialog
      .afterClosed()
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res === 0) this._usersService.deleteUser(user);
      });
  }

  acceptUser(user: User) {
    const newUser: Partial<User> = {
      ...user,
      userType: UserType.user,
    };
    const dialog = this.dialog.open(ConfirmDialog);
    dialog
      .afterClosed()
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res === 0) this._usersService.editUser(newUser);
      });
  }
}
