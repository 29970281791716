import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatSelectChange, MatStepper } from "@angular/material";
import { Subject } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { NuovaPeriziaService } from "src/app/modules/back/services/nuova-perizia.service";
import { NewAcronimoDialog } from "../../dialogs/new-acronomico/dialog.component";

@Component({
  selector: "allegati-component",
  templateUrl: "./allegati.component.html",
  styleUrls: ["./allegati.component.scss"],
})
export class AllegatiComponent implements OnInit, OnDestroy {
  @Input() stepper: MatStepper;
  @Output() save = new EventEmitter();
  private _unsuscribeAll: Subject<any>;

  form: FormGroup;
  file: File;
  files: { acronimo: string; sezione: string; nome: string; file: File }[] = [];
  sezioni = [
    { value: 0, label: "Proprietà" },
    { value: 1, label: "Dati catastali" },
    { value: 2, label: "Liceità edilizia ed urbanistica" },
    { value: 3, label: "Sopralluogo" },
    { value: 4, label: "Dati per la valutazione" },
    { value: 5, label: "Rapporto di valutazione" },
    { value: 6, label: "Altra documentazione" },
    { value: 7, label: "Documentazione per AQR" },
    { value: 8, label: "Altro" },
  ];
  acronimi = [
    { value: "APROV", label: "APROV - atto di provenienza", sezione: 0 },
    {
      value: "DPREL",
      label:
        "DPREL - dichiarazione del cliente attestante l’elenco dei preliminari sottoscritti e/o assenti",
      sezione: 0,
    },
    {
      value: "IPROV",
      label: "IPROV - conferimento Incarico agli Infoprovider",
      sezione: 0,
    },
    {
      value: "LDISM",
      label: "LDISM - lettera dell'Ente per la Dismissione dell'Immobile",
      sezione: 0,
    },
    {
      value: "PRPAQ",
      label: "PRPAQ - proposta di acquisto o compromesso",
      sezione: 0,
    },
    { value: "SUCCE", label: "SUCCE - documento di Successione", sezione: 0 },
    {
      value: "EPLAN",
      label:
        "EPLAN - elaborato Planimetrico Catastale Rasterizzato e Relativo Elenco Immobili",
      sezione: 1,
    },
    { value: "MCATA", label: "MCATA - foglio di Mappa Catastale", sezione: 1 },
    {
      value: "PCRAS",
      label: "PCRAS - planimetria Catastale Rasterizzata",
      sezione: 1,
    },
    {
      value: "PRAST",
      label:
        "PRAST - planimetrie Catastali Rasterizzate di tutte le porzioni presenti nell’elaborato planimetrico",
      sezione: 1,
    },
    {
      value: "VPLAN",
      label:
        "VPLAN - visure Storiche Catastali di tutte le porzioni presenti nell'elaborato planimetrico",
      sezione: 1,
    },
    { value: "VSTOR", label: "VSTOR - visura Storico Catastale", sezione: 1 },
    {
      value: "CDEST",
      label: "CDEST - certificato di Destinazione Urbanistica Aggiornato",
      sezione: 2,
    },
    {
      value: "CEDST",
      label: "CEDST - concessione Edilizia in sanatoria",
      sezione: 2,
    },
    {
      value: "CRTAB",
      label: "CRTAB - certificato di Abitabilità o di Agibilità",
      sezione: 2,
    },
    {
      value: "LICED",
      label: "LICED - licenza / Concessione Edilizia",
      sezione: 2,
    },
    { value: "PCOST", label: "PCOST - permesso a Costruire", sezione: 2 },
    {
      value: "PPOEG",
      label: "PPOEG - progetto Protocollato o Elaborato Grafico",
      sezione: 2,
    },
    {
      value: "RELTC",
      label:
        "RELTC - relazione tecnica asseverata firmata dal tecnico di supporto all'istruttoria",
      sezione: 2,
    },
    {
      value: "ROPER",
      label: "ROPER - relazione Opere già Realizzate",
      sezione: 2,
    },
    {
      value: "SANAT",
      label: "SANAT - domanda / Istanza di sanatoria",
      sezione: 2,
    },
    {
      value: "TAOPE",
      label:
        "TAOPE - titolo Autorizzativo Opere Esecuzione (Permesso di costruire / D.I.A. / S.C.I.A. / C.I.L.A.)",
      sezione: 2,
    },
    {
      value: "CEDST",
      label: "CEDST - concessione Edilizia in sanatoria",
      sezione: 2,
    },
    {
      value: "-df.est",
      label: "-df.est - documentazione Fotografica degli Esterni",
      sezione: 3,
    },
    {
      value: "-df.int",
      label: "-df.int - documentazione Fotografica degli interni",
      sezione: 3,
    },
    {
      value: "FGALL",
      label: "FGALL - foto Galleria",
      sezione: 3,
    },
    {
      value: "CMETR",
      label: "CMETR - computo Metrico",
      sezione: 4,
    },
    {
      value: "CTU",
      label: "CTU - C.T.U. - Consulenza Tecnica d'Ufficio",
      sezione: 4,
    },
    {
      value: "PEFIN",
      label: "PEFIN - piano Economico Finanziario",
      sezione: 4,
    },
    {
      value: "-perboz",
      label: "-perboz - perizia di stima in bozza",
      sezione: 5,
    },
    {
      value: "PERIZ",
      label: "PERIZ - perizia",
      sezione: 5,
    },
    {
      value: "PFIRM",
      label: "PFIRM - perizia firmata",
      sezione: 5,
    },
    {
      value: "-rev",
      label: "-rev - perizia di stima revisionata",
      sezione: 5,
    },
    {
      value: "-sal",
      label: "-sal - perizia di stima a SAL",
      sezione: 5,
    },
    {
      value: "-sal.fin",
      label: "-sal.fin - perizia di stima a SAL a lavori conclusi",
      sezione: 5,
    },
    {
      value: "-regcon",
      label: "-regcon - regolamento Condominiale",
      sezione: 6,
    },
    {
      value: "-mail",
      label: "-mail - mail della committenza o richieste al D.L.",
      sezione: 6,
    },
    {
      value: "-dati",
      label: "-dati - dati per AQR",
      sezione: 7,
    },
    {
      value: "altro",
      label: "Altro",
      sezione: 8,
    },
  ];

  constructor(
    private _formBuilder: FormBuilder,
    private _dialog: MatDialog,
    private _service: NuovaPeriziaService
  ) {
    this._unsuscribeAll = new Subject();
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      acronimo: [null, Validators.required],
      sezione: [null, Validators.required],
    });

    this._service.perizia
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res && res.attachments) {
          this.files = res.attachments as any;
        }
      });
  }

  ngOnDestroy() {
    this._unsuscribeAll.next();
    this._unsuscribeAll.complete();
  }

  upload() {
    if (!this.form.valid || !this.file) return;
    this.files.push({
      ...this.form.value,
      file: this.file,
      nome: this.file.name,
    });
    this.form.reset();
    //this.file = null;
  }

  updateAvatar(url: any) {
    this.file = url;
  }

  removeAvatar(filename: string) {}

  next() {
    this.save.emit({ attachments: this.files });
    this.stepper.next();
  }

  setSezione(event: MatSelectChange) {
    if (event.value === "altro") {
      this._dialog
        .open(NewAcronimoDialog)
        .afterClosed()
        .pipe(take(1))
        .subscribe((res: { acronimo: any; sezione: any }) => {
          if (res) {
            this.acronimi.push({ ...res.acronimo, sezione: 8 });
            //this.sezioni.push(res.sezione);

            this.form.controls.acronimo.setValue(res.acronimo.value);
            this.form.controls.sezione.setValue(8);
          }
        });
    } else {
      const ev = this.acronimi.find((a) => a.value === event.value);
      this.form.controls.sezione.setValue(ev.sezione);
      this.form.updateValueAndValidity();
    }
  }

  getDescForAcronimo(acronimo: string): string {
    return this.acronimi.find((a) => a.value.includes(acronimo)).label;
  }

  getSezione(sezione: number): string {
    return this.sezioni.find((a) => a.value === sezione).label;
  }

  deleteFile(fileName: string) {
    if (!this._service.periziaId) {
      const index = this.files.map((a) => a.file.name).indexOf(fileName);
      this.files.splice(index, 1);
    } else {
      const index = this.files.map((a) => a.nome).indexOf(fileName);
      this.files.splice(index, 1);
    }
  }

  seeFile(file: any) {
    window.location.href = file.file;
  }
}
