import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { EtichetteService } from "../../../services/etichette.service";
import { Etichetta } from "src/app/classi/Etichetta";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, combineLatest } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { User } from "src/app/classi/User";
import { AuthService } from "src/app/modules/auth/services/auth-service";
import { Perizia } from "src/app/classi/Perizia/Perizia";
import { DateAdapter } from "@angular/material";
import { SharedService } from "src/app/shared/services/shared.service";
import { StatiPeriziaHelper } from "src/helpers/stati-perizia-helper";
import { NuovoComparabileService } from "../../../services/nuovo-comparabile.service";

@Component({
  selector: "nuovo-comparabile",
  templateUrl: "./nuovo-comparabile.component.html",
  styleUrls: ["./nuovo-comparabile.component.scss"],
})
export class NuovoComparabileComponent implements OnInit, OnDestroy {
  private _unsuscribeAll: Subject<any>;

  @Input() isSubject = false;
  @Input() isEdit = false;
  @Input() etichette: Etichetta[];
  @Input() set category(value: number) {
    this.categoryValue = value;
  }
  @Input() comparabileToEdit: Perizia;

  @Output() subjectId = new EventEmitter();

  userIn: User;
  comparabile: Partial<Perizia> = {};

  visibleFilesInterni: string[] = [];
  visibleFilesEsterni: string[] = [];
  visibleDocumenti: string[] = [];
  filesInterni: any[] = [];
  filesEsterni: any[] = [];
  documenti: any[] = [];

  subCategory: string;
  loading = true;
  shouldSeeSave = true;
  categoryValue: number;
  periziaIdToGetBack: string;

  constructor(
    private _etichetteService: EtichetteService,
    private _authService: AuthService,
    private _sharedService: SharedService,
    private dateAdapter: DateAdapter<Date>,
    private activatedroute: ActivatedRoute,
    private _router: Router,
    private _comparabileService: NuovoComparabileService
  ) {
    this._unsuscribeAll = new Subject();
    this.dateAdapter.setLocale("it");
  }

  ngOnInit() {
    this._authService.userActive
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res) {
          this.userIn = res;
          this.shouldSeeSave =
            this._isOwndeByUSer() && this.comparabileToEdit
              ? this.comparabileToEdit.state === StatiPeriziaHelper.DRAFT
              : true;
        }
      });

    this.activatedroute.queryParams
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe(res => {
        if (res.periziaId) this.periziaIdToGetBack = res.periziaId;
      });

    if (!this.isEdit)
      combineLatest(this._etichetteService.onEtichetteChanged, this.activatedroute.params)
        .pipe(takeUntil(this._unsuscribeAll))
        .subscribe(([a, b]) => {
          if (a) {
            this.etichette = a.filter(
              (etichetta) => etichetta.comparabile === 1
            );
            this.loading = false;
          }

          if (b) this.categoryValue = +b.id;
        });
    else {
      this.visibleDocumenti = this.comparabileToEdit.visibleFilesNameDocumenti
        ? this.comparabileToEdit.visibleFilesNameDocumenti
        : [];
      this.visibleFilesInterni = this.comparabileToEdit.visibleFilesNameInterni
        ? this.comparabileToEdit.visibleFilesNameInterni
        : [];
      this.visibleFilesEsterni = this.comparabileToEdit.visibleFilesNameEsterni
        ? this.comparabileToEdit.visibleFilesNameEsterni
        : [];

      if (this.comparabileToEdit.documenti)
        this.comparabileToEdit.documenti.forEach((doc) => {
          this.documenti.push({ name: doc, file: null });
        });
      if (this.comparabileToEdit.filesEsterni)
        this.comparabileToEdit.filesEsterni.forEach((doc) => {
          this.filesEsterni.push({ name: doc, file: null });
        });
      if (this.comparabileToEdit.filesInterni)
        this.comparabileToEdit.filesInterni.forEach((doc) => {
          this.filesInterni.push({ name: doc, file: null });
        });

      this.loading = false;
    }
  }

  ngOnDestroy() {
    this._unsuscribeAll.next();
    this._unsuscribeAll.complete();
  }

  sendComparabile(form: { form: FormGroup; type: string }) {
    if (!form.form.valid && form.type === "send") return;
    this.comparabile = !this.isEdit
      ? {
        ...this._parseValuesFromForm(form.form),
        visibleFilesNameInterni: this.visibleFilesInterni,
        visibleFilesNameEsterni: this.visibleFilesEsterni,
        visibleFilesNameDocumenti: this.visibleDocumenti,
        filesInterni: this.filesInterni.map((file) => file.name),
        filesEsterni: this.filesEsterni.map((file) => file.name),
        documenti: this.documenti.map((file) => file.name),
        subCategory: this.subCategory,
      }
      : {
        ...this._parseValuesFromForm(form.form),
        visibleFilesNameInterni: this.visibleFilesInterni,
        visibleFilesNameEsterni: this.visibleFilesEsterni,
        visibleFilesNameDocumenti: this.visibleDocumenti,
        filesInterni: this.filesInterni.map((file) => file.name),
        filesEsterni: this.filesEsterni.map((file) => file.name),
        documenti: this.documenti.map((file) => file.name),
      };

    if (!this.comparabileToEdit)
      this._comparabileService
        .insertRequest(
          this.comparabile,
          form.type,
          this.userIn,
          this.filesInterni.map((file) => file.file),
          this.filesEsterni.map((file) => file.file),
          this.documenti.map((file) => file.file)
        )
        .then((res) => {
          if (!this.isSubject)
            this._router.navigateByUrl(!this.periziaIdToGetBack ? "/back/dash/elenco-comparabili" : "/dash/new-perizia/" + this.periziaIdToGetBack);
          else this.subjectId.emit(res);
        });
    else
      this._comparabileService
        .editComparabile(
          this.comparabileToEdit.id,
          form.type,
          this.comparabile,
          this.filesInterni ? this.filesInterni.map((file) => file.file) : [],
          this.filesEsterni ? this.filesEsterni.map((file) => file.file) : [],
          this.documenti ? this.documenti.map((file) => file.file) : []
        )
        .then(() => this._router.navigateByUrl(!this.periziaIdToGetBack ? "/back/dash/elenco-comparabili" : "/dash/new-perizia/" + this.periziaIdToGetBack));
  }

  uploadFile(files: any) {
    for (let i = 0; i < files.files.length; i++) {
      files.type === "interni"
        ? this.visibleFilesInterni.push(files.files[i].name)
        : files.type === "esterni"
          ? this.visibleFilesEsterni.push(files.files[i].name)
          : this.visibleDocumenti.push(files.files[i].name);

      files.type === "interni"
        ? this.filesInterni.push({
          name: Math.random().toString(36).substring(2),
          file: files.files[i],
        })
        : files.type === "esterni"
          ? this.filesEsterni.push({
            name: Math.random().toString(36).substring(2),
            file: files.files[i],
          })
          : this.documenti.push({
            name: Math.random().toString(36).substring(2),
            file: files.files[i],
          });
    }
  }

  deleteFile(file: string) {
    if (this.visibleFilesInterni.indexOf(file) !== -1) {
      const index = this.visibleFilesInterni.indexOf(file);
      this.visibleFilesInterni.splice(index, 1);
      this.filesInterni.splice(index, 1);
    } else if (this.visibleFilesEsterni.indexOf(file) !== -1) {
      const index = this.visibleFilesEsterni.indexOf(file);
      this.visibleFilesEsterni.splice(index, 1);
      this.filesEsterni.splice(index, 1);
    } else if (this.visibleDocumenti.indexOf(file) !== -1) {
      const index = this.visibleDocumenti.indexOf(file);
      this.visibleDocumenti.splice(index, 1);
      this.documenti.splice(index, 1);
    }
  }

  downloadFile(file: string) {
    let index: any;
    let fileToDownload: string;

    if (this.visibleFilesInterni.indexOf(file) !== -1)
      index = {
        index: this.visibleFilesInterni.indexOf(file),
        from: "interni",
      };
    if (this.visibleFilesEsterni.indexOf(file) !== -1)
      index = {
        index: this.visibleFilesEsterni.indexOf(file),
        from: "esterni",
      };
    if (this.visibleDocumenti.indexOf(file) !== -1)
      index = { index: this.visibleDocumenti.indexOf(file), from: "documenti" };

    switch (index.from) {
      case "interni":
        fileToDownload = this.comparabileToEdit.filesInterni[index.index];
        break;
      case "esterni":
        fileToDownload = this.comparabileToEdit.filesEsterni[index.index];
        break;
      case "documenti":
        fileToDownload = this.comparabileToEdit.documenti[index.index];
    }

    this._sharedService.downloadFile(fileToDownload);
  }

  setSubCategory(subCategory: string) {
    this.subCategory = subCategory;
  }

  private _parseValuesFromForm(form: FormGroup) {
    const json: any = {};
    const values = form.getRawValue();
    console.log(values);

    for (let key in values) {
      if (values[key] instanceof Date) {
        json[key] = values[key];
      } else if (values[key] instanceof Object) {
        for (let keyValue in values[key]) {
          if (values[key][keyValue] === true)
            json[key] = json[key] + "_" + keyValue;
        }
      } else json[key] = values[key] ? values[key] : "";
    }

    return json;
  }

  private _isOwndeByUSer(): boolean {
    return this.comparabileToEdit
      ? this.comparabileToEdit.user.id === this.userIn.id
      : true;
  }
}
