import { Injectable } from "@angular/core";
import { StatiPeriziaHelper } from "src/helpers/stati-perizia-helper";
import { HttpClient } from "@angular/common/http";
import { take } from "rxjs/operators";
import { AngularFireStorage } from "angularfire2/storage";
import { UserType } from "functions/src/lib/firestore/user";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(
    private _http: HttpClient,
    private _storage: AngularFireStorage) { }

  translateState(state: string): string {
    switch (state) {
      case StatiPeriziaHelper.PENDING:
        return "In attesa di verifica";
      case StatiPeriziaHelper.ACCEPTED:
        return "Accettato";
      case StatiPeriziaHelper.RETURNED:
        return "Rifiutato";
      case StatiPeriziaHelper.DRAFT:
        return "Bozza";
    }
  }

  translateRoles(role: string): string {
    switch (role) {
      case UserType.user:
        return "Perito";
      case UserType.admin:
        return "Ammnistratore";
      case UserType.revisore:
        return "Revisore";
      case UserType.pending:
        return "In attesa di verifica";
      case UserType.backoffice:
        return "Backoffice";
    }
  }

  getZonaOmi(city: string, lon: string, lat: string): Promise<any> {
    return this._http
      // .get(
      //   "http://161.97.134.212:3000/api/zoneomi?city=" + city + "&lon=" + lon + "&lat=" + lat
      // )
      .get(environment.baseUrl + "zoneOmi/" + city + "/" + lon + "/" + lat)
      .pipe(take(1))
      .toPromise();
  }

  parseNumber(strg: any): number {
    if (typeof strg === "string") {
      let decimal = ".";
      strg = strg.replace(/[^0-9$.,]/g, "");
      if (strg.indexOf(",") > strg.indexOf(".")) decimal = ",";
      if ((strg.match(new RegExp("\\" + decimal, "g")) || []).length > 1)
        decimal = "";
      if (
        decimal != "" &&
        strg.length - strg.indexOf(decimal) - 1 == 3 &&
        strg.indexOf("0" + decimal) !== 0
      )
        decimal = "";
      strg = strg.replace(new RegExp("[^0-9$" + decimal + "]", "g"), "");
      strg = strg.replace(",", ".");
      return parseFloat(strg);
    } else return strg;
  }

  async downloadFile(file: string) {
    const url = await this._storage
      .ref(file)
      .getDownloadURL()
      .pipe(take(1))
      .toPromise();
    window.open(url);
  }
}
