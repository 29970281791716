export const destinazioneUrbanistica = [
    {
        label: 'Residenziale',
        value: 1
    },
    {
        label: 'Terziaria',
        value: 2
    },
    {
        label: 'Commerciale',
        value: 3
    },
    {
        label: 'Industriale',
        value: 4
    },
    {
        label: 'Agricola',
        value: 5
    }
];

export const tipEdi = [
    {
        label: 'Condominio',
        value: 0
    },
    {
        label: 'Fabbricato storico',
        value: 1
    },
    {
        label: 'Fabbricato singolo',
        value: 2
    },
    {
        label: 'Fabbricato in linea',
        value: 3
    },
    {
        label: 'Fabbricato rurale',
        value: 4
    },
    {
        label: 'Fabbricato a schiera',
        value: 5
    },
    {
        label: 'Fabbricato bifamiliare',
        value: 6
    },
    {
        label: 'Fabbricato tipico',
        value: 7
    },
    {
        label: 'Fabbricato a blocco',
        value: 8
    },
];

export const statoConservativo = [
    {
        label: 'Scarso',
        value: 1
    },
    {
        label: 'Normale',
        value: 2
    },
    {
        label: 'Ottimo',
        value: 3
    }
];

export const formaMercato = [
    {
        label: 'Concorrenza mon.',
        value: 1
    },
    {
        label: 'Concorrenza mon. Ristretta'
    },
    {
        label: 'Oligopolio',
        value: 3
    },
    {
        label: 'Monopolio',
        value: 4
    },
    {
        label: 'Monopolio bilaterale',
        value: 5
    }
];

export const fasiMercato = [
    {
        label: 'Crescita',
        value: 0
    },
    {
        label: 'Rallentamento',
        value: 1
    },
    {
        label: 'Stasi',
        value: 2
    },
    {
        label: 'Decremento',
        value: 3
    },
    {
        label: 'Recupero',
        value: 4
    },
    {
        label: 'Ripresa',
        value: 5
    }
];

export const filtering = [
    {
        label: "Assente",
        value: 0
    },
    {
        label: "Up",
        value: 1
    },
    {
        label: "Down",
        value: -1
    }
];

export const macroCategorie = [
    { value: 'appartamento', label: 'Appartamento' },
    { value: 'bar', label: 'Bar', macro: 2 },
    { value: 'cantine', label: 'Cantine/Soffitte' },
    { value: 'caseVille', label: 'Case e ville' },
    { value: 'palestra', label: 'Palestra - Centro benessere' },
    { value: 'cinema', label: 'Cinema - Teatro' },
    { value: 'discoteca', label: 'Discoteca' },
    { value: 'distributore', label: 'Stazione di servizio' },
    { value: 'posti_auto', label: 'Posti auto' },
    { value: 'ristorante', label: 'Ristorante' },
    { value: 'vsupermercato', label: 'Supermecato' },
    { value: 'uffici', label: 'Uffici' },
    { value: 'palazzinaUffici', label: 'Palazzina uffici' },
    { value: 'turisticoRicettivo', label: 'Turistico ricettivo' },
    { value: 'sportivoAperto', label: 'Sportivo all\'aperto' },
    { value: 'sportivoChiuso', label: 'Sportivo al chiuso' },
    { value: 'industriale', label: 'Industriale' },
    { value: 'artigianale', label: 'Artigianale' },
    { value: 'magazzini', label: 'Magazzini all\'ingrosso' },
    { value: 'autorimessa ', label: 'Car Parking' },
]