export interface User {
  email: string;
  userType: UserType;
  name: string;
  surname: string;
  phone: string;
  disabled: boolean;
}

export enum UserType {
  admin = "admin",
  user = "user",
  revisore = "revisore",
  pending = "pending",
  backoffice = "backoffice",
}
