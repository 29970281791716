import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AuthService } from '../../modules/auth/services/auth-service';

@Injectable({ providedIn: 'root' })
export class AuthGuards implements CanActivate {

    constructor(private logIn: AuthService, private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | Observable<boolean> {
        return this.logIn.user.pipe(map(user => {
            return !!user;
        }), tap(isAuth=>{
            if (!isAuth){
                this.router.navigate(['/']);
            }
        }));
    }
} 