import {
  Component,
  OnDestroy,
  OnInit,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { SisterClass } from "src/app/classi/Sister/Sister";
import { DetailSister } from "../../dialogs/dialog-detail/detail.component";
import { MatDialog } from "@angular/material/dialog";
import { DetailSisterPagamento } from "../../dialogs/dialog-detail-payment/payment.component";
import { DetailSisterLogin } from "../../dialogs/dialog-login/login-dialog.component";
import { Omonini } from "src/app/classi/Sister/Omonini";
import { ElencoProvincie } from "src/app/classi/Sister/ElencoProvincie";
import { Convenzione } from "src/app/classi/Sister/Convenzione";
import { Router } from "@angular/router";
import { DetailConvenzioni } from "../../dialogs/dialog-convenzioni/convenzioni.component";
import { SisterUtilsService } from "../../services/sister-utils.service";
import { DialogIntestatiSister } from "../../dialogs/dialog-intestati/intestati.component";
import { SisterService } from "../../services/sister.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
declare var $: any;

@Component({
  selector: "sister",
  templateUrl: "./sister.component.html",
  styleUrls: ["./sister.component.scss"],
})
export class Sister implements OnInit, OnDestroy {
  private _unsuscribeAll: Subject<any>;
  @Input() cf: string;
  @Input() set isFromAnotherPage(value: boolean) {
    this.anotherPage = value;
    if (value)
      if (
        localStorage.getItem("cf") == null &&
        localStorage.getItem("psw") == null
      )
        this.showDetailLogin();
      else {
        this._logOutFromSis();
        this.login(localStorage.getItem("cf"), localStorage.getItem("psw"));
      }
  }
  @Output() sendItems = new EventEmitter();

  types: Tipologie[] = [
    { value: true, viewValue: "Persona fisica" },
    { value: false, viewValue: "Persona giuridica" },
  ];

  sister: SisterClass[] = [];
  omonimi: Omonini[] = [];
  elencoProvincie: ElencoProvincie[] = [];

  hasSearched = false;
  hasSearchedOmonini = false;
  hasSearchedProvincie = false;

  isLogged = false;

  loading = false;
  loadingLogin = false;

  error = false;

  firstSearch = true;

  errorMessage = "Errore, ";

  idConvenzione = 0;
  isFetchingCodFisc = true;
  allCheck = false;

  codFiscBuyer: string;
  passBuyer: string;

  form: FormGroup;

  showConfronto = false;
  anotherPage = false;

  constructor(
    private _toastr: ToastrService,
    public dialog: MatDialog,
    public router: Router,
    private formBuilder: FormBuilder,
    private _service: SisterUtilsService,
    private _sisterService: SisterService
  ) {
    this._unsuscribeAll = new Subject();
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      type: [this.isFetchingCodFisc, Validators.required],
      codFisc: ["", Validators.required],
    });

    this._service.onImmboliDaEsortareChanhe
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        this.showConfronto = !(res.length == 0);
      });

    if (this.router.url.includes("sister")) {
      if (
        localStorage.getItem("cf") == null &&
        localStorage.getItem("psw") == null
      )
        this.showDetailLogin();
      else {
        this._logOutFromSis();
        this.login(localStorage.getItem("cf"), localStorage.getItem("psw"));
      }
    }
  }

  ngOnDestroy() {
    this._logOutFromSis();
    this._logOut();

    this._unsuscribeAll.next();
    this._unsuscribeAll.complete();
  }

  onSubmit(form: FormGroup) {
    this.sister = [];
    this.omonimi = [];
    this.elencoProvincie = [];
    this.hasSearched = false;
    this.hasSearchedOmonini = false;
    this.hasSearchedProvincie = false;
    this.error = false;

    const codFisc = form.value.codFisc;

    if (!form.valid) return;

    if (!this.firstSearch) {
      this._logOutFromSis();
      this.login(localStorage.getItem("cf"), localStorage.getItem("psw")).then(
        () => {
          this.loading = true;
          this.fetchOmonini(codFisc.trim());
          if (this.firstSearch) this.firstSearch = false;
        }
      );
    } else {
      this.fetchOmonini(codFisc.trim());
      if (this.firstSearch) this.firstSearch = false;
    }
  }

  login(codFisc: string, pass: string) {
    return new Promise<void>((resolve1, reject1) => {
      this.loadingLogin = true;
      this._sisterService
        .login(codFisc, pass)
        .then(
          (resolve) => {
            if (resolve === "logged") this.isLogged = true;
            else if (resolve instanceof Array)
              this.showDetailConvenzione(resolve);

            resolve1();
          },
          (reject) => {
            if (reject === "sessione") {
              this.error = true;
              this.errorMessage = this.errorMessage + "utente già in sessione";
              this.isLogged = false;

              this._logOutFromSis();
              this.showDetailLogin();
            } else this.error = true;

            reject1();
          }
        )
        .finally(() => (this.loadingLogin = false));
    });
  }

  fetchOmonini(codFisc: string) {
    this.loading = true;
    this._sisterService
      .fetchOmonini(codFisc, this.idConvenzione, this.isFetchingCodFisc)
      .then(
        (resolve: any[]) => {
          this.hasSearchedOmonini = true;
          resolve.forEach((omonimo, index) => {
            this.omonimi.push(
              new Omonini(
                omonimo.nome,
                omonimo.cognome,
                omonimo.luogo,
                omonimo.data,
                omonimo.sesso,
                omonimo.codFisc,
                omonimo.id
              )
            );

            if (index === resolve.length - 1) {
              $(document).ready(function () {
                $("#dataTableOmonini").DataTable({
                  scrollY: true,
                  scrollX: true,
                  scrollCollapse: true,
                });
              });
            }
          });
        },
        (reject) => {
          this.error = true;
          this._logOutFromSis();
          if (reject === "no-data")
            this.errorMessage = this.errorMessage + "nessun dato disponibile";
          else
            this.errorMessage =
              this.errorMessage + "errore nella ricerca degli omonimi";
        }
      )
      .finally(() => (this.loading = false));
  }

  fetchProv() {
    this.loading = true;
    this._sisterService
      .fetchProvincie(this.isFetchingCodFisc)
      .then(
        (resolve: any[]) => {
          this.hasSearchedOmonini = false;
          this.hasSearchedProvincie = true;

          resolve.forEach((provincia, index) => {
            this.elencoProvincie.push(
              new ElencoProvincie(
                provincia.prov,
                provincia.fabbricati,
                provincia.terreni,
                provincia.id
              )
            );
            if (index === resolve.length - 1) {
              $(document).ready(function () {
                $("#dataTableProv").DataTable({
                  scrollY: true,
                  scrollX: true,
                  scrollCollapse: true,
                });
              });
            }
          });
        },
        () => {
          this.error = true;
          this.errorMessage =
            this.errorMessage + "errore nella ricerca delle provincie";
          this._logOutFromSis();
        }
      )
      .finally(() => (this.loading = false));
  }

  fetchBuildings(idProv: string) {
    this.loading = true;
    this._sisterService
      .fetchBuildings(idProv, this.isFetchingCodFisc)
      .then(
        (resolve: SisterClass[]) => {
          this.hasSearchedProvincie = false;
          this.hasSearched = true;
          this.sister = resolve;

          this.sister.forEach((immobile, index) => {
            this._service.parseImmobile(immobile);

            if (index === this.sister.length - 1) {
              $(document).ready(function () {
                $("#dataTableSister").DataTable({
                  scrollY: true,
                  scrollX: true,
                  scrollCollapse: true,
                  dom: "Bfrtip",
                  buttons: ["copy", "excel", "pdf", "print"],
                });
              });
            }
          });
        },
        () => {
          this.error = true;
          this.errorMessage =
            this.errorMessage + "errore nella ricerca degli immobili";
          this._logOutFromSis();
        }
      )
      .finally(() => (this.loading = false));
  }

  pay(id: number) {
    this.loading = true;
    this._sisterService
      .pay(id, this.isFetchingCodFisc)
      .then(
        (res) => {
          const blob = new Blob([res], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        },
        () => {
          this.error = true;
          this.errorMessage =
            this.errorMessage + "errore nel recupero della visura";
          this._logOutFromSis();
        }
      )
      .finally(() => (this.loading = false));
  }

  private _logOut() {
    this._sisterService.logOut().then(() => {
      this.isLogged = false;
      this.hasSearchedOmonini = false;
      this.hasSearchedProvincie = false;
      this.hasSearched = false;
      this.omonimi = [];
      this.elencoProvincie = [];
      this.sister = [];
    });

    if (localStorage.getItem("tokenSis")) {
      this._logOutFromSis();
    }

    if (this.router.url.indexOf("sister") > -1) {
      this.showDetailLogin();
    }
  }

  private _logOutFromSis() {
    this._sisterService.logOutFromSister().then(() => {
      localStorage.removeItem("tokenSis");

      this.isLogged = false;
      this.hasSearchedOmonini = false;
      this.omonimi = [];
      this.elencoProvincie = [];
      this.sister = [];
      this._toastr.success("Ti sei correttamente disconnesso da Sister");
    });
  }

  showDetailLogin() {
    const dialogRef = this.dialog.open(DetailSisterLogin, {
      data: {
        pass: this.passBuyer,
        codFisc: this.codFiscBuyer,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res && res.codFisc && res.pass) {
          this.codFiscBuyer = res.codFisc;
          this.passBuyer = res.pass;
          this.error = false;

          this.login(res.codFisc, res.pass);
        } else if (res == "close") {
          if (!this.anotherPage) this.router.navigateByUrl("/back/dash");
        } else {
          if (localStorage.getItem("tokenSis")) {
            this._logOutFromSis();
          }
          if (this.router.url.indexOf("sister") > -1) {
            this.showDetailLogin();
          }
        }
      });
  }

  showDetailConvenzione(data: Convenzione[]) {
    const dialogRef = this.dialog.open(DetailConvenzioni, {
      data: { data },
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res != undefined) {
          this.idConvenzione = res;
          this.isLogged = true;
        } else {
          this._logOutFromSis();
        }
      });
  }

  showDetail(i: SisterClass) {
    this.dialog.open(DetailSister, {
      data: {
        fabb: i.altriDati,
        terre: i.altriDatiTerreni,
      },
    });
  }

  showIntestati(i: SisterClass) {
    this.dialog.open(DialogIntestatiSister, {
      data: i,
    });
  }

  showDetailPagamento(i: number) {
    const dialogRef = this.dialog.open(DetailSisterPagamento);
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((result) => {
        if (result == 1) {
          this.pay(i);
        } else {
          this._logOutFromSis();
        }
      });
  }

  changeSelect(immobile: SisterClass) {
    if (!this._service.immobiliDaEsportare.includes(immobile)) {
      this._service.immobiliDaEsportare.push(immobile);
    } else {
      this._service.immobiliDaEsportare.splice(
        this._service.immobiliDaEsportare.indexOf(immobile),
        1
      );
    }

    this.showConfronto = !(this._service.immobiliDaEsportare.length == 0);
  }

  scarica() {
    if (this.anotherPage) {
      this.sendItems.emit(this._service.immobiliDaEsportare);
      this._logOut();
    } else this._service.scaricaImmobiliSister(this.codFiscBuyer);
  }

  selectDeselectAll() {
    this.allCheck = !this.allCheck;
    if (this.allCheck) {
      this._service.immobiliDaEsportare = this.sister;
    } else {
      this._service.immobiliDaEsportare = [];
    }

    this.showConfronto = !(this._service.immobiliDaEsportare.length == 0);
  }
}

export interface Tipologie {
  value: boolean;
  viewValue: string;
}
