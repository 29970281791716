import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { routes } from './routes';
import { Back } from './components/back/back.component';
import { MaterialModule } from '../../shared/material/material.module';
import { CommonModule } from '@angular/common';
import { ElencoPerizieComponent } from './components/elenco-perizie/elenco-perizie.component';
import { ElencoPerizieService } from './services/elenco-perizie.service';
import { MAT_DATE_FORMATS } from '@angular/material';
import { EtichetteService } from './services/etichette.service';
import { NuovaPeriziaComponent } from './components/nuovo/perizia/nuova-perizia.component';
import { SpinnerModule } from '../../shared/spinner-material/spinner.module';
import { DynamicFormBuilderModule } from '../../shared/dynamic-form-builder/dynamic-form-builder.module';
import { NuovaPeriziaService } from './services/nuova-perizia.service';
import { NuovoComparabileService } from './services/nuovo-comparabile.service';
import { NuovoComparabileComponent } from './components/nuovo/comparabile/nuovo-comparabile.component';
import { UtentiService } from './services/utenti.service';
import { UtentiComponent } from './components/utenti/utenti.component';
import { AuthService } from '../auth/services/auth-service';
import { NewEditUser } from './components/utenti/dialogs/new-edit/dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedDialogModule } from '../../shared/confirm-dialog/dialog.module';
import { ConfirmDialog } from '../../shared/confirm-dialog/dialog.component';
import { ElencoComparabiliComponent } from './components/elenco-comparabili/elenco-comparabili.component';
import { ElencoComparabiliService } from './services/elenco-comparabili.service';
import { SharedService } from '../../shared/services/shared.service';
import { StaminaUiModule } from '../stamina-ui/stamina-ui.module';
import { OpenStreetMapModule } from 'src/app/shared/open-street-map/open-map.module';
import { CategoryDialog } from './components/back/dialogs/category-dialog/dialog.component';
import { SisterModule } from './components/ricerca-sister/sister.module';
import { ComparabiliModule } from './components/ricerca-comparabili/comparabili.module';
import { DetailComparabileService } from './services/detail-comparabile.service';
import { DetailComparabileComponent } from './components/elenco-comparabili/detail/detail.component';
import { SceltaModelloDialog } from './components/back/dialogs/scelta-modello/dialog.component';
import { BackService } from './services/back.service';
import { ViewComparabileComponent } from './components/view-comparabile/view.component';
import { ViewComparabileService } from './services/view-comparabile.service';
import { InitPeriziaDialog } from './components/nuovo/perizia/dialogs/init-perizia/dialog.component';
import { IntestazioneComponent } from './components/nuovo/perizia/steps/intestazione/intestazione.component';
import { AllegatiComponent } from './components/nuovo/perizia/steps/allegati/allegati.component';
import { DueDiligenceComponent } from './components/nuovo/perizia/steps/due-diligence/due-diligence.component';
import { ValoriComponent } from './components/nuovo/perizia/steps/altri-valori/valori.component';
import { RevisioneComponent } from './components/nuovo/perizia/steps/revisione/revisione.component';
import { ChooseFileDialog } from './components/nuovo/perizia/dialogs/choose-file/dialog.component';
import { NewAcronimoDialog } from './components/nuovo/perizia/dialogs/new-acronomico/dialog.component';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MM YYYY',
        dateA11yLabel: 'DD/MM/YYYY',
        monthYearA11yLabel: 'MM YYYY',
    },
};

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        MaterialModule,
        SpinnerModule,
        DynamicFormBuilderModule,
        FormsModule,
        ReactiveFormsModule,
        SharedDialogModule,
        StaminaUiModule,
        OpenStreetMapModule,
        SisterModule,
        ComparabiliModule
    ],
    declarations: [
        Back,
        ElencoPerizieComponent,
        ElencoComparabiliComponent,
        NuovaPeriziaComponent,
        NuovoComparabileComponent,
        UtentiComponent,
        NewEditUser,
        CategoryDialog,
        DetailComparabileComponent,
        SceltaModelloDialog,
        ViewComparabileComponent,
        InitPeriziaDialog,
        IntestazioneComponent,
        AllegatiComponent,
        DueDiligenceComponent,
        ValoriComponent,
        RevisioneComponent,
        ChooseFileDialog,
        NewAcronimoDialog
    ],
    entryComponents: [
        NewEditUser,
        ConfirmDialog,
        CategoryDialog,
        SceltaModelloDialog,
        InitPeriziaDialog,
        ChooseFileDialog,
        NewAcronimoDialog
    ],
    providers: [
        ElencoPerizieService,
        ElencoComparabiliService,
        EtichetteService,
        NuovaPeriziaService,
        NuovoComparabileService,
        UtentiService,
        AuthService,
        SharedService,
        DetailComparabileService,
        BackService,
        ViewComparabileService,
        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
})
export class BackModule { }