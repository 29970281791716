import { Injectable, OnDestroy } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { BehaviorSubject, Subject, Subscription } from "rxjs";
import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from "@angular/fire/storage";
import { finalize, map, takeUntil, switchMap } from "rxjs/operators";
import { FirebaseCollectionsHelper } from "src/helpers/firebase-collections-helper";
import { AuthService } from "../../auth/services/auth-service";
import { RealPerizia } from "src/app/classi/Perizia/RealPerizia";
import { Timestamp } from "src/app/shared/interfaces/Timestamp";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Perizia } from "src/app/classi/Perizia/Perizia";

@Injectable()
export class NuovaPeriziaService implements OnDestroy {
  periziaId: string;
  perizia = new BehaviorSubject<Partial<RealPerizia>>(null);
  private _unsuscribeAll = new Subject();
  constructor(
    private _authService: AuthService,
    private _db: AngularFirestore,
    private _storage: AngularFireStorage
  ) {}

  resolve(route: ActivatedRouteSnapshot) {
    this.periziaId = route.params.id;
    if (this.periziaId) this._getPerizia();
  }

  ngOnDestroy(): void {
    this._unsuscribeAll.next();
    this._unsuscribeAll.complete();
  }

  uploadAndReturnUrl(file: File, folder?: string): Promise<string> {
    let storageRef: AngularFireStorageReference;
    let task: AngularFireUploadTask;

    if (!folder) {
      storageRef = this._storage.ref(file.name);
      task = storageRef.put(file);
    } else {
      storageRef = this._storage.ref(`${folder}/${file.name}`);
      task = storageRef.put(file);
    }

    return new Promise<any>((resolve, reject) => {
      // const task = this.storage.upload(filePath, file);

      task
        .snapshotChanges()
        .pipe(
          finalize(() =>
            storageRef.getDownloadURL().subscribe(
              (res) => resolve(res),
              (err) => reject(err)
            )
          )
        )
        .subscribe();
    });
  }

  async editPerizia(id: string, perizia: Partial<RealPerizia>) {
    const a = perizia.attachments ? perizia.attachments : [];
    delete perizia.attachments;
    await this._db.doc(FirebaseCollectionsHelper.PERIZIE + "/" + id).update({
      ...perizia,
    });

    const attachments: {
      acronimo: string;
      sezione: string;
      file: string;
      nome: string;
    }[] = [];

    for (let file of a) {
      if (typeof file.file !== "string") {
        const url = await this.uploadAndReturnUrl(file.file as File, id);
        attachments.push({
          acronimo: file.acronimo,
          sezione: file.sezione,
          file: url,
          nome: file.nome,
        });
      }
    }

    if (attachments.length > 0) {
      perizia.attachments = attachments;
      this._db
        .doc<RealPerizia>(FirebaseCollectionsHelper.PERIZIE + "/" + id)
        .update({
          attachments: attachments,
        });
    }

    if (a.length === 0) {
      this._db
        .doc<RealPerizia>(FirebaseCollectionsHelper.PERIZIE + "/" + id)
        .update({
          attachments: [],
        });
    }
  }

  async savePerizia(perizia: Partial<RealPerizia>) {
    const a = perizia.attachments ? perizia.attachments : [];

    delete perizia.attachments;
    const id = (
      await this._db.collection(FirebaseCollectionsHelper.PERIZIE).add({
        ...perizia,
        userId: this._authService.currentUser.id,
        state: "draft",
      })
    ).id;

    const attachments: {
      acronimo: string;
      sezione: string;
      file: string;
      nome: string;
    }[] = [];

    for (let file of a) {
      const url = await this.uploadAndReturnUrl(file.file as File, id);
      attachments.push({
        acronimo: file.acronimo,
        sezione: file.sezione,
        file: url,
        nome: file.nome,
      });
    }

    perizia.attachments = attachments;
    this._db
      .doc<RealPerizia>(FirebaseCollectionsHelper.PERIZIE + "/" + id)
      .update({
        attachments: attachments,
        dataInserimento: new Date() as unknown as Timestamp,
        userName: this._authService.currentUser.name,
        userSurname: this._authService.currentUser.surname,
      });
  }

  private _getPerizia(): Subscription {
    return this._db
      .doc<RealPerizia>(
        FirebaseCollectionsHelper.PERIZIE + "/" + this.periziaId
      )
      .snapshotChanges()
      .pipe(
        takeUntil(this._unsuscribeAll),
        map((res) => {
          return {
            $id: res.payload.id,
            ...(res.payload.data() as RealPerizia),
          };
        }),
        switchMap(async (per) => {
          const subId = per.subjectId;
          if (subId) {
            const sub = (
              await this._db
                .doc(FirebaseCollectionsHelper.COMPARABILI + "/" + subId)
                .get()
                .toPromise()
            ).data() as Perizia;

            per.$subject = sub;
          }

          return per;
        })
      )
      .subscribe((res) => {
        this.perizia.next(res);
      });
  }
}
