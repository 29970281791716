import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

@Component({
    selector: 'profile-photo-upload',
    templateUrl: './profile-photo-upload.component.html',
    styleUrls: ['./profile-photo-upload.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePhotoUploadComponent implements OnInit {
    /**
     * link to image (http(s))
     */
    @Input() image: string;

    isHoveredByDrop = false;
    public imgUrl: any;
    filename: string;

    /**
     * @deprecated use onImageChanged
     */
    @Output() onImageAdded = new EventEmitter<File>();

    /**
     * @deprecated use onImageChanged
     */
    @Output() onImageRemoved = new EventEmitter<string>();

    /**
     * emit the file selected by user or NULL if the file was removed
     */
    @Output() onImageChanged = new EventEmitter<File | null>();
    @ViewChild('fileInput', { static: false }) fileInput: ElementRef;
    @ViewChild('preview', { static: false }) preview: ElementRef;

    constructor(
        public chref: ChangeDetectorRef
    ) {
    }

    get photoUrl(): string {
        const url = this.imgUrl || this.image;
        return url ? `url(${url})` : null;
    }

    ngOnInit(): void {
        // this.filename = this.user && this.user.avatarUrl ? this.user.avatarUrl : null;
        // console.log(this.user, this.filename);
    }

    toggleDragHover(value: boolean): void {
        this.isHoveredByDrop = value;
        this.chref.markForCheck();
    }

    onDrop(files: File[]) {
        this._readURL(files[0]);
        this.filename = files[0].name;
        this._upload(files[0]);
    }

    onSelect(event) {
        this._readURL(event.target.files[0]);
        this.filename = event.target.files[0].name;
        this._upload(event.target.files[0]);
    }

    removeAvatar(): void {
        // console.log(this.filename);
        this.onImageRemoved.emit(this.filename);
        this.imgUrl = null;
        this.image = null; // changing input parameter here might make wrong effect in some situations
        // this.user.avatarUrl = null;
        this.onImageChanged.emit(null);
    }

    private _readURL(file: File) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (_event) => {
            this.imgUrl = reader.result;
            this.chref.markForCheck();
        }
    }

    private _upload(file: File) {
        this.onImageAdded.emit(file);
        this.onImageChanged.emit(file);
    }
}
