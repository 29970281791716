import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth-service';
import { FuseSplashScreenService } from '../../../../shared/services/splash.service';
import { Router } from '@angular/router';

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignUp implements OnInit {

  formSignUp: FormGroup;

  constructor(
    private _authService: AuthService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private formBuilder: FormBuilder,
    private router: Router) { }

  ngOnInit() {
    this.formSignUp = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      psw: ['', Validators.required],
      name: ['', Validators.required],
      surname: ['', Validators.required]
    });
  }

  onSubmit(form: FormGroup) {
    if (!form.valid) {
      return;
    }

    this._fuseSplashScreenService.show();
    this._authService.signUp(form).then(() => this.router.navigateByUrl('/login')).finally(() => this._fuseSplashScreenService.hide());
  }
}
