import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { take } from 'rxjs/operators';

@Injectable()
export class GeoLocationService {

    private _geoReverseService = 'https://nominatim.openstreetmap.org/reverse?&format=json&addressdetails=1&lat={lat}&lon={lon}';
    private _geoService = 'https://nominatim.openstreetmap.org/search/{address}?format=json';
    private _geoGoogle = 'https://maps.googleapis.com/maps/api/geocode/json?address=Comune+di+__ADRESS__&key=AIzaSyCzNHmjJgmCII7gI1PTqeGL6QJydbor4mY';

    constructor(
        private _http: HttpClient) { }

    reverseGeo(lat: number, lng: number) {
        const service = this._geoReverseService.replace(new RegExp('{lon}', 'ig'), `${lng}`).replace(new RegExp('{lat}', 'ig'), `${lat}`);
        return this._http.get(service);
    }

    async geo(address: string): Promise<any> {
        const service = this._geoService.replace(new RegExp('{address}', 'ig'), `${address.trim().split(' ').join('%20')}`);
        return await this._http.get(service).pipe(take(1)).toPromise();
    }

    async geoGoogle(address: string): Promise<any> {
        return await this._http.get(this._geoGoogle.replace("__ADRESS__", address)).pipe(take(1)).toPromise();
    }
}
