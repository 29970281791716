import { Injectable } from '@angular/core';
import { Immobile } from '../../../../../classi/Comparabili-immobiliare-casa/Immobile';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { CurrencyFormat } from 'src/app/shared/pipes/currency';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import * as _ from 'lodash';
import { map, take, catchError } from 'rxjs/operators';
import * as firebase from 'firebase';
import { StatiPeriziaHelper } from 'src/helpers/stati-perizia-helper';
import { SharedService } from 'src/app/shared/services/shared.service';
import * as moment from 'moment';

@Injectable()

export class ComparabiliService {

    lat: number;
    lng: number;

    immobili: Immobile[] = [];
    onChangeImmobili = new BehaviorSubject<Immobile[]>(null);
    onChangeLatitude = new BehaviorSubject<number>(null);
    onChangeLongitude = new BehaviorSubject<number>(null);

    constructor(
        private _currency: CurrencyFormat,
        private _db: AngularFirestore,
        private _http: HttpClient,
        private _sharedService: SharedService) { }

    public modifyLat(lat: number) {
        this.lat = lat;
    }

    public modifyLng(lng: number) {
        this.lng = lng;
    }

    getComparabili(raggio: string, contratto: string, tipo: string, filters: any): Promise<Immobile[]> {
        const usableFilters = this._cleanFilters(filters);
        return new Promise<Immobile[]>((resolve, reject) => {
            //combineLatest(this._getImmobiliare(raggio, contratto, tipo), this._getCasa(raggio, contratto, tipo), this._fetchDb(raggio, usableFilters))
                combineLatest(this._fetchDb(raggio, usableFilters))
                .subscribe(([a]) => {
                    if (a) {
                        //const immobili = _.concat(this._parseResponse(c, 'Tecnici Conferitori'), this._parseResponse(a as Immobile[], 'immobiliare.it'), this._parseResponse(b as Immobile[], 'casa.it'));
                        const immobili = _.concat(this._parseResponse(a, 'Tecnici Conferitori'));
                        this.immobili = immobili;
                        this.onChangeImmobili.next(immobili);
                    }

                    resolve(this.immobili);
                }, reject);
        });
    }

    private _getImmobiliare(raggio: string, contratto: string, tipo: string): Observable<any> {
        return this._http.get("http://173.249.48.19:8080/Stamina/resources/immobiliareit/" + contratto + '/' + tipo + '/' + raggio + "/" + this.lat + "/" + this.lng + "/raggio")
            .pipe(take(1), catchError(() => of([])));
    }

    private _getCasa(raggio: string, contratto: string, tipo: string): Observable<any> {
        return this._http.get("http://173.249.48.19:8080/Stamina/resources/casait/" + raggio + "/" + this.lat + "/" + this.lng + "/"
            + (contratto == "1" ? 'vendita' : 'affitto') + "/" + (tipo == '1' ? 'case' : tipo == "10_" ? "terreni" : tipo == "1_6" ? "garage-posti-auto" : tipo == "2_16" ? "commerciale" : ""))
            .pipe(take(1), catchError(() => of([])));;
    }

    private _fetchDb(raggio: string, filters: any): Observable<any> {
        const newCoordinates = this._getNewCoordinates(raggio);
        const maxPoint = new firebase.firestore.GeoPoint(newCoordinates.greatLat, newCoordinates.greatLong);
        const minPoint = new firebase.firestore.GeoPoint(newCoordinates.lessLat, newCoordinates.lessLong);

        return this._db.collection('comparabili', ref => ref
            .where('coordinates', '<=', maxPoint)
            .where('coordinates', '>=', minPoint)
            .where('state', '==', StatiPeriziaHelper.ACCEPTED))
            .get()
            .pipe(
                map(immobili => immobili.docs.map(immobile => {
                    const comparabile = immobile.data() as Immobile;
                    comparabile.fonte = 'Tecnici Conferitori';
                    const coordinates = immobile.data().coordinates as firebase.firestore.GeoPoint;
                    comparabile.lat = String(coordinates.latitude);
                    comparabile.lng = String(coordinates.longitude);

                    return {
                        id: immobile.ref,
                        ...comparabile,
                        prezzo: this._currency.transform(+(comparabile.prezzo.split(".").join("").split(",").join(".")))
                    }
                })),
                map(imm => imm.filter(im => {
                    if (Object.keys(filters).length === 0) return true;
                    else {

                        const allProperties = Object.keys(filters).length;
                        const rightProperties = [];

                        for (const key in filters)
                            if (im[key] && key !== 'datacompravendita') {
                                if (im[key].toLowerCase() === filters[key].toLowerCase())
                                    rightProperties.push(true);
                            } else if (im[key] && key === 'datacompravendita') {
                                const dataCompravendita = im[key].toDate();
                                const periodoRicerca = filters[key];
                                if (periodoRicerca === 0) {
                                    const compareDate = moment().subtract(6, 'months');
                                    if (moment(dataCompravendita).isAfter(compareDate.toDate())) rightProperties.push(true);

                                } else if (periodoRicerca === 1) {
                                    const compareDate = moment().subtract(1, 'years');
                                    if (moment(dataCompravendita).isAfter(compareDate.toDate())) rightProperties.push(true);

                                } else {
                                    const compareDate = moment().subtract(2, 'years');
                                    if (moment(dataCompravendita).isAfter(compareDate.toDate())) rightProperties.push(true);
                                }
                            } else if (im['vani_principali'] && (key === 'supMin' || key === 'supMax')) {
                                const sup = +im['vani_principali'];
                                const supMin = filters['supMin'];
                                const supMax = filters['supMax'];
                                if (supMin && supMax) {
                                    if (sup <= supMax && sup >= supMin) rightProperties.push(true);
                                } else if (!supMin && supMax) {
                                    if (sup <= supMax) rightProperties.push(true);
                                } else if (supMin && !supMax) {
                                    if (sup >= supMin) rightProperties.push(true);
                                }
                            }

                        return allProperties === rightProperties.length;
                    }
                })));
    }

    private _getNewCoordinates(raggio: string): any {
        return {
            greatLat: this.lat + (180 / Math.PI) * (+raggio / 6378137),
            greatLong: this.lng + (180 / Math.PI) * (+raggio / 6378137) / Math.cos(this.lng),
            lessLat: this.lat + (180 / Math.PI) * (+(-raggio) / 6378137),
            lessLong: this.lng + (180 / Math.PI) * (+(-raggio) / 6378137) / Math.cos(this.lng)
        }
    }

    private _parseResponse(arra: Immobile[], fonte: string): Immobile[] {

        arra.forEach(imm => {
            if (imm.coordinate != null && imm.coordinate != "") {
                this.onChangeLatitude.next(+imm.coordinate.split(",")[0])
                this.onChangeLongitude.next(+imm.coordinate.split(",")[1])
                return;
            }
        });

        arra.forEach(imm => {
            const immobile = imm as Immobile;
            immobile.fonte = fonte;

            if (immobile.prezzo && immobile.fonte === "immobiliare.it") immobile.prezzo = this._currency.transform(+imm.prezzo.replace('€', ''), '€', 2, '.', ',');

            immobile.vaniAccDiretti = 0;
            immobile.vaniAccNonComunicanti = 0;
            immobile.vaniPrincipali = 0;
            immobile.vaniAltro = 0;
            immobile.terrazze = 0;

            if (imm.statoManutenzione) {
                switch (imm.statoManutenzione.toLowerCase().split(' ').join('')) {
                    case 'daristrutturare':
                        immobile.statoManutenzione = "Minimo";
                        break;
                    case 'abitabile':
                        immobile.statoManutenzione = "Medio";
                        break;
                    case 'buono/abitabile':
                        immobile.statoManutenzione = "Medio";
                        break;
                    case 'incostruzione':
                        immobile.statoManutenzione = "Massimo";
                        break;
                    case 'nuovo':
                        immobile.statoManutenzione = "Massimo";
                        break;
                    case 'ristrutturato':
                        immobile.statoManutenzione = "Massimo";
                        break;
                    case 'ottimo/ristrutturato':
                        immobile.statoManutenzione = "Massimo";
                        break;
                    case 'nuovo/incostruzione':
                        immobile.statoManutenzione = "Massimo";
                        break;
                }
            }

            if (immobile.coordinate != null && immobile.coordinate != "") {
                immobile.alpha = 1;
                immobile.lat = imm.coordinate.split(",")[0];
                immobile.lng = imm.coordinate.split(",")[1];
            }

            if (immobile.supExtra) {
                immobile.supExtra.forEach(extra => {
                    switch (extra.consistenza.toLowerCase()) {
                        case 'abitazione':
                            imm.supPrincipale = +imm.supPrincipale + +extra.superficie.replace(",", ".")
                            break;
                        case "angolo cottura":
                            imm.vaniAccDiretti = +imm.vaniAccDiretti + +extra.superficie.replace(",", ".")
                            break;
                        case "corridoi":
                            imm.vaniAccDiretti = +imm.vaniAccDiretti + +extra.superficie.replace(",", ".")
                            break;
                        case "bagno":
                            imm.vaniAccDiretti = +imm.vaniAccDiretti + +extra.superficie.replace(",", ".")
                            break;
                        case "mansarda":
                            imm.vaniAccDiretti = +imm.vaniAccDiretti + +extra.superficie.replace(",", ".")
                            break;
                        case "vano scale":
                            imm.vaniAccDiretti = +imm.vaniAccDiretti + +extra.superficie.replace(",", ".")
                            break;
                        case "ripostigli":
                            imm.vaniAccDiretti = +imm.vaniAccDiretti + +extra.superficie.replace(",", ".")
                            break;
                        case "sottotetto":
                            imm.vaniAccDiretti = +imm.vaniAccDiretti + +extra.superficie.replace(",", ".")
                            break;
                        case "ingresso":
                            imm.vaniPrincipali = +imm.vaniPrincipali + +extra.superficie.replace(",", ".")
                            break;
                        case "soggiorno":
                            imm.vaniPrincipali = +imm.vaniPrincipali + +extra.superficie.replace(",", ".")
                            break;
                        case "sala da pranzo":
                            imm.vaniPrincipali = +imm.vaniPrincipali + +extra.superficie.replace(",", ".")
                            break;
                        case "studio":
                            imm.vaniPrincipali = +imm.vaniPrincipali + +extra.superficie.replace(",", ".")
                            break;
                        case "camera da letto":
                            imm.vaniPrincipali = +imm.vaniPrincipali + +extra.superficie.replace(",", ".")
                            break;
                        case "balcone":
                            imm.terrazze = +imm.terrazze + +extra.superficie.replace(",", ".")
                            break;
                        case "balconi":
                            imm.terrazze = +imm.terrazze + +extra.superficie.replace(",", ".")
                            break;
                        case "terrazza":
                            imm.terrazze = +imm.terrazze + +extra.superficie.replace(",", ".")
                            break;
                        case "terrazzo":
                            imm.terrazze = +imm.terrazze + +extra.superficie.replace(",", ".")
                            break;
                        case "magazzino":
                            imm.vaniAccNonComunicanti = +imm.vaniAccNonComunicanti + +extra.superficie.replace(",", ".")
                            break;
                        case "soffitta":
                            imm.vaniAccNonComunicanti = +imm.vaniAccNonComunicanti + +extra.superficie.replace(",", ".")
                            break;
                        case "cantina":
                            imm.vaniAccNonComunicanti = +imm.vaniAccNonComunicanti + +extra.superficie.replace(",", ".")
                            break;
                        case "lavanderia":
                            imm.vaniAccNonComunicanti = +imm.vaniAccNonComunicanti + +extra.superficie.replace(",", ".")
                            break;
                        default:
                            break;
                    }
                });
            }

            if (immobile.supExtra) {
                let sommaSup = 0;
                immobile.supExtra.forEach(element => {
                    sommaSup = +sommaSup + +element.supCommerciale.replace(",", ".");
                });
                if (imm.prezzo)
                    immobile.valoreUnitario = this._currency.transform(Math.round(((+imm.prezzo.replace('€', '').split('.').join('').split(',').join('.') / sommaSup) * 10) / 10), '€', 2, '.', ',');

            } else {
                if (imm.prezzo && immobile.fonte === "immobiliare.it")
                    immobile.valoreUnitario = this._currency.transform(Math.round((+imm.prezzo.replace('€', '').split('.').join('').split(',').join('.') / +immobile.superficie) * 10) / 10, '€', 2, '.', ',');
                else if (imm.prezzo && imm.fonte === "Tecnici Conferitori") {
                    immobile.valoreUnitario = this._currency.transform(this._sharedService.parseNumber(imm.prezzo) / this._sharedService.parseNumber(imm['superficiecommerciale']), '€', 2, '.', ',')
                } else
                    if (imm.prezzo)
                        immobile.valoreUnitario = this._currency.transform(Math.round((+imm.prezzo.replace('€', '').split('.').join('') / +immobile.superficie) * 10) / 10, '€', 2, '.', ',');
            }
        });

        return arra;
    }

    private _cleanFilters(filters: any): any {
        delete filters['address'];
        delete filters['raggio'];
        delete filters['contratto'];
        delete filters['tipologia'];

        for (const key in filters)
            if (filters[key] === null) delete filters[key];

        return filters;
    }
}