import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { User, UserType } from "../../../classi/User";
import { take, map, catchError, tap, switchMap } from "rxjs/operators";
import { FormGroup } from "@angular/forms";
import { FirebaseCollectionsHelper } from "src/helpers/firebase-collections-helper";
import * as firebase from "firebase";

@Injectable({ providedIn: "root" })
export class AuthService {
  authState$: Observable<any>;

  user = new BehaviorSubject<string>(null);
  public userActive = new BehaviorSubject<User>(null);

  get currentUser(): User {
    return this.userActive.getValue();
  }

  get userRole(): UserType {
    const user = this.currentUser;
    return user ? user.userType : null;
  }

  get getUserId(): string {
    return this.user ? this.user.getValue() : null;
  }

  get isAdminRole(): boolean {
    return this.userRole === UserType.admin;
  }

  get isRevisoreRole(): boolean {
    return this.userRole === UserType.revisore;
  }

  get isUserRole(): boolean {
    return this.userRole === UserType.user;
  }

  constructor(
    private _db: AngularFirestore,
    private _auth: AngularFireAuth,
    private router: Router
  ) {
    this._loadSavedUserId();

    this._auth.authState.subscribe((user) => {
      if (user) this.user.next(user.uid);
    });

    this.authState$ = this._auth.authState.pipe(
      tap(this._setUserId.bind(this)),
      switchMap((fbUser) => {
        this.user.next(fbUser ? fbUser.uid : null);
        if (!fbUser) {
          return of(null);
        }
        return this._fetchUserByAuthId(fbUser.uid);
      }),
      tap((user: User) => {
        if (user) {
          user.userType = user.userType;
        }
        this.userActive.next(user);
      })
    );

    this.authState$.subscribe();
  }

  logIn(email: string, password: string): Promise<any> {
    return new Promise<void>(async (resolve, reject) => {
      try {
        const credentials = await this._auth.auth.signInWithEmailAndPassword(
          email,
          password
        );
        this._setUserId(credentials.user);
        const user = (await this._fetchUserByAuthId(credentials.user.uid)
          .pipe(take(1))
          .toPromise()) as User;
        if (user.userType !== UserType.pending && !user.disabled) {
          this.userActive.next(user);
          this.router.navigate(["/back", "dash"]);
          resolve();
        } else {
          this.logout();
          reject("pending");
        }
      } catch (e) {
        console.log(e);
        reject();
      }
    });
  }

  async signUp(form: FormGroup): Promise<any> {
    const newUser: Partial<User> = {
      email: form.value.email,
      userType: UserType.pending,
      name: form.value.name,
      surname: form.value.surname,
      subDate: new Date(),
    };

    return new Promise(async (resolve, reject) => {
      try {
        const user = await this._auth.auth.createUserWithEmailAndPassword(
          form.value.email,
          form.value.psw
        );
        this._db
          .doc(FirebaseCollectionsHelper.USERS + "/" + user.user.uid)
          .set({
            ...newUser,
          })
          .then(resolve, reject);
      } catch (e) {
        reject();
      }
    });
  }

  getAuthToken(): Promise<string> {
    return firebase.auth().currentUser
      ? firebase.auth().currentUser.getIdToken()
      : null;
  }

  public logout(): Promise<void> {
    this.userActive.next(null);
    localStorage.removeItem("USER_UID");
    this.router.navigateByUrl("/");
    return this._auth.auth.signOut();
  }

  private _fetchUserByAuthId(authId: string): Observable<User> {
    if (!authId) {
      return of<User>(null);
    }
    return this._db
      .doc<User>(`users/${authId}`)
      .valueChanges()
      .pipe(
        map((user) => {
          user.id = authId;
          return !!user ? { ...user, id: authId } : null;
        })
      )
      .pipe(
        catchError((err) => {
          console.error(err);
          return of(null);
        })
      );
  }

  private _setUserId(user: firebase.User): void {
    if (user) {
      this.user.next(user.uid);
      localStorage.setItem("USER_UID", user.uid);
    } else {
      localStorage.removeItem("USER_UID");
    }
  }

  private async _loadSavedUserId(): Promise<void> {
    this.user.next(localStorage.getItem("USER_UID"));
  }
}
