import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog, MatStepper } from "@angular/material";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RealPerizia } from "src/app/classi/Perizia/RealPerizia";
import { NuovaPeriziaService } from "src/app/modules/back/services/nuova-perizia.service";

@Component({
  selector: "revisione-component",
  templateUrl: "./revisione.component.html",
  styleUrls: ["./revisione.component.scss"],
})
export class RevisioneComponent implements OnInit, OnDestroy {
  @Input() stepper: MatStepper;
  @Output() save = new EventEmitter();
  private _unsuscribeAll: Subject<any>;

  form: FormGroup;

  constructor(
    private _service: NuovaPeriziaService,
    private _formBuilder: FormBuilder
  ) {
    this._unsuscribeAll = new Subject();
  }

  ngOnInit() {
    this.form = this._formBuilder.group({
      editedText: [null, Validators.required],
    });

    this._service.perizia
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res) this._setText(res);
      });
  }

  ngOnDestroy() {
    this._unsuscribeAll.next();
    this._unsuscribeAll.complete();
  }

  private _setText(perizia: Partial<RealPerizia>) {
    if (!perizia.intestazione) return;
    const text =
      "La società di valutazioni immobiliari " +
      perizia.intestazione.societyName +
      ", con sede a " +
      perizia.intestazione.societyCity +
      " (prov. " +
      perizia.intestazione.societyProv +
      ") in " +
      perizia.intestazione.societyAddress +
      ", codice fiscale/p.IVA " +
      perizia.intestazione.societyPiva +
      " ,ad evasione dell’incarico ricevuto da " +
      perizia.intestazione.client +
      " e " +
      perizia.intestazione.client1 +
      " e " +
      perizia.intestazione.client2 +
      " " +
      perizia.intestazione.bankName +
      " , con sede a " +
      perizia.intestazione.bankCity +
      " (prov. " +
      perizia.intestazione.bankProv +
      ") in " +
      perizia.intestazione.bankAddress +
      ", codice fiscale/p. IVA " +
      perizia.intestazione.bankPiva +
      " , ha incaricato la società retista " +
      perizia.intestazione.societyRetePiva +
      " , con sede a " +
      perizia.intestazione.societyReteCity +
      " (prov. " +
      perizia.intestazione.societyReteProv +
      ")" +
      "in " +
      perizia.intestazione.societyReteAddress +
      " , codice fiscale/p. IVA " +
      perizia.intestazione.societyRetePiva +
      " della redazione del rapporto di valutazione " +
      perizia.intestazione.houseNumber +
      " del " +
      perizia.intestazione.client +
      " relativo alla determinazione del più probabile Valore di Mercato, Assicurabile, Cauzionale e di Pronto Realizzo dell’immobile" +
      " sito a " +
      perizia.intestazione.houseCity +
      " , CAP: " +
      perizia.intestazione.houseCap +
      " (prov. " +
      perizia.intestazione.houseProv +
      ") in " +
      perizia.intestazione.houseAddress +
      " , composto da " +
      perizia.intestazione.houseMore +
      ". Il tecnico incaricato della redazione del rapporto di valutazione è: Il sottoscritto " +
      perizia.intestazione.accountName +
      " , nato a " +
      perizia.intestazione.accountBornCity +
      " (prov. " +
      perizia.intestazione.accountBornProv +
      ") il " +
      perizia.intestazione.accountBirthDate +
      " , codice fiscale " +
      perizia.intestazione.accountDocumentNumber +
      " , residente a " +
      perizia.intestazione.accountCity +
      " , CAP " +
      perizia.intestazione.accountCap +
      " , (prov. " +
      perizia.intestazione.accountProv +
      ") in " +
      perizia.intestazione.accountAddress +
      " , con studio sito a " +
      perizia.intestazione.client +
      " , CAP: " +
      perizia.intestazione.client +
      " (prov. " +
      perizia.intestazione.client +
      ")in " +
      perizia.intestazione.client +
      " , iscritto all’ordine/collegio professionale: Collegio di Treviso con il numero: " +
      perizia.intestazione.accountRegistryOrderNumber +
      " qualificato" +
      "come esperto estimatore Certificato/Qualificato " +
      perizia.intestazione.accountRegistryOrderQualify;

    this.form.controls.editedText.setValue(text.trim());
    this.form.updateValueAndValidity();
  }

  next() {
    this.save.emit(this.form.value);
  }

  prev() {
    this.stepper.previous();
  }
}
