import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { User, UserType } from 'src/app/classi/User';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UtentiService } from 'src/app/modules/back/services/utenti.service';

@Component({
    selector: 'dialog-overview-example-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.scss']
})

export class NewEditUser implements OnInit {

    userToEdit: User;
    isLoading = false;
    formUser: FormGroup;

    constructor(
        public dialogRef: MatDialogRef<NewEditUser>,
        private formBuilder: FormBuilder,
        private _userService: UtentiService,
        @Inject(MAT_DIALOG_DATA) public data: any) {

        this.userToEdit = this.data ? this.data : null;
    }

    ngOnInit() {
        this.formUser = this.formBuilder.group({
            surname: [{ value: this.userToEdit ? this.userToEdit.surname : '', disabled: this.userToEdit }, Validators.required],
            name: [{ value: this.userToEdit ? this.userToEdit.name : '', disabled: this.userToEdit }, Validators.required],
            email: [{ value: this.userToEdit ? this.userToEdit.email : '', disabled: this.userToEdit }, Validators.required],
            userType: [this.userToEdit ? this.userToEdit.userType === UserType.admin ? 'admin' : this.userToEdit.userType === UserType.revisore ? 'revisore' : 'user' : 'user', Validators.required]
        });
    }

    onSave(form: FormGroup) {
        const newUser: Partial<User> = {
            ...form.value,
            id: this.userToEdit ? this.userToEdit.id : null
        }

        this.isLoading = true;
        if (form.valid)
            this.userToEdit ? this._userService.editUser(newUser).then(() => this.dialogRef.close()).finally(() => this.isLoading = false)
                : this._userService.saveUser(newUser).then(() => this.dialogRef.close()).finally(() => this.isLoading = false);

    }

    onNoClick() {
        this.dialogRef.close();
    }
}