import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { routes } from "./routes";
import { CommonModule } from "@angular/common";
import { MAT_DATE_FORMATS } from "@angular/material/core";
import { Sister } from "./components/sister/sister.component";
import { DetailSister } from "./dialogs/dialog-detail/detail.component";
import { DetailSisterPagamento } from "./dialogs/dialog-detail-payment/payment.component";
import { DetailSisterLogin } from "./dialogs/dialog-login/login-dialog.component";
import { DetailConvenzioni } from "./dialogs/dialog-convenzioni/convenzioni.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DataTablesModule } from "angular-datatables";
import { SpinnerModule } from "../../../../shared/spinner-material/spinner.module";
import { DialogIntestatiSister } from "./dialogs/dialog-intestati/intestati.component";
import { SisterUtilsService } from "./services/sister-utils.service";
import { MaterialModule } from "src/app/shared/material/material.module";
import { SisterService } from "./services/sister.service";
import { ToastrModule, ToastrService } from "ngx-toastr";

export const MY_FORMATS = {
  parse: {
    dateInput: "DD/MM/YYYY",
  },
  display: {
    dateInput: "DD/MM/YYYY",
    monthYearLabel: "MM YYYY",
    dateA11yLabel: "DD/MM/YYYY",
    monthYearA11yLabel: "MM YYYY",
  },
};

export enum ToasterPosition {
  topRight = "toast-top-right",
  topLeft = "toast-top-left",
  bottomRight = "toast-bottom-right",
  bottomLeft = "toast-bottom-left",
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    MaterialModule,
    SpinnerModule,
    ToastrModule.forRoot({
      timeOut: 2500,
      positionClass: ToasterPosition.bottomRight,
      preventDuplicates: true,
      //  toastClass: 'ngx-toastr toast-success custom-toast'
    }),
  ],
  exports: [
    Sister,
    DetailSister,
    DetailSisterPagamento,
    DetailSisterLogin,
    DetailConvenzioni,
    DialogIntestatiSister,
  ],
  declarations: [
    Sister,
    DetailSister,
    DetailSisterPagamento,
    DetailSisterLogin,
    DetailConvenzioni,
    DialogIntestatiSister,
  ],
  entryComponents: [
    DetailSister,
    DetailSisterPagamento,
    DetailSisterLogin,
    DetailConvenzioni,
    DialogIntestatiSister,
  ],
  providers: [
    SisterUtilsService,
    SisterService,
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    ToastrService,
  ],
})
export class SisterModule {}
