import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';


@Component({
    selector: 'detail-sister-login',
    templateUrl: './login-dialog.component.html',
    styleUrls: ['./login-dialog.component.css']
})
export class DetailSisterLogin {

    constructor(
        public dialogRef: MatDialogRef<DetailSisterLogin>,
        @Inject(MAT_DIALOG_DATA) public dataPay: dataPay) { }

    onNoClick(): void {
        this.dialogRef.close("close");
    }
}

export interface dataPay {
    codFisc: string;
    pass: string;
}