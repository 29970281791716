import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './routing/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { DataTablesModule } from 'angular-datatables';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { SpinnerModule } from './shared/spinner-material/spinner.module';
import { MaterialModule } from './shared/material/material.module';
import { CurrencyFormat, NumberFormat } from './shared/pipes/currency';
import { BackModule } from './modules/back/back.module';
import { AngularFireFunctionsModule, FUNCTIONS_REGION } from '@angular/fire/functions';
import { AuthModule } from './modules/auth/auth.module';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { ConfrontoService } from './modules/back/components/ricerca-comparabili/services/confronto.service';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';

@NgModule({
  imports: [
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireStorageModule,
    AngularFireFunctionsModule,
    AngularFireAuthModule,
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    DataTablesModule,
    MaterialModule,
    SpinnerModule,
    BackModule,
    AuthModule,
    SharedModule
  ],
  declarations: [
    AppComponent,
    CurrencyFormat,
    NumberFormat
  ],
  entryComponents: [
  ],
  providers: [
    CurrencyFormat,
    NumberFormat,
    ConfrontoService,
    { provide: FUNCTIONS_REGION, useValue: 'europe-west1' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
