import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { macroCategorie } from "src/app/classi/categorie";

@Component({
  templateUrl: "./dialog.component.html",
  styleUrls: ["./dialog.component.scss"],
})
export class NewAcronimoDialog implements OnInit {
  form: FormGroup;
  categorie = macroCategorie;

  constructor(
    public dialogRef: MatDialogRef<NewAcronimoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.form = this._formBuilder.group({
      acronimo: [null, Validators.required],
      descrizione: [null, Validators.required],
    });
  }

  confirm(form: FormGroup) {
    if (!form.valid) return;
    this.dialogRef.close({
      acronimo: {
        label: form.value.descrizione,
        value: form.value.acronimo,
      },
    });
  }
}
