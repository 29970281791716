import { SuperficieExtra } from '../Sister/SuperficieExtra';
import { Intestati } from '../Sister/Intestati';

export class Immobile {
  constructor(
    public descrizione: string,
    public coordinate: string,
    public comune: string,
    public indirizzo: string,
    public tipoImmobile: string,
    public statoManutenzione: string,
    public prezzo: string,
    public bagni: string,
    public data: string,
    public tipologia: string,
    public superficie: string,
    public locali: string,
    public postiAuto: string,
    public garage: string,
    public piano: string,
    public disponibilita: string,
    public tipoProprieta: string,
    public annoDiCostruzione: string,
    public stato: string,
    public riscaldamento: string,
    public condizionatore: string,
    public classeEnergetica: string,
    public peg: string,
    public climatizzatore: string,
    public balcone: string,
    public terrazzo: string,
    public giardino: string,
    public cantina: string,
    public allarme: string,
    public fotovoltaioco: string,
    public link: string,
    public macrozona: string,
    public microzona: string,
    public planimetrie: boolean,
    public ascensore: boolean | any,
    public supExtra: SuperficieExtra[],
    public vaniAccDiretti: number,
    public vaniAccNonComunicanti: number,
    public vaniAltro: number,
    public terrazze: number,
    public fonte: string,
    public vaniPrincipali: number,
    public supPrincipale: number,
    public lat: string,
    public lng: string,
    public alpha: number,
    public valoreUnitario: string,
    public intestati: Intestati[]
  ) { }
}