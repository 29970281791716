import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SisterUtilsService } from '../../services/sister-utils.service';
import { Intestati } from 'src/app/classi/Sister/Intestati';


@Component({
    selector: 'detail-sister',
    templateUrl: './intestati.component.html',
    styleUrls: ['./intestati.component.css']
})
export class DialogIntestatiSister {

    dataPP: Intestati[]

    constructor(
        public dialogRef: MatDialogRef<DialogIntestatiSister>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public service: SisterUtilsService) {

        this.dataPP = data.intestati;

    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}