import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'dropdown',
  templateUrl: './dropdown.html',
  styleUrls: ['./dropdown.scss']
})
export class DropDownComponent implements OnInit {

  @Input() field: any = {};
  @Input() form: FormGroup;
  disabled: boolean;
  @Input() isViewMode?: boolean = false;

  @Output() onOmiClick = new EventEmitter();

  constructor() {
  }

  ngOnInit() {
    this.disabled = this.form.controls[this.field.name].disabled;
  }

  emit() {
    this.onOmiClick.emit(0);
  }
}