import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DetailComparabileService } from '../../../services/detail-comparabile.service';
import { Etichetta } from 'src/app/classi/Etichetta';
import { Perizia } from 'src/app/classi/Perizia/Perizia';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'detail-comparabile',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.scss']
})

export class DetailComparabileComponent implements OnInit, OnDestroy {

    private _unsuscribeAll: Subject<any>;

    comparabile: Perizia;
    etichette: Etichetta[];

    constructor(
        private _service: DetailComparabileService) {

        this._unsuscribeAll = new Subject();
    }

    ngOnInit() {
        this.comparabile = this._service.comparabile;
        this.etichette = this._service.etichette;

        this._matchFiledAndValues();

        this._service.onComparabileChanged.pipe(takeUntil(this._unsuscribeAll))
            .subscribe(res => {
                if (res) {
                    this.comparabile = res;
                    this._matchFiledAndValues();
                }
            });
    }

    ngOnDestroy() {
        this._unsuscribeAll.next();
        this._unsuscribeAll.complete();
    }

    private _matchFiledAndValues() {
        this.etichette.forEach(etichetta => {
            if (this.comparabile[etichetta.name]) etichetta.value = this.comparabile[etichetta.name];
            if (this.comparabile[etichetta.name + 'millesimi']) etichetta.millesimi = this.comparabile[etichetta.name + 'millesimi'];
            if (this.comparabile[etichetta.name + 'indice']) etichetta.indice = this.comparabile[etichetta.name + 'indice'];
            if (this.comparabile[etichetta.name + 'vetusta']) etichetta.vetusta = this.comparabile[etichetta.name + 'vetusta'];
            if (this.comparabile[etichetta.name + 'vitaUtile']) etichetta.vitaUtile = this.comparabile[etichetta.name + 'vitaUtile'];
        });
    }
}