import { Routes } from "@angular/router";
import { ElencoPerizieComponent } from "./components/elenco-perizie/elenco-perizie.component";
import { ElencoPerizieService } from "./services/elenco-perizie.service";
import { NuovaPeriziaComponent } from "./components/nuovo/perizia/nuova-perizia.component";
import { EtichetteService } from "./services/etichette.service";
import { NuovoComparabileComponent } from "./components/nuovo/comparabile/nuovo-comparabile.component";
import { UtentiService } from "./services/utenti.service";
import { UtentiComponent } from "./components/utenti/utenti.component";
import { ElencoComparabiliComponent } from "./components/elenco-comparabili/elenco-comparabili.component";
import { ElencoComparabiliService } from "./services/elenco-comparabili.service";
import { AuthAdminGuards } from "../../routing/guards/admin.guards";
import { Back } from "./components/back/back.component";
import { DetailComparabileService } from "./services/detail-comparabile.service";
import { DetailComparabileComponent } from "./components/elenco-comparabili/detail/detail.component";
import { ViewComparabileComponent } from "./components/view-comparabile/view.component";
import { ViewComparabileService } from "./services/view-comparabile.service";
import { NuovaPeriziaService } from "./services/nuova-perizia.service";

export const routes: Routes = [
  {
    path: "dash",
    component: Back,
    children: [
      {
        path: "comparabili",
        loadChildren: () =>
          import(
            "../back/components/ricerca-comparabili/comparabili.module"
          ).then((mod) => mod.ComparabiliModule),
      },
      {
        path: "sister",
        loadChildren: () =>
          import("../back/components/ricerca-sister/sister.module").then(
            (mod) => mod.SisterModule
          ),
      },
      {
        path: "mca",
        loadChildren: () =>
          import("../back/components/mca/mca.module").then(
            (mod) => mod.McaModule
          ),
      },
      {
        path: "elenco-perizie",
        component: ElencoPerizieComponent,
        resolve: { data: ElencoPerizieService },
      },
      {
        path: "elenco-comparabili",
        component: ElencoComparabiliComponent,
        resolve: { data: ElencoComparabiliService },
      },
      {
        path: "new-perizia",
        component: NuovaPeriziaComponent,
        resolve: { data: EtichetteService },
      },
      {
        path: "new-perizia" + "/:id",
        component: NuovaPeriziaComponent,
        resolve: { data: EtichetteService, a: NuovaPeriziaService },
      },
      {
        path: "new-comparabile" + "/:id",
        component: NuovoComparabileComponent,
        resolve: { data: EtichetteService },
      },
      {
        path: "edit-comparabile" + "/:id",
        component: DetailComparabileComponent,
        resolve: { data: DetailComparabileService },
      },
      {
        path: "view-comparabile" + "/:id",
        component: ViewComparabileComponent,
        resolve: { data: ViewComparabileService },
      },
      {
        path: "utenti",
        component: UtentiComponent,
        canActivate: [AuthAdminGuards],
        resolve: { data: UtentiService },
      },
    ],
  },
];
