import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Etichetta } from "src/app/classi/Etichetta";
import { Perizia } from "src/app/classi/Perizia/Perizia";
import { SharedService } from "src/app/shared/services/shared.service";
import { ViewComparabileService } from "../../services/view-comparabile.service";

@Component({
  selector: "view-comparabile",
  templateUrl: "./view.component.html",
  styleUrls: ["./view.component.scss"],
})
export class ViewComparabileComponent implements OnInit, OnDestroy {
  private _unsuscribeAll: Subject<any>;

  @Input() comparabile: Perizia;
  @Input() etichette: Etichetta[];

  constructor(
    private _service: ViewComparabileService,
    private _sharedService: SharedService
  ) {
    this._unsuscribeAll = new Subject();
  }

  ngOnInit() {
    console.log(this.etichette, this.comparabile);

    if (!this.comparabile) this.comparabile = this._service.comparabile;
    if (!this.etichette) this.etichette = this._service.etichette;

    this._matchFiledAndValues();
    this._service.onComparabileChanged
      .pipe(takeUntil(this._unsuscribeAll))
      .subscribe((res) => {
        if (res) {
          if (!this.comparabile) this.comparabile = res;
          this._matchFiledAndValues();
        }
      });
  }

  ngOnDestroy() {
    this._unsuscribeAll.next();
    this._unsuscribeAll.complete();
  }

  onDownload(file: string) {
    let index: any;
    let fileToDownload: string;

    if (this.comparabile.visibleFilesNameInterni.indexOf(file) !== -1)
      index = {
        index: this.comparabile.visibleFilesNameInterni.indexOf(file),
        from: "interni",
      };
    if (this.comparabile.visibleFilesNameEsterni.indexOf(file) !== -1)
      index = {
        index: this.comparabile.visibleFilesNameEsterni.indexOf(file),
        from: "esterni",
      };
    if (this.comparabile.visibleFilesNameDocumenti.indexOf(file) !== -1)
      index = {
        index: this.comparabile.visibleFilesNameDocumenti.indexOf(file),
        from: "documenti",
      };

    switch (index.from) {
      case "interni":
        fileToDownload = this.comparabile.filesInterni[index.index];
        break;
      case "esterni":
        fileToDownload = this.comparabile.filesEsterni[index.index];
        break;
      case "documenti":
        fileToDownload = this.comparabile.documenti[index.index];
    }

    this._sharedService.downloadFile(fileToDownload);
  }

  private _matchFiledAndValues() {
    this.etichette.forEach((etichetta) => {
      if (this.comparabile[etichetta.name])
        etichetta.value = this.comparabile[etichetta.name];
      if (this.comparabile[etichetta.name + "millesimi"])
        etichetta.millesimi = this.comparabile[etichetta.name + "millesimi"];
      if (this.comparabile[etichetta.name + "indice"])
        etichetta.indice = this.comparabile[etichetta.name + "indice"];
      if (this.comparabile[etichetta.name + "vetusta"])
        etichetta.vetusta = this.comparabile[etichetta.name + "vetusta"];
      if (this.comparabile[etichetta.name + "vitaUtile"])
        etichetta.vitaUtile = this.comparabile[etichetta.name + "vitaUtile"];
    });
  }
}
