import { Injectable, OnDestroy } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Subject } from "rxjs";

@Injectable()
export class FormService implements OnDestroy {

    private _unsuscribeAll: Subject<any>;

    constructor(
        private _db: AngularFirestore) {

        this._unsuscribeAll = new Subject();
    }

    ngOnDestroy() {
        this._unsuscribeAll.next();
        this._unsuscribeAll.complete();
    }

    async getZonaSismica(comune: string) {
        return (await this._db.collection<{ denominazione: string, classificazione: string; }>('comuni', ref => ref.where('denominazione', '==', comune)).get().toPromise())
            .docs.map(doc => {
                return doc.data().classificazione;
            });
    }
}