// export const categorie = [
//     { value: 'aelodromi', name: 'Aelodromi', macro: 6 },
//     { value: 'affittacemere', name: 'Affittacamere', macro: 3 },
//     { value: 'agriturismo', name: 'Agriturismo', macro: 3 },
//     { value: 'appartamento', name: 'Appartamento', macro: 0 },
//     { value: 'appartamentoAttico', name: 'Attico', macro: 0 },
//     { value: 'appartamentoLoft', name: 'Loft', macro: 0 },
//     { value: 'autodromiEMotodromi', name: 'Autodromi e motodromi', macro: 6 },
//     { value: 'autorimessa ', name: 'Autorimessa', macro: 2 },
//     { value: 'bar', name: 'Bar', macro: 2 },
//     { value: 'biblioteca', name: 'Biblioteca', macro: 1 },
//     { value: 'bocciodromi', name: 'Bocciodromi', macro: 7 },
//     { value: 'campeggio', name: 'Campeggio', macro: 3 },
//     { value: 'campiDaGolf', name: 'Campi da golf', macro: 6 },
//     { value: 'campiDaHockey', name: 'Campi da hockey', macro: 6 },
//     { value: 'campiDaTennis', name: 'Campi da tennis', macro: 6 },
//     { value: 'cantine', name: 'Cantine', macro: 0 },
//     { value: 'caseDiCura', name: 'Case di cura', macro: 1 },
//     { value: 'caseVille', name: 'Case ville', macro: 0 },
//     { value: 'centroBenessere', name: 'Centro benessere', macro: 2 },
//     { value: 'centroCongressi', name: 'Centro congressi', macro: 1 },
//     { value: 'centroDiArchiviazione', name: 'Centro di archiviazione', macro: 1 },
//     { value: 'centroDirezionale', name: 'Centro direzionale', macro: 1 },
//     { value: 'centroElaborazioneDati', name: 'Centro elaborazione dati', macro: 1 },
//     { value: 'cinema', name: 'Cinema', macro: 2 },
//     { value: 'danzaSportiva', name: 'Danza sportiva', macro: 7 },
//     { value: 'discoteca', name: 'Discoteca', macro: 2 },
//     { value: 'distributore', name: 'Distributore', macro: 2 },
//     { value: 'ippodromo ', name: 'Ippodromo', macro: 6 },
//     { value: 'istitutoDiCredito', name: 'Istituto di credito', macro: 1 },
//     { value: 'motel', name: 'Motel', macro: 3 },
//     { value: 'negozio', name: 'Negozio', macro: 2 },
//     { value: 'ostello', name: 'Ostello', macro: 3 },
//     { value: 'palazziDelGhiaccio', name: 'Palazzi del ghiaccio', macro: 7 },
//     { value: 'palestra', name: 'Palestra', macro: 2 },
//     { value: 'parcheggio', name: 'Parcheggio', macro: 2 },
//     { value: 'pensione', name: 'Pensione', macro: 3 },
//     { value: 'piscine', name: 'Piscine', macro: 7 },
//     { value: 'poligoniArmiDaFuoco', name: 'Poligono', macro: 6 },
//     { value: 'poligoniConArco', name: 'Poligono con arco', macro: 6 },
//     { value: 'posti_auto', name: 'Posti auto', macro: 5 },
//     { value: 'postoAutoCopertoFabbricato', name: 'Posto auto coperto', macro: 0 },
//     { value: 'postoAutoCopertoTettoia', name: 'Posto auto coperto da tettoia', macro: 0 },
//     { value: 'postoAutoScoperto', name: 'Posto auto scoperto', macro: 0 },
//     { value: 'residence', name: 'Residence', macro: 3 },
//     { value: 'rifugio', name: 'Rifugio', macro: 3 },
//     { value: 'ristorante', name: 'Ristorante', macro: 2 },
//     { value: 'scuole', name: 'Scuola', macro: 1 },
//     { value: 'stadiBaseball', name: 'Stadi di baseball', macro: 6 },
//     { value: 'stadiDiAtleticaLeggera', name: 'Stadi di atletica leggera', macro: 6 },
//     { value: 'stadiDiCalcio', name: 'Stadi di calcio', macro: 6 },
//     { value: 'stadiDiRugby', name: 'Stadi di rugby', macro: 6 },
//     { value: 'studioProfessionale', name: 'Studio professionale', macro: 1 },
//     { value: 'vsupermercato', name: 'Supermecato', macro: 2 },
//     { value: 'teatro', name: 'Teatro', macro: 2 },
//     { value: 'tennisCoperto', name: 'Tennis coperto', macro: 7 },
//     { value: 'uffici', name: 'Uffici', macro: 1 },
//     { value: 'velodromi', name: 'Velodromi', macro: 6 },
//     { value: 'villa', name: 'Villa', macro: 0 },
//     { value: 'villaggioTuristico', name: 'Villaggio turistico', macro: 3 },
//     { value: 'villettaASchiera', name: 'Villetta a schiera', macro: 0 },
//     { value: 'villino', name: 'Villino', macro: 0 },
//     { value: 'warehouse', name: 'Magazzino', macro: 2 },
// ]

export const macroCategorie = [
  { name: "Abitativo", value: 0 },
  { name: "Commerciale", value: 2 },
  //{ name: 'Altro tipo d\'uso - Turistico/ricettivo', value: 3 },
  { name: "Industriale", value: 4 },
  { name: "Uffici", value: 1 },
  { name: "Altro tipo d'uso", value: 5 },
  //{ name: 'Artigianale', value: 5 },
  //{ name: 'Sportivo all\' aperto', value: 6 },
  //{ name: 'Altro tipo d\'uso - Strutture al chiuso', value: 7 },
];

export const categorie = [
  { value: "appartamento", name: "Appartamento", macro: 0 },
  { value: "negozio", name: "Negozio", macro: 2 },
  { value: "bar", name: "Bar", macro: 2 },
  { value: "cantine", name: "Cantine/Soffitte", macro: 5 },
  { value: "case_ville", name: "Case e ville", macro: 0 },
  // { value: "centroBenessere", name: "Centro benessere", macro: 2 },
  { value: "cinema", name: "Cinema - Teatro", macro: 2 },
  { value: "discoteca", name: "Discoteca", macro: 2 },
  { value: "distributore", name: "Stazione di servizio", macro: 2 },
  { value: "palestra", name: "Palestra - Centro benessere", macro: 2 },
  { value: "posti_auto", name: "Posti auto", macro: 5 },
  { value: "ristorante", name: "Ristorante", macro: 2 },
  { value: "vsupermercato", name: "Supermecato", macro: 2 },
  // { value: "teatro", name: "Teatro", macro: 2 },
  { value: "uffici", name: "Uffici", macro: 1 },
  { value: "palazzinaUffici", name: "Palazzina uffici", macro: 1 },
  { value: "turisticoRicettivo", name: "Turistico ricettivo", macro: 5 },
  { value: "sportivoAperto", name: "Sportivo all'aperto", macro: 5 },
  { value: "sportivoChiuso", name: "Sportivo al chiuso", macro: 5 },
  { value: "industriale", name: "Industriale", macro: 4 },
  { value: "artigianale", name: "Artigianale", macro: 4 },
  { value: "magazzini", name: "Vendita all'ingrosso", macro: 2 },
  { value: "centroCommerciale", name: "Centro commerciale", macro: 2 },
  { value: "autorimessa ", name: "Car Parking", macro: 2 },
];
