import { Injectable } from '@angular/core';
import { AngularFireStorage } from 'angularfire2/storage';

@Injectable()

export class BackService {

    constructor(
        private _storage: AngularFireStorage) { }

    async downloadFile(name: string) {
        const url = await this._storage.ref('file-excel/' + name).getDownloadURL().toPromise();
        window.open(url);
    }
}