import { NgModule } from '@angular/core';
import { SpinnerComponent } from './spinner.component';
import { MaterialModule } from '../material/material.module';

@NgModule({
    imports: [
        MaterialModule
    ],
    exports: [
        SpinnerComponent
    ],
    declarations: [
        SpinnerComponent
    ]
})
export class SpinnerModule { }