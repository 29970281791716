import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Convenzione } from 'src/app/classi/Sister/Convenzione';



@Component({
    selector: 'convenzioni-sister',
    templateUrl: './convenzioni.component.html',
    styleUrls: ['./convenzioni.component.css']
})
export class DetailConvenzioni {

    dataPP: Convenzione[];

    constructor(
        public dialogRef: MatDialogRef<DetailConvenzioni>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        @Inject(MAT_DIALOG_DATA) public conv: string) {

        this.dataPP = data.data;
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    changeConv(conv: string) {
        this.conv = conv;
    }
}