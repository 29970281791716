import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Etichetta } from "src/app/classi/Etichetta";
import { BehaviorSubject, Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import * as _ from "lodash";

@Injectable()
export class EtichetteService {
  etichette: Etichetta[] = [];
  onEtichetteChanged = new BehaviorSubject<any[]>(null);

  constructor(private _http: HttpClient) {}

  resolve(): Promise<any> {
    return new Promise<void>((resolve, reject) => {
      this._getAllEtichette().subscribe((res) => {
        this.etichette = res as Etichetta[];
        this.onEtichetteChanged.next(this.etichette);

        resolve();
      }, reject);
    });
  }

  private _getAllEtichette(): Observable<any> {
    return this._http
      .get("https://europe-west1-easystima-4edee.cloudfunctions.net/api/v1.0/etichette/get-all")
      .pipe(
        take(1),
        map((res: any) =>
          res.map((single: any) => {
            if (single.options) {
              const options = _.orderBy(
                single.options,
                (single) => +single.key,
                "asc"
              );
              single.options = options;
            }

            return single;
          })
        ),
        map((res) => {
          return _.orderBy(res, (a) => a.ordinamento).map((a) => {
            return {
              ...a,
              required: a.required === 1 ? true : false,
            };
          });
        })
      );
  }
}
